import React, { Component } from 'react'
import { connect } from 'react-redux';
import { /*Link,*/ withRouter } from 'react-router-dom';
import * as actions from '../../actions/actions';
import { showLoginModal, fetchLoginBD, fetchLoginLocalStorage} from '../../actions/loginActionsCreator';
import { fetchRespuestaLogin } from '../../actions/homeActionsCreator';
import { fetchQuestionaryToAns, saveParcial, saveParcialEvaluacion, sendFinal, sendFinalEvaluacion } from '../../actions/UserAnsActionCreator';
import { HeaderPreviewQuestionaryComp } from '../viewQuestionary/HeaderPreviewQuestionaryComp'; //'../HeaderPreviewQuestionaryComp';
import { Tabs, Button, Row, Col, message, Card, Divider, Alert } from 'antd';
import { LoadingComp } from '../genericComponents/LoadingComp';
import { validateRequieredAns, truncate, validateRequieredOneAns } from '../../helpers/helpers'
import { GUTTER_BASE, MESSAGE_CONFIG, TABS_ELIPSIS } from '../../configs/layoutVars';
import * as uiTexts from '../../configs/uiTexts';
import * as styleVars from '../../configs/styleVars';
import QuestionsCreatorComp from '../NewQuestionary/QuestionsCreatorComp'
import { linksRouter } from '../../configs/baseUrl';
import PersonalData from './PersonalData';
import DataDownload from './DataDownload';
import {getAccessTokenApi} from '../../functions/auth';

//simport { linksRouter } from '../../configs/baseUrl';


const TabPane = Tabs.TabPane;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary,
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    fetchQuestionaryToAns: (token, idCuestionario) => dispatch(fetchQuestionaryToAns(token, idCuestionario)),
    showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
    saveParcial: (questionary, token) => dispatch(saveParcial(questionary, token)),
    saveParcialEvaluacion: (questionary, token) => dispatch(saveParcialEvaluacion(questionary, token)),
    sendFinal: (questionary, token) => dispatch(sendFinal(questionary, token)),
    sendFinalEvaluacion: (questionary, token) => dispatch(sendFinalEvaluacion(questionary, token)),
    fetchRespuestaLogin: () => dispatch(fetchRespuestaLogin()),
    fetchLoginBD:(usuario, idCuestionario) => dispatch(fetchLoginBD(usuario, idCuestionario)),
    fetchLoginLocalStorage:() => dispatch(fetchLoginLocalStorage())
});


message.config(MESSAGE_CONFIG);


/** Sections from questionary ****************/
const tabsBuilder = (sections, editionEstatus) => {
    //console.log(sections);
    // eslint-disable-next-line
    return (sections.map((section, index) => {
        //console.log(index);

        return (
            <TabPane
                style={styleVars.tabs}
                tab={truncate(TABS_ELIPSIS, section.title)}
                disabled={section.disabled}
                key={index}
            >

                <Row gutter={GUTTER_BASE}>
                    <Col span={24}>
                        <Col span={24} style={styleVars.verticalMargin30}>
                            <span style={{ color: styleVars.MAIN_COLOR, width: "50%", fontSize: "20px", fontWeight: "bold" }}>{section.title}</span>
                        </Col>
                    </Col>

                    <Col span={24}>
                        <Col span={24} style={styleVars.verticalMargin15}>
                            <span style={{ color: "#676767", fontSize: "16px" }}>
                                <b>Instrucciones</b>:&nbsp;{(section.relatedInfo !== "" ? section.relatedInfo : uiTexts.SECTION_INSTRUCTIONS)}
                            </span>
                        </Col>
                    </Col>



                </Row>
                <QuestionsCreatorComp editionEstatus={editionEstatus} section={section.idSection} questions={section.questions} />
            </TabPane>
        )
    })
    )
}







class ResponseUsers extends Component {
    constructor(props) {
        super(props)
        this.state = { disableAvanceSend: true, disableSend: true, idCuestionario: this.props.match.params.id }
    }
    

    componentWillMount() {
        //console.log("responseusers", this.props)

        const valorURL= this.props.match.params.id
        if (!this.props.credentials.isAuthenticated) {
            if (this.props.credentials.showLogin !== actions.LOGIN && this.props.credentials.showLogin !== actions.LOGING 
                && this.props.credentials.showLogin !== actions.LOGED_OUT)

               
                if(valorURL.includes('_~')){

                    var splitCadena=valorURL.split('_~')
                    let buff2 = new Buffer(splitCadena[1], 'base64')
                    let text = buff2.toString('ascii')

                    localStorage.setItem("correo", text)

                    if(localStorage.getItem('token')){
                        const jwt = getAccessTokenApi();
                        if (jwt) {
                            this.props.fetchLoginLocalStorage()
                        }else{
                            localStorage.removeItem('token')
                            this.props.fetchLoginBD(localStorage.getItem('correo'), splitCadena[0])
                        }
                    }else{
                        this.props.fetchLoginBD(localStorage.getItem('correo'), splitCadena[0])
                    }
                }
                else{
                    this.props.fetchRespuestaLogin(this.props.credentials.isAuthenticated, this.props.credentials.token, actions.LOGIN, valorURL, null);
                    this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, actions.LOGIN, valorURL, null)
                }
                
        }
        else if (this.props.questionary.questionary === null && !this.props.questionary.isLoading) {
            if(localStorage.getItem('correo')){
                var splitCadena2=valorURL.split('_~')
                this.props.fetchQuestionaryToAns(this.props.credentials.token, splitCadena2[0]);
            }else{
                this.props.fetchQuestionaryToAns(this.props.credentials.token, this.props.match.params.id);
            }
        }
    }
    componentDidUpdate() {
        try {
            //Validación del botón de guardado de avance
            const evalAvance = validateRequieredOneAns(this.props.questionary.questionary.sections)
            if (evalAvance !== this.state.disableAvanceSend)
                this.setState({ disableAvanceSend: evalAvance })

            //Validación del botón de guardado final
            const evalDisable = validateRequieredAns(this.props.questionary.questionary.sections)
            if (evalDisable !== this.state.disableSend)
                this.setState({ disableSend: evalDisable })
        } catch (error) {

        }

    }

    /* Actions from Questionary ****************/
    actionButtonsBuilder = (actions, isSending) => {
        //console.log(actions)
        return (
            // eslint-disable-next-line
            actions.map((action, index) => {
                //console.log({ ...action })
                if (action.visible) {
                    if (action.action === "DOWNLOAD_ANSWERS" || action.action === "DOWNLOAD_RESP_EVALUACION") {
                        return (
                            <Col sm={4} offset={2} key={index}>
                                <DataDownload />
                            </Col>
                        );
                    }
                    else {
                       // console.log("this.state.disableAvanceSend", this.state.disableAvanceSend)
                        return (
                            <Col sm={4} offset={2} key={index}>
                                <Button
                                    type={action.type}
                                    actionclick={action.action}
                                    onClick={() => this.handlerSaveButton(action)}
                                    block={false}
                                    loading={isSending}
                                    disabled={
                                        action.disabled ?
                                            action.disabled :
                                            action.action === "SAVE_PROGRESS" || action.action === "SAVE_PROGRESS_EVALUACION" ?
                                              this.state.disableAvanceSend :
                                                this.state.disableSend
                                    }
                                    key={index}
                                >
                                    {action.label}
                                </Button>
                            </Col>
                        )
                    }
                }
            })
        )
    }

    handlerSaveButton = (action) => {
        //console.log("clicked!! ", action);
        switch (action.action) {
            case "SAVE_PROGRESS":
                this.props.saveParcial(this.props.questionary.questionary, this.props.credentials.token);
                break;
                case "SAVE_PROGRESS_EVALUACION":
                    this.props.saveParcialEvaluacion(this.props.questionary.questionary, this.props.credentials.token);
                    break;
            case "SEND_QUESTIONARY":
                this.props.sendFinal(this.props.questionary.questionary, this.props.credentials.token);
                break;
            case "SEND_EVALUACION":
                    this.props.sendFinalEvaluacion(this.props.questionary.questionary, this.props.credentials.token);
                    break;
            default:
                break;
        }
    }


    render() {


        if (!this.props.credentials.isAuthenticated)
            return null;

        if (this.props.questionary.isLoading || this.props.questionary.isLoading === null || this.props.questionary.isSending)
            return <LoadingComp />;

        else if (this.props.questionary.message !== null && !this.props.questionary.succesSend)
            return <h2>{uiTexts.FAILED_FETCH_MESSAGE}</h2>


        if (this.props.questionary.succesSend) {
            if (!this.props.questionary.questionary) {
                this.props.history.replace("/" + linksRouter.successAnsUser)
                return null;

                /*
                 return ((this.props.questionary.succesSend && this.props.questionary.message !== null) &&
                 <Alert message={this.props.questionary.message} type="success" />)
                */
            }
        }

        /*
                if (this.props.questionary.succesSend && this.props.questionary.message === null)
                    return (
                        <React.Fragment>
                            <Card gutter={GUTTER_BASE}>
                                <h2>Resultado</h2>
                                <Divider></Divider>
                                <Row>
                                    <Alert message={"El cuestionario se ha enviado correctamente"} type="success" />
                                </Row>
                            </Card>
                        </React.Fragment>
                    )
        */

        if (this.props.questionary.questionary.header) {
            
            return (
                
                <React.Fragment>


                    <Card gutter={GUTTER_BASE}>
                        <HeaderPreviewQuestionaryComp header={this.props.questionary.questionary.header} />
                        <Row>
                            <Col style={{ color: "#888888" }} span={24}>
                                <PersonalData></PersonalData>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row>
                            {
                                (this.props.questionary.succesSend && this.props.questionary.message !== null) &&
                                <Alert message={this.props.questionary.message} type="success" />
                            }
                        </Row>
                        <Tabs >
                            {tabsBuilder(this.props.questionary.questionary.sections, this.props.editionEstatus)}
                        </Tabs>

                        <Row gutter={GUTTER_BASE}>
                            {this.actionButtonsBuilder(this.props.questionary.questionary.header.actions, this.props.questionary.isSending)}
                        </Row>
                    </Card>
                </React.Fragment>
            );
        }
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponseUsers));