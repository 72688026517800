import React, { Component } from 'react';
import { Col, /*InputNumber*/Row } from 'antd';
//import { Radio } from 'antd';
import { verticalMargin15 } from '../../configs/styleVars';
import { connect } from 'react-redux';
import { updateHeader } from '../../actions/previewQuestionaryActionCreator';
import { cloneObject } from '../../helpers/helpers';

//const RadioGroup = Radio.Group;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary,
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateHeader: (questionary, header) => dispatch(updateHeader(questionary, header))
});


class PersonalData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            age: null,
            gender: null
        }

        this.onChangeAge = this.onChangeAge.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
    }

    onChangeAge(value) {
        this.setState({ age: value });
    }

    onChangeGender({ target }) {
        this.setState({ gender: target.value }, () => this.updateToRedux());
    }


    updateToRedux = () => {
        let newHeader = cloneObject(this.props.questionary.questionary.header);
        newHeader.age = this.state.age;
        newHeader.gender = this.state.gender
        //console.log(newHeader);

        this.props.updateHeader(this.props.questionary.questionary, newHeader)
    }

    render() {
        return (

            <Row>
                <Col style={verticalMargin15} md={11} offset={1}>
                    {this.props.questionary.questionary.header.fullName}
                </Col>


            </Row>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
/*

 <Col style={verticalMargin15} md={3} offset={1}>
                    <InputNumber
                        value={this.state.age}
                        onChange={this.onChangeAge}
                        min={18} max={99} placeholder="Edad"
                        onBlur={this.updateToRedux}
                    />
                </Col>

                <Col style={verticalMargin15} md={7} offset={1}>
                    <b>Sexo </b>&nbsp;
                        <RadioGroup style={{ width: "auto" }} onChange={this.onChangeGender} >
                        <Radio value={"M"}>Masculino</Radio>
                        <Radio value={"F"}>Femenino</Radio>
                    </RadioGroup>
                </Col>

*/