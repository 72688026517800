import React, { Component } from 'react';
import { Form, Input } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';


const FormItem = Form.Item;


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});



class InputComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.isChild ? this.props.question.childQuestion.value : this.props.question.value,
            questionaryToSend: null,
            questionToSend: null
        }
        this.onChange = this.onChange.bind(this)
        //console.log(this.props);

    }


    /** onBlur Input ****************/
    onBlur = (questionary, section, question) => {
        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)

        if (this.props.isChild)
            newQuestion.childQuestion.value = this.state.value;
        else
            newQuestion.value = this.state.value;

        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    /** onChange Input ****************/
    onChange({ target }) {
        this.setState({ value: target.value });
    }


    render() {

        if (!this.props.question.visible)
            return (null)
        if (this.props.isChild)
            if (!this.props.question.childQuestion.visible)
                return (null)

        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (
                <FormItem colon={layoutVars.COLON} label={this.props.isChild && this.props.question.childQuestion.label} >
                    <Input readOnly={true} />
                </FormItem>
            )

        return (
            <FormItem
                disabled={this.props.isChild ? this.props.question.childQuestion.disabled : this.props.question.disabled}
                colon={layoutVars.COLON}
                //label={this.props.isChild ? this.props.question.childQuestion.label : this.props.question.label}
                required={this.props.isChild ? this.props.question.childQuestion.required : this.props.question.required}
                validateStatus={this.props.isChild ? this.props.question.childQuestion.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR : this.props.question.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                help={this.props.isChild ? this.props.question.childQuestion.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null : this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >


                <Input
                    value={this.state.value}
                    onChange={this.onChange}
                    onBlur={() => this.onBlur(this.props.questionary, this.props.section, this.props.question)}
                    disabled={this.props.question.disabled}
                />
            </FormItem>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InputComp));