/**
 * Actions for login
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGIN_SHOW_MODAL = 'LOGIN_SHOW_MODAL';
export const LOGOUT_FETCHING = "LOGOUT_FETCHING";
export const LOGOUT = "LOGOUT";
export const LOGED_OUT = "LOGED_OUT";
export const RESET_LOGIN = "RESET_LOGIN"
export const LOGING = "LOGING"
export const LOGIN = "LOGIN"
export const NO_ACCESS = "NO_ACCESS";

/**
 * Actions to set status and router Values
 */
export const NAVIGATE_TO = "NAVIGATE_TO"


/** 
 * Actions to load and display questionary
 * */
export const LOADING_QUESTIONARY = 'LOADING_QUESTIONARY';
export const FAILED_LOAD_QUESTIONARY = 'FAILED_LOAD_QUESTIONARY';
export const ADD_QUESTIONARY_TO_SCREEN = 'ADD_QUESTIONARY_TO_SCREEN';
export const PROCESSING_QUESTIONARY = 'PROCESSING_QUESTIONARY';


/**
 * Actions to update questionary on change components
 */
export const UPDATED_VALUES = 'UPDATED_VALUES';
export const UPDATED_VALUES_TEMP_QUEST = "UPDATED_VALUES_TEMP_QUEST"



/** 
* Global Questionary actions
*/
export const SAVE_PROGRESS = "SAVE_PROGRESS";
export const SEND_QUESTIONARY = "SEND_QUESTIONARY";
export const SENDING_QUESTIONARY = "SENDING_QUESTIONARY";
export const FAILED_SEND_QUESTIONARY = "FAILED_SEND_QUESTIONARY";
export const SUCCESS_SEND_QUESTIONARY = "SUCCESS_SEND_QUESTIONARY";
export const SUCCESS_SEND_PROGRESS_QUESTIONARY = "SUCCESS_SEND_PROGRESS_QUESTIONARY";
export const DOWNLOAD_ANSWERS = "DOWNLOAD_ANSWERS";


/**
 * Clear all values
 */
export const RESET_VALUES = "RESET_VALUES";

/**
 * Actions to new Questions
 */
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const EDIT_QUESTION = "EDIT_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const CLOSE_EDITOR = "CLOSE_EDITOR";


/**
 * Actions to Home
 */

export const LOADING_PUBLIC_HOME = "LOADING_PUBLIC_HOME";
export const SUCCESS_PUBLIC_HOME = 'SUCCESS_PUBLIC_HOME';
export const FAILED_PUBLIC_HOME = 'FAILED_PUBLIC_HOME';
export const CLEAN_PUBLIC_HOME = 'CLEAN_PUBLIC_HOME';


/**
 *  Actions to Home cards list 
 */
export const LOADING_LIST_QUEST = "LOADING_LIST_QUEST";
export const SUCCESS_LIST_QUEST = 'SUCCESS_LIST_QUEST';
export const FAILED_LIST_QUEST = 'FAILED_LIST_QUEST';
export const CLEAN_DATA_LIST_QUEST = "CLEAN_DATA_LIST_QUEST";

export const CLONING_QUEST = "CLONING_QUEST";
export const SUCCESS_CLONE_QUEST = 'SUCCESS_CLONE_QUEST';
export const FAILED_CLONE_QUEST = 'FAILED_CLONE_QUEST';

export const DELETING_QUEST = "DELETING_QUEST";
export const SUCCESS_DELETE_QUEST = 'SUCCESS_DELETE_QUEST';
export const FAILED_DELETE_QUEST = 'FAILED_DELETE_QUEST';


export const DELETING_ANS_QUEST = "DELETING_ANS_QUEST";
export const SUCCESS_DELETE_ANS_QUEST = 'SUCCESS_DELETE_ANS_QUEST';
export const FAILED_DELETE_ANS_QUEST = 'FAILED_DELETE_ANS_QUEST';




/**
 * Home cards galery actions
 */
export const homeCardsActions = {
    PREVIEW_QUEST: "VIEW_QUESTIONARY",
    DELETE_QUEST: "DROP_QUESTIONARY",
    CLONE_QUEST: "DUPLICATE_QUESTIONARY",
    EDIT_QUEST: "EDIT_QUESTIONARY",
    EDIT_PERIOD: "EDIT_PERIOD",
    ADD_PERIOD: "ADD_PERIOD",
    POST_QUESTIONARY: "POST_QUESTIONARY",
    VIEW_URL: "VIEW_URL"
}


/**
 * Publish actions
 */
export const LOADING_DATA_PUBLISH = "LOADING_DATA_PUBLISH";
export const FAILED_FETCH_DATA_PUBLISH = "FAILED_FETCH_DATA_PUBLISH";
export const SUCCESS_FETCH_DATA_PUBLISH = "SUCCESS_FETCH_DATA_PUBLISH";


export const FAILED_PUBLISH = "FAILED_PUBLISH";
export const SUCCESS_PUBLISH = "SUCCESS_PUBLISH";
export const CLOSE_PUBLISH_WINDOW = "CLOSE_PUBLISH_WINDOW";
export const PUBLISHING = "PUBLISHING";


/**
 * Editing period actions
 */
export const LOADING_PERIOD = "LOADING_PERIOD";
export const FAILED_FETCH_PERIOD = "FAILED_FETCH_PERIOD";
export const SUCCESS_FETCH_PERIOD = "SUCCESS_FETCH_PERIOD";
export const CLEAR_FETCH_PERIOD = "CLEAR_FETCH_PERIOD";

export const FAILED_EDITED_PERIOD = "FAILED_EDITED_PERIOD";
export const SUCCESS_EDITED_PERIOD = "SUCCESS_EDITED_PERIOD";
export const CLOSE_PERIOD_WINDOW = "CLOSE_PERIOD_WINDOW";
export const SAVING_EDITED_PERIOD = "SAVING_EDITED_PERIOD";

/**
 * Inbox answers actions
 */

export const LOADING_INBOX_ANS = "LOADING_INBOX_ANS";
export const FAILED_FETCH_INBOX_ANS = "FAILED_FETCH_INBOX_ANS";
export const SUCCESS_FETCH_INBOX_ANS = "SUCCESS_FETCH_INBOX_ANS";

/**
 * Preview answered Questionary
 */

export const LOADING_PREVIEW_ANS = "LOADING_PREVIEW_ANS";
export const FAILED_FETCH_PREVIEW_ANS = "FAILED_FETCH_PREVIEW_ANS";
export const SUCCESS_FETCH_PREVIEW_ANS = "SUCCESS_FETCH_PREVIEW_ANS";
export const CLOSE_PREVIEW_ANS_WINDOW = "CLOSE_PREVIEW_ANS_WINDOW";


/**
 * Download data actions
 */

export const LOADING_DATA_DOWNLOAD = "LOADING_DATA_DOWNLOAD";
export const FAILED_FETCH_DATA_DOWNLOAD = "FAILED_FETCH_DATA_DOWNLOAD";
export const SUCCESS_FETCH_DATA_DOWNLOAD = "SUCCESS_FETCH_DATA_DOWNLOAD";
export const CLEAN_FETCH_DATA_DOWNLOAD = "CLEAN_FETCH_DATA_DOWNLOAD";


/**
 * Messages actions
 */

 export const SEND_MESSAGE="SEND_MESSAGE";
 export const CLEAR_MESSAGE="CLEAR_MESSAGE";