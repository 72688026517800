import * as ActionTypes from '../actions/actions';

export const inboxAnsRed = (state = {
    isLoading: null,
    payload: null,
    message: null,
    typeMessage: null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_INBOX_ANS:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }

        case ActionTypes.FAILED_FETCH_INBOX_ANS:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }

        case ActionTypes.SUCCESS_FETCH_INBOX_ANS:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }


        case ActionTypes.DELETING_ANS_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal }

        case ActionTypes.FAILED_DELETE_ANS_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal }

        case ActionTypes.SUCCESS_DELETE_ANS_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal }

        case ActionTypes.RESET_VALUES:
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal }


        default:
            return state;
    }
}