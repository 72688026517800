import * as ActionTypes from '../actions/actions';

export const previewAnsRed = (state = {
    isLoading: null,
    payload: null,
    message: null,
    openModal: null,
    succesSend: null,
    typeMessage: null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_PREVIEW_ANS:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, succesSend: action.succesSend, typeMessage: action.typeMessage  }

        case ActionTypes.FAILED_FETCH_PREVIEW_ANS:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, succesSend: action.succesSend, typeMessage: action.typeMessage  }

        case ActionTypes.SUCCESS_FETCH_PREVIEW_ANS:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, succesSend: action.succesSend, typeMessage: action.typeMessage  }

        case ActionTypes.CLOSE_PREVIEW_ANS_WINDOW:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, succesSend: action.succesSend, typeMessage: action.typeMessage  }

        default:
            return state;
    }
}


