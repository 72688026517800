/** Used with label, whether to display : after label text. */
export const COLON = false;

/** Property of Row as grid spacing */
export const GUTTER_BASE = 16;
export const GUTTER_BASE_LISTQUEST = 36;
export const ADMIN_ROL="ADMIN"


/** Login */
export const MAX_LENGTH_USER = 22;
export const MAX_LENGTH_PASS = 30;

/** Property to validate status of form components, which could be 'success', 'warning', 'error', 'validating'. */

export const VALIDATE_SUCCESS = "success"
export const VALIDATE_WARNING = "warning"
export const VALIDATE_ERROR = "error"
export const VALIDATE_VALIDATING = "validating"


export const MESSAGE_CONFIG = { top: 150, duration: 2, maxCount: 1 };

export const urlDatosPersonales = "https://www.ine.mx/credencial/manifestacion-proteccion-datos-personales-del-registro-federal-electores/"
export const urlCAU = "https://cau.ine.mx/"

export const MAX_LENGTH_QUESION = 450;
export const MAX_LENGTH_LABELS = 100;

export const MAX_LENGTH_TITLE = 200;
export const MAX_LENGTH_RELATED_INFO = 100;
export const MAX_LENGTH_SECTION = 200;


/**
 * lenght of elipssis
 */

export const TABS_ELIPSIS = 50;
export const TABS_ELIPSIS_CARD = 100;