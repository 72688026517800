
export const MAIN_COLOR = "#d5007f";
export const fontSizeIconSlider = { fontSize: '20px' };
export const failureText = { color: "red", fontWeight: "bold" };
export const ReCAPTCHA = { margin: "15px 0" };
export const inputNoBorder = { border: "none" };
export const verticalMargin15 = { marginTop: "15px", marginBottom: "15px" }
export const verticalMargin30 = { marginTop: "30px", marginBottom: "30px" }
export const verticalMargin45 = { marginTop: "45px", marginBottom: "45px" }
export const pinkText = { color: "#d5007f" };
export const questionCard = { margin: "15px" }
export const labelsCheckboxEditor = { display: "inline-block", margin: "10px 10px" }
export const relatedInfo = { "display": "block", "width": "100%", "margin": "15px 0" }
export const relatedInfoBad = {backgroundColor : "#FFDED7"}
export const relatedInfoGood = {backgroundColor : "#fff"}
export const gralCard = { width: "85%", "margin": "0 auto" }
export const tabs = { "backgroundColor": "white" }
export const highlightText = { color: "#d5007f", fontWeight: "bold" }
export const loginButtonHeader = { float: "right", width: "150px", height: "100%", borderRadius: 0, marginRight: "-40px", fontSize: "16px" }
export const verticalRadioGAlign = { display: 'block', height: '30px', lineHeight: '30px' }
export const fontSize16={fontSize:"16px"}
export const fontSize15={fontSize:"15px"}
export const fontSize12={fontSize:"12px"}
export const cardStyle={ width: "150px", border: "red 2px dashed", textAlign: "center", padding: "40px 0", backgroundColor: "transparent", float: "left", margin: "15px" };
export const wRadio={width: "25px", lineHeight:"25px"};
export const answer={overFlowWrap: "break-word !important", width : "98%", textAlign: "justify", lineHeight:"19px"};
