import * as ActionTypes from './actions';
//import { baseUrl } from '../configs/baseUrl';
//import { cloneObject, setDisableStatusIntoQuest } from '../helpers/helpers'



export const updateTemporalQuestion = (tempQuestion) => {   
    return (
        {
            type: ActionTypes.UPDATED_VALUES_TEMP_QUEST,
            question: tempQuestion,
            message: null
        })
}




