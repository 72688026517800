import React, { Component } from 'react';
import { Row, Col } from 'antd';
import InputFactoryComp from './individualEditComps/InputFactoryComp'
import RadioFactoryComp from './individualEditComps/RadioFactoryComp';
import CheckBoxFactoryComp from './individualEditComps/CheckBoxFactoryComp';
import RateFactoryComp from './individualEditComps/RateFactoryComp';
import DatePickerFactoryComp from './individualEditComps/DatePickerFactoryComp';
import DateRangePickerFactoryComp from './individualEditComps/DateRangePickerFactoryComp';
import TimePickerFactoryComp from './individualEditComps/TimePickerFactoryComp';
import SelectFactoryComp from './individualEditComps/SelectFactoryComp';
import SliderFactoryComp from './individualEditComps/SliderFactoryComp';
import { connect } from 'react-redux';
import * as galeryTypes from '../../configs/galeryTypes';
import * as layoutVars from '../../configs/layoutVars';


const mapStateToProps = (state) => {
    return {
        editionEstatus: state.editionEstatus
    }
}

const mapDispatchToProps = (dispatch) => ({

});
// eslint-disable-next-line 
const typeSelect = (props, typeQuestionary) => {
    switch (props.type) {


        case galeryTypes.INPUT:
            return <InputFactoryComp {...props} />

        case galeryTypes.RADIO:
            return <RadioFactoryComp {...props} typeQuestionary={typeQuestionary}/>

        case galeryTypes.CHECKBOX:
            return <CheckBoxFactoryComp {...props} typeQuestionary={typeQuestionary}/>

        case galeryTypes.RATE:
            return <RateFactoryComp {...props} />

        case galeryTypes.DATEPICKER:
            return <DatePickerFactoryComp {...props} />

        case galeryTypes.DATERANGE_PICKER:
            return <DateRangePickerFactoryComp {...props} />

        case galeryTypes.SELECT:
            return <SelectFactoryComp {...props} typeQuestionary={typeQuestionary}/>

        case galeryTypes.TIMEPICKER:
            return <TimePickerFactoryComp {...props} />

        case galeryTypes.SLIDER:
            return <SliderFactoryComp {...props} />

        default:
            return null;

    }
}





class QuestionEditorComp extends Component {

    render() {
        return (
            <Row gutter={layoutVars.GUTTER_BASE}>
                <Col span={24}>
                    {typeSelect(this.props.editionEstatus.question, this.props.typeQuestionary)}
                </Col>
            </Row>
        );
    }
}





export default connect(mapStateToProps, mapDispatchToProps)(QuestionEditorComp);