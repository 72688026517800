import jwtDecode from "jwt-decode";
export function getAccessTokenApi() {
    const accessToken = localStorage.getItem('token'); //Se verifica que el token exista en el localstorage
  
    if (!accessToken || accessToken === "null") {
      return null;
    }
  
    return willExpireToken(accessToken) ? null : accessToken;
  }

  function willExpireToken(token) {

    const tokenFinal= JSON.parse(token)
    const seconds = 60;
    const metaToken = jwtDecode(tokenFinal.accessToken); //decodifica el token
    const { exp } = metaToken; //Se obtiene el tiempo de vida del token
    const now = (Date.now() + seconds) / 1000;
    return now > exp; //verifica que no hay expirado
  }