import React, { Component } from 'react';
import { Row, Col, Input, Tag, Icon, Card, message, Divider } from 'antd';
//import { connect } from 'react-redux';
//import DateRange from './DateRange';
import { connect } from 'react-redux';
import * as layoutVars from '../../configs/layoutVars';
import { validateMail } from '../../helpers/helpers';
import * as styleVars from '../../configs/styleVars';
import * as uiTexts from '../../configs/uiTexts';


/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        //   credentials: state.credentials,
        publishData: state.publishData
    }
}

/** Redux get dispatch functions ****************/

/*const mapDispatchToProps = (dispatch) => ({
    fetchDataPublish: (token, idQuestionario) => dispatch(fetchDataPublish(token, idQuestionario)),
});*/



class MailConfig extends Component {
    constructor(props) {
        super(props)
        this.saveInputRef = React.createRef();
        this.saveInputRef1 = React.createRef();
        //console.log(this.props);
        this.state = {
            tags: this.props.formState.mailDataValues === null ? this.props.publishData.payload.correo.listaCorreosCopia : this.props.formState.mailDataValues.tags,
            inputVisible: false,
            inputValue: '',
            inputValue1: '',
            tempInputValue1:'',
            inputId:'',
            urlInput: this.props.formState.mailDataValues === null ? "" : this.props.formState.mailDataValues.urlInput
        };

    }

    showInput = () => {
        this.setState({ inputVisible: true });
    }

    handleInputChange = (e) => {
      
       
      //this.setState({ inputId: e.target.value });
      if(e.target.id==="2"){ 
        this.setState({ inputValue: e.target.value });  
      }
      else if(e.target.id==="1"){ 
        this.setState({ inputValue1: e.target.value });  
      }
    }

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
        this.setState({tempInputValue1:''})
    }

    handleInputConfirm = () => {
        const state = this.state;
        const inputValue = state.inputValue;
        const inputValue1 = state.inputValue1;
        let tempInputValue1=state.tempInputValue1;
        let tags = state.tags;
        ///console.log("por aqui");
        console.log("tamaño arreglo", tags.length);
        if (validateMail(inputValue)|| validateMail(inputValue1)) {

            if (inputValue1 && tags.indexOf(inputValue1) === -1) {
                tags = [ inputValue1, ...tags];
                tempInputValue1= inputValue1; 

            }
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue];

            }
        } else {
            message.error('inserta uno válido');
        }

        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
            inputValue1:'',
            tempInputValue1
        }, () => this.props.setMailData(this.state));
//console.log ("TAGS", tags)
//console.log("tamaño arreglo1", tags.length);
    }
    

    newMailCreator = () => {
        const { inputVisible, inputValue} = this.state;
       
        return (
            <React.Fragment>
 
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        id="2"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag

                        onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}
                    >
                        <Icon type="plus" /> {uiTexts.ADD_MAIL}
                    </Tag>
                )}

            </React.Fragment>
        )
                  
    }

    newMail = () => {
        const { inputValue1,tempInputValue1 } = this.state;
        return (
            <React.Fragment>
 
                {tempInputValue1 ===''&&
                 (   <Input
                        ref={this.saveInputRef1}
                        type="text"
                        size="small"
                        id="1"
                        placeholder="Ingresa correo"
                        style={{ width: 200 }}
                        value={inputValue1}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                        closable="true"
                    />)}
                    {tempInputValue1 !==''&&

                   ( 
                    <Tag key={tempInputValue1} closable={true} afterClose={() => this.handleClose(tempInputValue1)}>
                        {tempInputValue1}
                    </Tag>
                  )
                }

            </React.Fragment>
        )
    
 
    }


    tagsCreator = () => {
        const { tags, tempInputValue1 } = this.state;
        
        return (

            tags.map((tag, index) => {
                //const isLongTag = tag.length > 20;
                if(tag !==tempInputValue1){
                const tagElem = (
                    <Tag key={tag} closable={index !== 1} afterClose={() => this.handleClose(tag)}>
                        {tag}
                    </Tag>
                );
                return tagElem;}
            })

        )

    }


    render() {


        //console.log(this.props.publishData.payload.organos);
        //console.log(this.props.formState.configPeriodValues.idJunta - 1);


        return (
            <React.Fragment>
                <Row gutter={layoutVars.GUTTER_BASE}>
                    <Col span={22} offset={1}>
                        <h3>Correo</h3>
                        <div>Para: {this.newMail()}</div>
                        <div>CC: {this.tagsCreator()} {this.newMailCreator()} </div>
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={layoutVars.GUTTER_BASE}>
                    <Col span={22} offset={1}>
                        <h3>Mensaje</h3>
                    </Col>
                    <Col span={22} offset={1}>
                        <Card>
                            <h4>{uiTexts.PRESENTATION_TXT}</h4>
                            <p>{uiTexts.DESCR_TXT_1}<b>{this.props.publishData.payload.tituloCuestionario}</b>{uiTexts.DESCR_TXT_2}<b>
                                {this.props.publishData.payload.nombreAreaCreacion}</b> </p>
                            
                            {/* se comenta apartado de publicacion {uiTexts.DESCR_TXT_3}</p>
                            <Input
                                value={this.state.urlInput} placeholder={uiTexts.PLACEHOLDER_LINK}
                                onChange={(e) => {
                                    this.setState({ urlInput: e.target.value },
                                        () => {
                                            if (this.state.urlInput.length >3) {
                                                this.props.enableDisableSaveBtn(false)
                                            }
                                            else {
                                                this.props.enableDisableSaveBtn(true)
                                            }
                                            this.props.setMailData(this.state)
                                        }
                                    )
                                }}
                            >
                            </Input>*/}

                            <p style={styleVars.verticalMargin15}>{uiTexts.FINAL_TEXT} <b> {this.props.formState.configPeriodValues.initialRange} </b>al <b> {this.props.formState.configPeriodValues.finalRange} </b>Aplicación:<b> {this.props.publishData.payload.organos[this.props.formState.configPeriodValues.idJunta - 1].label}</b></p>

                        </Card>
                    </Col>
                </Row>


            </React.Fragment>
        )

    }
}

export default connect(mapStateToProps)(MailConfig);
/**
 * 
 */