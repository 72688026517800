import React, { Component } from 'react'
import { Modal, Input, Col, Row, Divider, Switch, Radio } from 'antd';
import { connect } from 'react-redux';
import { addQuestionaryToScreen } from '../../actions/previewQuestionaryActionCreator';
import * as layoutVars from '../../configs/layoutVars'
import { verticalMargin15 } from '../../configs/styleVars';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '../../configs/baseUrl';
import { cloneObject } from '../../helpers/helpers';
import * as uiTexts from '../../configs/uiTexts'




/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    addQuestionaryToScreen: (questionary) => dispatch(addQuestionaryToScreen(questionary)),
});






class ModalHeadQuest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionaryName: '',
            sectionName: "",
            relatedInfo: "",
            type: "",
            personalData: false
        };
    }
    onChangeTitle = (e) => {
        this.setState({ questionaryName: e.target.value });
    }
    onChangeSection = (e) => {
        this.setState({ sectionName: e.target.value });
    }

    onChangeType = (e) => {
        this.setState({ type: e.target.value });
    }
    
    onChangeDescription = (e) => {
        this.setState({ relatedInfo: e.target.value });
    }

    handleOk = (e) => {
        //console.log(this.state);
        let newQuestionary = cloneObject(this.props.questionary.questionary);
        newQuestionary.header.questionaryName = this.state.questionaryName;
        newQuestionary.header.relatedInfo = this.state.relatedInfo;
        newQuestionary.header.personalData = this.state.personalData;
        newQuestionary.sections[0].title = this.state.sectionName;

        switch (this.state.type) {
            case 1:
                newQuestionary.header.type = this.state.personalData ? uiTexts.NEW_QUESTIONARY_TYPE3 : uiTexts.NEW_QUESTIONARY_TYPE1;
                break;
            case 2:
                newQuestionary.header.type = this.state.personalData ? uiTexts.NEW_QUESTIONARY_TYPE4 : uiTexts.NEW_QUESTIONARY_TYPE2;
                break;
            default:
                break;
        }
      
        //console.log(newQuestionary);
        this.props.addQuestionaryToScreen(newQuestionary)
    }

    handleCancel = (e) => {
        this.props.history.push("/" + linksRouter.questionaryList);
    }


    render() {
        // console.log(this.state);
        return (
            <Modal
                title={uiTexts.NEW_QUESTIONARY_MODAL_TITLE}
                visible={true}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: (this.state.questionaryName !== "" && this.state.sectionName !== "" && this.state.type !== "") ? false : true }}
            >
                <Row gutter={layoutVars.GUTTER_BASE}>

                <Col span={24} style={verticalMargin15}>
                        <Col span={24}><b>{uiTexts.NEW_QUESTIONARY_TITLE_TIPO}</b> </Col>
                        <Radio.Group style={verticalMargin15} onChange={this.onChangeType} value={this.state.type}>
                            <Radio value={1}>{uiTexts.NEW_QUESTIONARY_RADIO_ENCUESTA}</Radio>
                            <Radio value={2}>{uiTexts.NEW_QUESTIONARY_RADIO_EVALUACION}</Radio>
                        </Radio.Group>
                    </Col>

                    <Col span={24} style={verticalMargin15}>
                        <Col span={24}><b>{uiTexts.NEW_QUESTIONARY_TITLE_LABEL}</b> </Col>
                        <Input
                            style={verticalMargin15}
                            placeholder={uiTexts.NEW_QUESTIONARY_TITLE_PLACEHOLDER}
                            value={this.state.questionaryName}
                            onChange={this.onChangeTitle}
                            maxLength={layoutVars.MAX_LENGTH_TITLE}
                        />
                    </Col>

                    <Col span={24} style={verticalMargin15}>
                        <Col span={24}><b>{uiTexts.NEW_QUESTIONARY_RELATEDINFO_LABEL}</b> </Col>
                        <Input
                            style={verticalMargin15}
                            placeholder={uiTexts.NEW_QUESTIONARY_RELATEDINFO_PLACEHOLDER}
                            value={this.state.relatedInfo}
                            onChange={this.onChangeDescription}
                            maxLength={layoutVars.MAX_LENGTH_RELATED_INFO}
                        />
                    </Col>

                    <Col span={24} style={verticalMargin15}>
                        <Col span={24}><b>{uiTexts.NEW_QUESTIONARY_SECTION_LABEL}</b> </Col>
                        <Input
                            style={verticalMargin15}
                            placeholder={uiTexts.NEW_QUESTIONARY_SECTION_PLACEHOLDER}
                            value={this.state.sectionName}
                            onChange={this.onChangeSection}
                            maxLength={layoutVars.MAX_LENGTH_SECTION}
                        />
                    </Col>


                    <Divider></Divider>
                    <Col span={24}>
                        <Switch onChange={(checked) =>
                            this.setState({ personalData: checked })} />  <b>{uiTexts.NEW_QUESTIONARY_MULTIPLE}</b>
                    </Col>
                </Row>
            </Modal>
        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalHeadQuest));

