import * as ActionTypes from './actions';

export const clearMessage = () => ({
    type: ActionTypes.CLEAR_MESSAGE,
    notifType:null,
    showMessage: false,
    title: null,
    description: null
});

export const sendMessage = (notifType, title, description) => ({
    type: ActionTypes.SEND_MESSAGE,
    notifType:notifType,
    showMessage: true,
    title: title,
    description: description
}); 