import * as ActionTypes from './actions';
import { errorStatusType } from './errorSelector';
import { ERROR_TYPE } from '../configs/galeryTypes';
import { resetLogin } from './loginActionsCreator';




/** functions fetch Inbox answers */

export const fetchDownloadData = (token, urlDownload, idQuestionario) => (dispatch) => {
    // console.log(token);

    dispatch(fetchingDownloadData());
    return fetch(urlDownload,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({ "idCuestionario": idQuestionario }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            let errmess = errorStatusType(404);
            throw errmess;
        })
        .then(response => response.json())
        .then(data => {
            dispatch(successDownloadData(data));

        })
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureDownloadData(error.message))
        }
    );
}

const fetchingDownloadData = () => ({
    type: ActionTypes.LOADING_DATA_DOWNLOAD,
    isLoading: true,
    payload: null,
    message: null,
});

const failureDownloadData = (error) => ({
    type: ActionTypes.FAILED_FETCH_DATA_DOWNLOAD,
    isLoading: false,
    payload: null,
    message: error,
});

const successDownloadData = (data) =>
    ({
        type: ActionTypes.SUCCESS_FETCH_DATA_DOWNLOAD,
        isLoading: false,
        payload: data,
        message: null,
    });

