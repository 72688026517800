import React, { Component } from 'react';
import { Form, Slider, Row, Col, Input, Divider, Switch } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';

import * as layoutVars from '../../../configs/layoutVars';
import * as styleVars from '../../../configs/styleVars';
import { connect } from 'react-redux';
import * as galeryTypes from '../../../configs/galeryTypes'
import { updateTemporalQuestion } from '../../../actions/updateTemporalQuestionActionCreator';


const FormItem = Form.Item;
const { TextArea } = Input;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateTemporalQuestion: (tempQuestion) => dispatch(updateTemporalQuestion(tempQuestion))
});



class SliderFactoryComp extends Component {

    constructor(props) {
        super(props);

        this.state = {

            disabled: this.props.disabled,
            idQuestion: this.props.idQuestion,
            label: this.props.label,
            relatedInfo: this.props.relatedInfo,
            required: this.props.required,
            title: this.props.title,
            type: galeryTypes.SLIDER,
            validateStatus: this.props.validateStatus,
            value: this.props.value,
            valueToShowChild: this.props.valueToShowChild,
            visible: this.props.visible,
            titleValidate: "",
            labelValidate: "",
            disableOkButton: true,
            addItemDisabled: false,
            labelsTemp: this.props.label,
            childQuestion: null

        }
    }

    onChange() {
        this.setState(() => { this.props.updateTemporalQuestion(this.state) })
    }

    validaButton() {
        if (this.state.title !== ""){
            this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) })
        }else{
            this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
        }
    }
    
    onChangeSlider = (e) => {

        this.setState({ value: e.toString() },
            () => this.onChange());

    }

    sliderCreator = (question) => {
        return <Slider
            onAfterChange={e => this.onChangeSlider(e)}
            min={0}
            max={100}
            defaultValue={question.value === "" ? 0 : parseInt(question.value, 10)}
        />
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <FormItem
                            colon={layoutVars.COLON}
                            label={uiTexts.QUESTION_TITLE_LABEL}
                            required={true}
                        >
                            <TextArea
                                autosize={{ minRows: 1, maxRows: 3 }}
                                maxLength={layoutVars.MAX_LENGTH_QUESION}
                                value={this.state.title}
                                placeholder={uiTexts.QUESTION_TITLE_LABEL_PLACEHOLDER}
                                onChange={(e) => {
                                    this.setState({ title: e.target.value })
                                }}
                                onKeyUp={(e) => {
                                    this.setState(
                                        () => this.validaButton())
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Divider orientation="left" style={styleVars.pinkText}>{uiTexts.PREVIEW_LABEL}</Divider>

                <Col span={12}>

                    <FormItem

                        colon={layoutVars.COLON}
                    >

                        <div className="icon-wrapper-slider">
                            {this.sliderCreator(this.state)}
                        </div>
                    </FormItem>
                </Col>

                <Col span={24} style={styleVars.verticalMargin30}>

                    <b>{uiTexts.REQUIERED_QUESTION_LABEL} &nbsp;&nbsp;&nbsp;</b> <Switch
                        checkedChildren={uiTexts.YES_LABEL}
                        unCheckedChildren={uiTexts.NOT_LABEL}
                        defaultChecked={this.state.required}
                        checked={this.state.required}
                        onChange={(checked) =>
                            this.setState({ required: checked }, () => this.onChange())}
                    />
                </Col>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SliderFactoryComp);


