import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import mensajeExito from "../../imgs/mensajeExito.svg";
import { resetValuesQuestionary } from '../../actions/previewQuestionaryActionCreator';
import { MSG_UNFINISHED_OR_ALREADY_FINISHED } from '../../configs/uiTexts';
import axios from 'axios'; 
import {UrlShowImagePublish} from '../../configs/baseUrl';
import * as ReactDOM from 'react-dom';
import { LoadingComp } from '../genericComponents/LoadingComp';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    resetValuesQuestionary: (message) => dispatch(resetValuesQuestionary(message)),
});

class ThanksPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bandera : true
        }
    }

    componentWillMount() {
        this.props.resetValuesQuestionary(MSG_UNFINISHED_OR_ALREADY_FINISHED);
    }

    obtenerArchivo = (llaveCompuesta, shaCuestionario) =>{
        //Hay 2 varibales ya que 1 caso es terminar correctamente la contestacion y otra es que cierre sin contestar
        var infoPost = {
            idCuestionarioPeriodo: llaveCompuesta, 
            idHashCuestionario: shaCuestionario
          }

      axios.post(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlShowImagePublish, infoPost, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
               // 'Authorization': this.props.credentials.token.accessToken
                }
            }).then(response => {
                this.setState({bandera: false})
            //Realizamos la conversion de la imagen en base 64 a un <img>               
            const data = response.data;
            const PicturesViewer = ({ data }) => <img style={{marginLeft: 'auto', marginRight: 'auto', width: '50%', display: 'block'}} src={`data:image/jpeg;base64,${data}`} />
            ReactDOM.render(<PicturesViewer data={data} />, document.getElementById('containerImage'))
            }).catch(error => {
                console.error("al recuperar imagen",error);
                
            });
      }  

    render() {
        if (this.state.bandera){
            this.obtenerArchivo(this.props.credentials.idImagen != null ? this.props.credentials.idImagen : this.props.questionary.idImagen != null ? this.props.questionary.idImagen : null, this.props.credentials.idCuestionario);
            return <LoadingComp />;
        }else{
            return (<div id="containerImage"></div>)  
        }  
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ThanksPage));