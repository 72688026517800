import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Button, Input, Row, Col } from 'antd';
import { addQuestionaryToScreen } from '../../actions/previewQuestionaryActionCreator';
import QuestionsCreatorComp from '../NewQuestionary/QuestionsCreatorComp';
import * as styleVars from '../../configs/styleVars';
import { cloneObject } from '../../helpers/helpers';
import * as uiText from '../../configs/uiTexts';
import { truncate } from '../../helpers/helpers';
import { TABS_ELIPSIS, GUTTER_BASE, MAX_LENGTH_TITLE, MAX_LENGTH_SECTION } from '../../configs/layoutVars';

const { TextArea } = Input;
const TabPane = Tabs.TabPane;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    addQuestionaryToScreen: (questionary) => dispatch(addQuestionaryToScreen(questionary)),
    
});

let sectionTemplate = {
    disabled: false,
    idSection: 0,
    questions: [],
    title: "",
    visible: true
}


class TabsBuilder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisibleInput: false,
            inputValue: "",
            isEditingTitle: false,
            isEditingRelatedInfo: false,
            relatedInfo: "",
            title: ""
        }
    }

    saveChanges = (index) => {
        let newQuestionary = cloneObject(this.props.questionary.questionary);
        newQuestionary.sections[index].title = this.state.title;
        newQuestionary.header.relatedInfo = this.state.relatedInfo;
        if (this.state.title !== "")
            this.props.addQuestionaryToScreen(newQuestionary);
        else
            this.setState({ title: this.props.questionary.questionary.header.questionaryName })
    }

    saveInstructions = (index) => {
        let newQuestionary = cloneObject(this.props.questionary.questionary);
        newQuestionary.sections[index].relatedInfo = this.state.relatedInfo;
        if (this.state.relatedInfo !== "")
            this.props.addQuestionaryToScreen(newQuestionary);
        else
            this.setState({ relatedInfo: this.props.questionary.questionary.sections[index].relatedInfo })
    }

    titleEditor = (title, index) => {
        return (
            this.state.isEditingTitle ?
                <Input
                    maxLength={MAX_LENGTH_TITLE}
                    value={this.state.title}
                    style={{ width: "50%" }}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    placeholder={uiText.NEW_QUESTIONARY_TITLE_LABEL}
                    onPressEnter={(e) => this.setState({ isEditingTitle: false }, () => this.saveChanges(index))}
                    onBlur={(e) => this.setState({ isEditingTitle: false }, () => this.saveChanges(index))}
                >
                </Input>
                :
                <span style={{ color: styleVars.MAIN_COLOR, width: "50%", fontSize: "20px", fontWeight: "bold" }}>{title}</span>
        )
    }


    ralatedInfoEditor = (relatedInfo, index) => {
        return (
            this.state.isEditingRelatedInfo ?
                <TextArea

                    value={this.state.relatedInfo}
                    style={{ width: "80%" }}
                    onChange={(e) => this.setState({ relatedInfo: e.target.value })}
                    placeholder={uiText.SECTION_INSTRUCTIONS_PLACEHOLDER}
                    onPressEnter={(e) => this.setState({ isEditingRelatedInfo: false }, () => this.saveInstructions(index))}
                    onBlur={(e) => this.setState({ isEditingRelatedInfo: false }, () => this.saveInstructions(index))}
                >
                </TextArea>
                :
                <span style={{ color: "#676767", fontSize: "16px" }}>
                    <b>Instrucciones</b>:&nbsp;{(relatedInfo !== "" ? relatedInfo : uiText.SECTION_INSTRUCTIONS)}
                </span>
        )
    }


    builder = (sections, editionEstatus) => {

        return (sections.map((section, index) => {

            return (
                <TabPane
                    style={styleVars.tabs}
                    tab={truncate(TABS_ELIPSIS, section.title)}
                    disabled={section.disabled}
                    key={index}
                    closable={true}
                >
                    <Row gutter={GUTTER_BASE}>
                        <Col span={24}>
                            <Col span={24} style={styleVars.verticalMargin30}>
                                {this.titleEditor(section.title, index)}


                                <Button
                                    style={{ border: "none" }}
                                    disabled={this.state.isEditingTitle}
                                    shape="circle"
                                    icon="edit"
                                    onClick={(e) => this.setState({ isEditingTitle: true, title: section.title })}
                                />
                            </Col>
                        </Col>


                        <Col span={24}>
                            <Col span={24} style={styleVars.verticalMargin15}>

                                {this.ralatedInfoEditor(section.relatedInfo, index)}


                                <Button
                                    style={{ border: "none" }}
                                    disabled={this.state.isEditingRelatedInfo}
                                    shape="circle"
                                    icon="edit"
                                    onClick={(e) => this.setState({ isEditingRelatedInfo: true, relatedInfo: section.relatedInfo })}
                                />
                            </Col>
                        </Col>



                    </Row>
                    <QuestionsCreatorComp editionEstatus={editionEstatus} section={section.idSection} questions={section.questions} />
                </TabPane>
            )
        })
        );
    }



    onEdit = (targetKey, action) => {
        this[action](targetKey);
    }

    add = () => {
        if (this.state.inputValue !== "") {
            //console.log("add");
            let newQuestionary = cloneObject(this.props.questionary.questionary);
            let newSection = cloneObject(sectionTemplate);
            newSection.idSection = newQuestionary.sections.length;
            newSection.title = this.state.inputValue;
            newQuestionary.sections.push(newSection);
            //console.log("add", newQuestionary);
            this.props.addQuestionaryToScreen(newQuestionary);
        }
        this.setState({ isVisibleInput: false, inputValue: "" })
    }

    remove = (targetKey) => {
        let newQuestionary = cloneObject(this.props.questionary.questionary);

        if (newQuestionary.sections.length > 1) {
            newQuestionary.sections.splice(targetKey, 1);

            newQuestionary.sections.forEach((section, index) => {
                section.idSection = index;
            });

            this.props.addQuestionaryToScreen(newQuestionary);
        }
    }

    render() {
        return (
            <React.Fragment>


                <p style={{ textAlign: "left" }}>
                    {this.state.isVisibleInput ?
                        <Input
                            value={this.state.inputValue}
                            style={{ width: "250px" }}
                            maxLength={MAX_LENGTH_SECTION}
                            onChange={(e) => this.setState({ inputValue: e.target.value })}
                            onPressEnter={() => this.add()}
                            onBlur={() => this.add()}
                            placeholder={uiText.REQUIRED_LABEL}
                        />
                        :
                        <Button icon="folder-add"
                            onClick={(e) => this.setState({ isVisibleInput: true })}
                        > {uiText.ADD_NEW_SECTION}</Button>
                    }
                </p>


                <Tabs
                    hideAdd
                    type="editable-card"
                    onEdit={this.onEdit}
                >
                    {this.builder(this.props.questionary.questionary.sections, this.props.editionEstatus)}
                </Tabs>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsBuilder);