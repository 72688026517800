import * as ActionTypes from '../actions/actions';

export const PublicHome = (state = {
    isLoading: null,
    payload: null,
    message: null,
    typeMessage: null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_PUBLIC_HOME:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }

        case ActionTypes.FAILED_PUBLIC_HOME:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }

        case ActionTypes.SUCCESS_PUBLIC_HOME:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }

        case ActionTypes.CLEAN_PUBLIC_HOME:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage }

        default:
            return state;
    }
}

