import { NOTIF_TYPE, ERROR_TYPE } from "../configs/galeryTypes";
import { FORBBIDEN_PAGE, GENERIC_ERROR, GET_INFO_ERROR, GROUP_ERROR, OFFLINE_ERROR, PERIOD_ERROR, PERIOD_MESSAGE, SAVE_ERROR, TOKEN_ERROR } from "../configs/uiTexts";

export const errorSelector = (typeMessage) => {
    let errorObj = { type: null, duration: null, title: null, message: null }

    errorObj.type = typeMessage;
    errorObj.duration = 100;

    switch (typeMessage) {
        case ERROR_TYPE.FORBBIDEN:
            errorObj.type = NOTIF_TYPE.error;
            errorObj.title = "Acceso no permitido";
            errorObj.message = FORBBIDEN_PAGE;
            break;

        case ERROR_TYPE.GENERIC:
            errorObj.type = NOTIF_TYPE.warning;
            errorObj.title = "Sistema no disponible";
            errorObj.message = GENERIC_ERROR;
            break;

        case ERROR_TYPE.GET_INFO:
            errorObj.type = NOTIF_TYPE.error;
            errorObj.title = "Conexión no disponible";
            errorObj.message = GET_INFO_ERROR;
            break;

        case ERROR_TYPE.GROUP:
            errorObj.type = NOTIF_TYPE.error;
            errorObj.title = "Acceso no permitido";
            errorObj.message = GROUP_ERROR;
            break;

        case ERROR_TYPE.OFFLINE:
            errorObj.type = NOTIF_TYPE.warning;
            errorObj.title = "Sin conexión";
            errorObj.message = OFFLINE_ERROR;
            break;

        case ERROR_TYPE.SAVE:
            errorObj.type = NOTIF_TYPE.warning;
            errorObj.title = "Conexión no disponible";
            errorObj.message = SAVE_ERROR;
            break;

        case ERROR_TYPE.TOKEN:
            errorObj.type = NOTIF_TYPE.error;
            errorObj.title = "Acceso no permitido";
            errorObj.message = TOKEN_ERROR;
            break;
        
        case ERROR_TYPE.PERIOD:
            errorObj.type = NOTIF_TYPE.warning;
            errorObj.title = "Cuestionario no disponible";
            errorObj.message = PERIOD_MESSAGE;
            break;

        default:
            errorObj.type = NOTIF_TYPE.warning;
            errorObj.title = "Sistema no disponible";
            errorObj.message = GENERIC_ERROR;
            break;

    }

    return errorObj;
}




export const errorStatusType = (status) => {
    //console.log(status);

    switch (status) {

        case 400:
            return new Error(ERROR_TYPE.GET_INFO);

        case 410:
            return new Error(ERROR_TYPE.SAVE);

        case 401:
            return new Error(ERROR_TYPE.TOKEN);

        case 403:
            return new Error(ERROR_TYPE.FORBBIDEN)
        case 404:
            return new Error(ERROR_TYPE.OFFLINE);

        case 440:
            return new Error(ERROR_TYPE.PERIOD);

        case 441:
            return new Error(ERROR_TYPE.GROUP);

        case 500:
            return new Error(ERROR_TYPE.GENERIC);

        default:
            return new Error(ERROR_TYPE.GENERIC);
    }
}
