import * as ActionTypes from '../actions/actions';

export const LoginRed = (state = {
    isFetching: false,
    message: null,
    token: null,
    isAuthenticated: false,
    showLogin: false, 
    idCuestionario: null, 
    idImagen: null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOGIN_FETCHING:
            //console.log(action.payload);
            return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin }

        case ActionTypes.LOGIN_FAILURE:
            //console.log(action.payload);
            return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin }

        case ActionTypes.LOGIN_SUCCESS:
            //console.log(action.payload);
            return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin }

        case ActionTypes.LOGIN_SHOW_MODAL:
            return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin, idCuestionario: action.idCuestionario, idImagen: action.idImagen }

        case ActionTypes.LOGED_OUT:
            return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin }
        case ActionTypes.RESET_LOGIN:
            //console.log(action.payload);
            return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin }
            case ActionTypes.NO_ACCESS:
                //console.log(action.payload);
                return { ...state, isFetching: action.isFetching, message: action.message, isAuthenticated: action.isAuthenticated, token: action.token, showLogin: action.showLogin }

        default:
            return state;
    }
}