import * as ActionTypes from './actions';
import { UrlGetListPeriod, UrlUpdatePeriod } from '../configs/baseUrl';
import { ERROR_TYPE, NOTIF_TYPE } from '../configs/galeryTypes';
import { errorStatusType } from './errorSelector';
import { resetLogin } from './loginActionsCreator';
import { sendMessage } from './messagesActionCreator'
import { SEND_QUEST_SUCCESS_MSG } from '../configs/uiTexts';


/** functions to new fetch Periods to update */

export const fetchDataPeriod = (token, idQuestionario) => (dispatch) => {
    // console.log(token);


    dispatch(fetchingDataPeriod());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") +  UrlGetListPeriod,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({ "idCuestionario": idQuestionario }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(data => dispatch(successFetchDataPeriod(data)))
        .catch(error => dispatch(failureFetchDataPeriod(error.message)));

}

const fetchingDataPeriod = () => ({
    type: ActionTypes.LOADING_PERIOD,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true
});

const failureFetchDataPeriod = (message) => ({
    type: ActionTypes.FAILED_FETCH_PERIOD,
    isLoading: false,
    payload: null,
    message: message,
    openModal: false,
    typeMessage: ERROR_TYPE.GET_INFO
});

const successFetchDataPeriod = (data) => ({
    type: ActionTypes.SUCCESS_FETCH_PERIOD,
    isLoading: false,
    payload: data,
    message: null,
    openModal: true,

});

export const clearDataPeriod = () => ({
    type: ActionTypes.CLEAR_FETCH_PERIOD,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,

});


/** functions to save Periods to updated */

export const saveEditedPeriod = (token, payload) => (dispatch) => {
    // console.log(token);
    dispatch(savingEditedPeriod());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlUpdatePeriod,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify(payload),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(() => {
            dispatch(successSaveEdited());
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", SEND_QUEST_SUCCESS_MSG));
        })
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureSaveEdited(error.message))
        }
        );

}



const savingEditedPeriod = () => ({
    type: ActionTypes.SAVING_EDITED_PERIOD,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true

});

const failureSaveEdited = (message, payload) => ({
    type: ActionTypes.FAILED_EDITED_PERIOD,
    isLoading: false,
    payload: payload,
    message: message,
    openModal: true,
    typeMessage: ERROR_TYPE.SAVE
});

const successSaveEdited = () => ({
    type: ActionTypes.SUCCESS_EDITED_PERIOD,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false
});





export const closeEditingPeriodWindow = () => ({
    type: ActionTypes.CLOSE_PERIOD_WINDOW,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false
})
