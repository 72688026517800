import {Layout, LocaleProvider, Breadcrumb, BackTop } from 'antd';
import esES from 'antd/lib/locale-provider/es_ES';
import 'moment/locale/es-us';
import React, { Component } from 'react';
import {/* Link, NavLink,*/  Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ViewQuestionaryComp from './viewQuestionary/ViewQuestionaryComp';
import CreateQuestionary from './NewQuestionary/CreateQuestionary';
import PublicHome from './Home/PublicHome';
import ListQuest from './Home/ListQuest';
import { linksRouter } from '../configs/baseUrl';
import HeaderComp from './genericComponents/HeaderComp';
import LoginModalComp from './genericComponents/LogInModalComp';
import GlobalMenu from './genericComponents/GlobalMenu';
import InboxAnswers from './inboxAnswers/InboxAnswers';
import ResponseUsers from './responseUsers/ResponseUsers';
import UserAnsOK from './auxiliarForms/UserAnsOK';
import ErrorControler from './genericComponents/ErrorControler';
import ErrorPage from './auxiliarForms/ErrorPage'; 
import ThanksPage from './auxiliarForms/ThanksPage'; 
import SinAcceso from './auxiliarForms/SinAcceso';
import Forbbiden from './auxiliarForms/Forbbiden';
import PeriodPage from './auxiliarForms/PeriodPage';
import * as uiTexts from '../configs/uiTexts';
import MessageControler from './genericComponents/MessageControler';
import ForbiddenGroup from './auxiliarForms/ForbiddenGroup';

window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
});

//import { CSSTransition, TransitionGroup } from 'react-transition-group';
//import SuccesSendQuestionary from './viewQuestionary/SuccesSendQuestionary'; //'./SuccesSendQuestionary';SuccesSendQuestionary

//const { SubMenu } = Menu;
const { Content, Footer } = Layout;


/** Redux get State ****************/
const mapStateToProps = (state) => {
 // console.log("STATE", state)
  return {
    credentials: state.credentials,
    publicHome: state.publicHome,
    califSend:state.questionary.califSend,
    rut:state.questionary.ruta
    //state:state
  }
}
class Main extends Component {

  render() {

    return ( 
   //   console.log("props RUTA CUESTIONARIO1", this.props.history.location.pathname),
   // console.log("state CUESTIONARIO1", this.props.rut),
   //console.log("state CUESTIONARIO1", this.props.credentials.showLogin),
  

   // this.props.rut===true?this.props.history.replace("/"+ linksRouter.thanksPage):"",
    // linksRouter.thanksPage? {state: {califSend:true}}:'',
   /* (this.props.history.location.pathname==='/undefined'|| this.props.history.location.pathname==='/inicio') && this.props.califSend===true?
    this.props.history.replace("/"+ linksRouter.thanksPage):"",
    console.log("ESTADO ACT EN Main", this.props.history.location.pathname),
    */
     //console.log("linksRouter.thanksPage", linksRouter),
    
     //console.log("props CUESTIONARIO1", this.props.history),
      <LocaleProvider locale={esES}>

        <Layout>
          <HeaderComp />
          <LoginModalComp />
          <GlobalMenu />

          <Breadcrumb>
            <Breadcrumb.Item> </Breadcrumb.Item>
          </Breadcrumb>



          <Content className="main-container">
            <BackTop></BackTop>
            <Layout>
              <Content className="info-container" >

               <Switch>
                  
               <Route exact path={"/" + linksRouter.thanksPage} component={() => <ThanksPage />} />
               <Route exact path={"/" + linksRouter.previewQuestionary + "/:id"} component={({ match }) => <ViewQuestionaryComp match={match} />} />
               <Route exact path={"/" + linksRouter.questionaryList} component={() => <ListQuest />} />
               <Route exact path={"/" + linksRouter.newQuestionary} component={({ match }) => <CreateQuestionary match={match} />} />
               <Route exact path={"/" + linksRouter.publicHome} component={() => <PublicHome />} />
               <Route exact path={"/" + linksRouter.editQuestionary + "/:id"} component={({ match }) => <CreateQuestionary match={match} />} />
               <Route exact path={"/" + linksRouter.inboxAnswers + "/:id"} component={() => <InboxAnswers />} />
               <Route exact path={"/" + linksRouter.responseUsers + "/:id"} component={() => <ResponseUsers />} />
               <Route exact path={"/" + linksRouter.successAnsUser} component={() => <UserAnsOK />} />
               <Route exact path={"/" + linksRouter.forbbidenPage} component={() => <Forbbiden />} />
               <Route exact path={"/" + linksRouter.errorPage} component={() => <ErrorPage />} />
               <Route exact path={"/" + linksRouter.sinAcceso} component={() => <SinAcceso />} />
               <Route exact path={"/" + linksRouter.periodPage} component={() => <PeriodPage />} />
               <Route exact path={"/" + linksRouter.forbiddenGroup} component={() => <ForbiddenGroup />} />
              
               <Redirect to={"/" + linksRouter.publicHome} />

             </Switch>
                {//this.props.califSend===true?<>{console.log("Entro al redirect1")} <Redirect to={"/" + linksRouter.error} /></>:""
                  }
                  {//this.props.califSend && this.props.credentials.showLogin===null?<>{console.log("Entro al redirect1", this.props.history.location.pathname), this.props.history.location.pathname="/graciasParticipar"}  {console.log("Entro al redirect2", this.props.history.location.pathname)} </>:""
                  }
                <ErrorControler></ErrorControler>
                <MessageControler></MessageControler>

              </Content>
            </Layout>

          </Content>

          <Footer>
          <div>Derechos reservados INE <span style={{ float: "right" }}> <font color="black">F-{uiTexts.VERSIONFRONT} B-{this.props.publicHome.payload !== null && this.props.publicHome.payload.version}</font></span></div>
          </Footer>

        </Layout>

      </LocaleProvider>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Main));