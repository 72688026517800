import * as ActionTypes from './actions';
import { UrlListQuest, UrlPublicHome, UrlCloneQuest, UrlDeleteQuest } from '../configs/baseUrl';
//import { ERROR_TYPE } from '../configs/galeryTypes';
import { errorStatusType } from './errorSelector';
import { resetLogin } from './loginActionsCreator';
import { ERROR_TYPE, NOTIF_TYPE } from '../configs/galeryTypes';
import { CLONE_SUCCESS_MSG, DELETE_SUCCESS_MSG, SEND_QUEST_SUCCESS_MSG } from '../configs/uiTexts';
import { sendMessage } from './messagesActionCreator';


/** functions to get public home data */
export const fetchPublicHome = () => (dispatch) => {
    dispatch(fetchingPublicHome());
    return fetch(UrlPublicHome + "/home.json",
        {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": token.acces_token
            },
            redirect: "manual"
        }
    )
        .then(response => {
            if (response.ok) {
                return response;
            } else {
                let errorObj = errorStatusType(response.status)
                errorObj.response = response;
                console.log(errorObj);
                throw errorObj;
            }
        })
        .then(response => response.json())
        .then(questionary => {
            return fetch(window.location.origin + "/JsonHelper/url.json", {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": token.acces_token
                },
                redirect: "manual"
            }).then(response => {
                if (response.ok) {
                    return response;
                } else {
                    let errorObj = errorStatusType(response.status)
                    errorObj.response = response;
                    console.log(errorObj);
                    throw errorObj;
                }
            })
            .then(response => response.json())
            .then(jsonRutas => {
                //let validarCaptcha = jsonRutas.validarCaptcha;
                let questionaryTmp = {...questionary, "validarCaptcha": jsonRutas.validarCaptcha !== null &&  jsonRutas.validarCaptcha !== undefined 
                    ? jsonRutas.validarCaptcha: true};
                console.log("questionaryTmp:=>", questionaryTmp);
                dispatch(successPublicHome(questionaryTmp))
            })
            .catch((error) => dispatch(failurePublicHome(errorStatusType(404).message))
            );
        })
        .catch((error) => dispatch(failurePublicHome(errorStatusType(404).message))
        );
}

export const fetchRespuestaLogin = (isAuthenticated, token, typeLog, idCuestionario, idImagen) => (dispatch) => {
    //dispatch(fetchingPublicHome());
    
    return fetch(window.location.origin + "/JsonHelper/url.json", {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": token.acces_token
        },
        redirect: "manual"
    }).then(response => {
        if (response.ok) {
            return response;
        } else {
            let errorObj = errorStatusType(response.status)
            errorObj.response = response;
            console.log(errorObj);
            throw errorObj;
        }
    })
    .then(response => response.json())
    .then(jsonRutas => {
        //let validarCaptcha = jsonRutas.validarCaptcha;
        let questionaryTmp = {"validarCaptcha": jsonRutas.validarCaptcha !== null &&  jsonRutas.validarCaptcha !== undefined 
            ? jsonRutas.validarCaptcha: true};
        console.log("questionaryTmp:=>", questionaryTmp);
        dispatch(successPublicHome(questionaryTmp))
    })
    .catch((error) => dispatch(failurePublicHome(errorStatusType(404).message))
    );
}


const fetchingPublicHome = () => ({
    type: ActionTypes.LOADING_PUBLIC_HOME,
    isLoading: true,
    payload: null,
    message: null,
    typeMessage: null,
    succesSend: false

});

const failurePublicHome = (error) => ({
    type: ActionTypes.FAILED_PUBLIC_HOME,
    isLoading: false,
    payload: null,
    message: null,
    typeMessage: error,
    succesSend: false
});


const successPublicHome = (dataHome) => ({
    type: ActionTypes.SUCCESS_PUBLIC_HOME,
    isLoading: false,
    payload: dataHome,
    message: SEND_QUEST_SUCCESS_MSG,
    typeMessage: null,
    succesSend: false
});

export const cleanPublicHome = () => ({
    type: ActionTypes.CLEAN_PUBLIC_HOME,
    isLoading: false,
    payload: null,
    message: null,
    typeMessage: null,
    succesSend: false
})

/** functions to get questionary list data */

export const fetchListQuestionary = (token) => (dispatch) => {
    // console.log(token);
    dispatch(fetchingListQuestionary());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS")+UrlListQuest,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
                //"Authorization": " token.accessToken"
            },
            redirect: "manual"
        }
    )
        .then(response => {
            if (response.ok) {
                return response;
            } else {
                //console.log(response);
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = errorStatusType(404);
            throw errmess;
        })
        .then(response => response.json())
        .then(questionary => dispatch(successListQuestionary(questionary)))
        .catch(error => {
         
             if (error.message === ERROR_TYPE.TOKEN) {
                 dispatch(resetLogin())
             }
            dispatch(failureListQuestionary(error.message))
        }
        );
}



const fetchingListQuestionary = () => ({
    type: ActionTypes.LOADING_LIST_QUEST,
    isLoading: true,
    payload: null,
    message: null,
    typeMessage: null,
    succesSend: false
});

const failureListQuestionary = (error) => ({
    type: ActionTypes.FAILED_LIST_QUEST,
    isLoading: false,
    payload: null,
    message: null,
    typeMessage: error,
    succesSend: false
});

export const successListQuestionary = (dataHome) => ({
    type: ActionTypes.SUCCESS_LIST_QUEST,
    isLoading: false,
    payload: dataHome,
    message: null,
    typeMessage: null,
    succesSend: false
});

export const cleanListQuestionary = (dataHome) => ({
    type: ActionTypes.CLEAN_DATA_LIST_QUEST,
    isLoading: false,
    payload: dataHome,
    message: null,
    typeMessage: null,
    succesSend: false
});


/** functions to  clone questionary*/

export const fetchCloneQuest = (token, idQuestionary) => (dispatch) => {
    dispatch(cloningQuestionary());

    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlCloneQuest,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({ "idCuestionario": idQuestionary }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = errorStatusType(404);
            throw errmess;
        })
        .then(response => response.json())
        .then(questionary => {
            dispatch(successCloneQuestionary(questionary));
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", CLONE_SUCCESS_MSG));
        })
        .catch(error => {
            //console.log(error.message);
              if (error.message === ERROR_TYPE.TOKEN) {
                  dispatch(resetLogin())
              }
            dispatch(failureCloneQuestionary(error.message))
        }
        );
}




const cloningQuestionary = () => ({
    type: ActionTypes.CLONING_QUEST,
    isLoading: true,
    payload: null,
    message: null,
    typeMessage: null,
    succesSend: false
});

const failureCloneQuestionary = (error) => ({
    type: ActionTypes.FAILED_LIST_QUEST,
    isLoading: false,
    payload: null,
    message: null,
    typeMessage: error,
    succesSend: false
});

const successCloneQuestionary = (dataHome) => ({
    type: ActionTypes.SUCCESS_LIST_QUEST,
    isLoading: false,
    payload: dataHome,
    message: CLONE_SUCCESS_MSG,
    typeMessage: null,
    succesSend: true
});





/** functions to  delete questionary*/

export const fetchDeleteQuest = (token, idQuestionary) => (dispatch) => {
    dispatch(deletingQuestionary());

    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlDeleteQuest,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({ "idCuestionario": idQuestionary }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = errorStatusType(404);
            throw errmess;
        })
        .then(response => response.json())
        .then(questionary => {
            
            dispatch(successDeleteQuestionary(questionary))
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", DELETE_SUCCESS_MSG));

        })
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureDeleteQuestionary(error.message))
        }
        );

}




const deletingQuestionary = () => ({
    type: ActionTypes.DELETING_QUEST,
    isLoading: true,
    payload: null,
    message: null,
    typeMessage: null,
    succesSend: false

});

const failureDeleteQuestionary = (error) => ({
    type: ActionTypes.FAILED_DELETE_QUEST,
    payload: null,
    message: null,
    typeMessage: error,
    succesSend: false
});

const successDeleteQuestionary = (dataHome) => ({
    type: ActionTypes.SUCCESS_DELETE_QUEST,
    isLoading: false,
    payload: dataHome,
    message: DELETE_SUCCESS_MSG,
    typeMessage: null,
    succesSend: true
});