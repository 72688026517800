import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//import LoginForm from './LoginForm';
import { Redirect, withRouter } from 'react-router-dom';
import { linksRouter } from '../../configs/baseUrl';
import { Layout, Button } from 'antd';
import mainId from '../../imgs/id.png';
import { loginButtonHeader } from '../../configs/styleVars';
import * as uiTexts from '../../configs/uiTexts';
import { showLoginModal } from '../../actions/loginActionsCreator';
import * as ActionTypes from './../../actions/actions';



const { Header } = Layout;



/** Redux get State ****************/
const mapStateToProps = (state) => {
    //console.log("CONTADOR state", state.contador.cont);
    return {
        credentials: state.credentials,
        questionary: state.questionary,
        califSend: state.questionary.califSend,
      
    }
    
}



/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
});




class HeaderComp extends Component {

    constructor(props) {
        super(props);
        this.state = { act: 1 }

    }

    /**
     * Función que controla el mostrar el modal de Iniciar sesión/Cerrar sesión
     */
    processLoginBtn = () => {
        if (this.props.credentials.isAuthenticated) {
            const mode = (this.props.history.location.pathname.indexOf("responderCuestionario") > -1)? ActionTypes.LOGED_OUT : ActionTypes.LOGOUT;
            this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, mode, this.props.credentials.idCuestionario, this.props.questionary.questionary != null ? this.props.questionary.questionary.header.id : null);
        } else {
            this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, ActionTypes.LOGIN, null, null);
        }
    }

    processLogOut = () => {
        this.props.history.replace("/" + linksRouter.publicHome);
    }

    processCerrar =() => {
        this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, ActionTypes.LOGOUT, null, null);
    }


    render() {
       // console.log("PROPS HEADER", this.props)    
        //console.log("LOCALSTORAGE", localStorage);
  /* if(i===1)
      {  console.log("cont 1", this.state.cont);
        this.setState({ cont: 2 });
        i=3;
        console.log("cont", this.state.cont);}
        */return (
           // console.log("props CUESTIONARIOHEDER1", this.props.history.location.pathname),
            //console.log("califsend EN HEADER", this.props.califSend),
        /*    this.props.califSend=== true && this.props.credentials.showLogin===null?<>
            {console.log("Entro al redirect1", this.props.history.location.pathname),
            this.props.history.replace("/"+ linksRouter.thanksPage)},
             </>:"",
            console.log("Entro al redirect2", this.props.history.location.pathname),
          */  
            //this.ruta(this.props.history.location.pathname),
          //  console.log("history",this.props.history.location.pathname),
           // console.log("questionary", this.props.questionary.questionary),
            <Header className="header">
                <img src={mainId} alt="Logo cuestionarios y evaluaciones" />
                { this.renderLoginLogoutButton() }
            </Header>

        )
    }

    /**
     * Lógica para mostrar u ocultar el botón de Iniciar o Cerrar sesión bajo ciertas condiciones
     * @returns Botón para mostrat Inicio o Cierre de sesión o nulo si no se cumplen condiciones para mostrarlo
     */
    renderLoginLogoutButton() {
        //Página de agradecimiento no muestra botones de login ni logout
        //Página de error no muestra botón para Iniciar sesión
        //if (this.props.history.location.pathname !== ("/" + linksRouter.thanksPage) && this.props.history.location.pathname !== ("/" + linksRouter.sinAcceso) &&!(this.props.history.location.pathname == ("/" + linksRouter.errorPage) && !this.props.credentials.isAuthenticated) ) {
       if (this.props.history.location.pathname == ("/" + linksRouter.thanksPage))  {
        return (
                <Button
                    onClick = {() => this.processLogOut()}
                    icon    = {"close-circle"}
                    style   = {loginButtonHeader} type="primary" block >

                    {uiTexts.LOGOUT_BUTTON}
                </Button>
            );
        }

        if (this.props.history.location.pathname == ("/" + linksRouter.periodPage) && this.props.credentials.isAuthenticated)  {
            return (
                    <Button
                        onClick = {() => this.processCerrar()}
                        icon    = {"close-circle"}
                        style   = {loginButtonHeader} type="primary" block >
    
                        {uiTexts.LOGOUT_BUTTON}
                    </Button>
                );
        }

        if (this.props.history.location.pathname == ("/" + linksRouter.forbbidenPage) && this.props.credentials.isAuthenticated)  {
            return (
                    <Button
                        onClick = {() => this.processCerrar()}
                        icon    = {"close-circle"}
                        style   = {loginButtonHeader} type="primary" block >
    
                        {uiTexts.LOGOUT_BUTTON}
                    </Button>
                );
        }

        if (this.props.history.location.pathname !== ("/" + linksRouter.thanksPage) && this.props.history.location.pathname !== ("/" + linksRouter.sinAcceso) &&!(this.props.history.location.pathname == ("/" + linksRouter.errorPage) && !this.props.credentials.isAuthenticated) ) {
        return (
                <Button
                    onClick = {() => this.processLoginBtn()}
                    icon    = {this.props.credentials.isAuthenticated ? "close-circle" : "user"}
                    style   = {loginButtonHeader} type="primary" block >

                    {(this.props.credentials.isAuthenticated)? uiTexts.LOGOUT_BUTTON : uiTexts.LOGIN_BUTTON}
                </Button>
            );
        }
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComp))
