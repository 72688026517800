import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Questionary } from '../reducers/previewQuestionaryRed';
import { LoginRed } from '../reducers/loginRed';
import { EditQuestionRed } from '../reducers/editQuestionRed';
import { TempQuestion } from '../reducers/updateTempQuestionRed';
import { PublicHome } from '../reducers/homeRed';
import { listQuestionary } from '../reducers/listQuestionaryRed';
import { publishRed } from '../reducers/publishRed';
import { editPeriodRed } from '../reducers/editPeriodRed';
import { inboxAnsRed } from '../reducers/inboxAnsRed';
import { previewAnsRed } from '../reducers/previewAnsRed';
import { messagesRed } from '../reducers/messagesRed';
import thunk from 'redux-thunk';
import { downloadRed } from '../reducers/downloadRed';


export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
     
            questionary: Questionary,
            credentials: LoginRed,
            editionEstatus: EditQuestionRed,
            tempQuestion: TempQuestion,
            publicHome: PublicHome,
            listQuestionary: listQuestionary,
            publishData: publishRed,
            periodData: editPeriodRed,
            inboxAns: inboxAnsRed,
            previewAns: previewAnsRed,
            downloadData:downloadRed,
            messages:messagesRed 
        }), applyMiddleware(thunk));
    return store;
};