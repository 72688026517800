import React, { Component } from 'react'
import { Form, Select, Col, Divider, Tag, Icon, Input, Row, Switch, InputNumber } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateTemporalQuestion } from '../../../actions/updateTemporalQuestionActionCreator';
import * as galeryTypes from '../../../configs/galeryTypes'
import * as styleVars from '../../../configs/styleVars';
import { cloneObject } from '../../../helpers/helpers';



const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        tempQuestion: state.temporalQuest
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateTemporalQuestion: (tempQuestion) => dispatch(updateTemporalQuestion(tempQuestion))
});

class SelectFactoryComp extends Component {
    constructor(props) {
        super(props);

        this.state = {

            disabled: this.props.disabled,
            idQuestion: this.props.idQuestion,
            label: this.props.label,
            ponderacion: this.props.ponderacion ? this.props.ponderacion : "",
            relatedInfo: this.props.relatedInfo,
            required: this.props.required,
            title: this.props.title,
            type: galeryTypes.SELECT,
            typeQuestionary: this.props.typeQuestionary,
            validateStatus: this.props.validateStatus,
            value: this.props.value,
            valueToShowChild: this.props.valueToShowChild,
            visible: this.props.visible,
            titleValidate: "",
            labelValidate: "",
            disableOkButton: true,
            addItemDisabled: false,
            inputValue: "",
            childQuestion: null,
            inputVisible: false

        }
    }

    onChange = () => {
        //Condiciones de validacion para el boton de aceptar en el proceso de creación y modificacion
        if (this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 ||
            this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) {
                if (this.state.label !== "" && this.state.label !== undefined && this.state.titleValidate === layoutVars.VALIDATE_SUCCESS){
                    this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) }) 
                }else{
                    this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
                }
            }else {
                this.setState({required: true}); //Solo aplica para el caso de EVALUACIONES
                if (this.state.label !== "" && this.state.label !== undefined && this.state.title !== "" && this.state.title !== undefined &&
                    this.state.ponderacion !== "" && this.state.ponderacion !== undefined && this.state.value !== "" && this.state.ponderacion !== null && this.state.value !== undefined ){
                    this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) })
                }else{
                    this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
                }
            }    
    }

    tagCreator = (question) => {
        let newQuest = cloneObject(question)
       
        //Limpiado de la cadena para que no comience con |
        if(newQuest.label.startsWith('|')){
            newQuest.label = newQuest.label.slice(1);
        }

        if(newQuest.label !== null || newQuest.label !== undefined){
        return (
            <React.Fragment>
                {
                    (newQuest.label !== "") ?
                        // eslint-disable-next-line
                        newQuest.label.split("|").map((item, index, arrayLabels) => {
                          if (item !== "")
                                return (
                                    <Tag closable onClose={(e) => this.deleteTag(e, item, arrayLabels)} key={index}>{item}</Tag>
                                )
                        })
                        : null

                }
                {this.state.inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 150 }}
                        value={this.state.inputValue}
                        onChange={(e) => this.setState({ inputValue: e.target.value })}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!this.state.inputVisible && (
                    <Tag onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}
                    >
                        <Icon type="plus" /> {uiTexts.ADD_LABEL}
                    </Tag>
                )}

            </React.Fragment>
        )
        }
    }

    showInput = () => {
        this.setState({ inputVisible: true });
    }

    handleInputConfirm = () => {
        let newQuest = cloneObject(this.state)

        //Limpiado de la cadena para que no comience con |
        if(newQuest.label.startsWith('|')){
            newQuest.label = newQuest.label.slice(1);
        }

        const state = this.state;
        const inputValue = state.inputValue;

        let tags = newQuest.label.split("|");
        
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
            this.setState({ label: tags.join("|") },
                () => this.onChange())
        }
        
        newQuest = null;
        this.setState({
            inputVisible: false,
            inputValue: '',
        });
    }


    deleteTag = (e, removedTag, arrayLabels) => {
        e.preventDefault();

        const tags = arrayLabels.filter(tag => tag !== removedTag);
        //this.onChange())
        this.setState({ label: tags.join("|") },
            () => this.onChange())

    }

    selectCreator = (question) => {
        let newQuest = cloneObject(question)

        //Limpiado de la cadena para que no comience con |
        if(newQuest.label.startsWith('|')){
            newQuest.label = newQuest.label.slice(1);
        }

        return (
            (newQuest.label !== "") ?
                // eslint-disable-next-line
                (newQuest.label.split("|").map((item, index) => {
                    if (item !== "")
                        return (
                            <Option value={index.toString()} key={index.toString()}>{item}</Option>)
                }))
                : null
        )

    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col span={18}>
                        <FormItem
                            colon={layoutVars.COLON}
                            label={uiTexts.QUESTION_TITLE_LABEL}
                            required={true}
                            validateStatus={this.state.titleValidate}
                            help={this.state.titleValidate === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
                        >
                            <TextArea
                                autosize={{ minRows: 1, maxRows: 3 }}
                                maxLength={layoutVars.MAX_LENGTH_QUESION}
                                value={this.state.title}
                                placeholder={uiTexts.QUESTION_TITLE_LABEL_PLACEHOLDER}
                                onChange={(e) => {
                                    this.setState({ title: e.target.value })
                                }}
                                onBlur={(e) => {
                                    this.setState({ titleValidate: this.state.title === "" ? layoutVars.VALIDATE_ERROR : layoutVars.VALIDATE_SUCCESS },
                                        () => this.onChange())
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={1}/>
                    <Col span={4} >
                        <div style={{ display: this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 || this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE4 ? "block" : "none" }}>
                            <FormItem
                                colon={layoutVars.COLON}
                                label={uiTexts.INPUT_LABEL_REACTIVE_VALUE}
                                required={true}
                            >
                            <InputNumber min={1} max={100} type="number" 
                            value={this.state.ponderacion}
                            style={{ width: "100%" }}
                            onChange={(e) => {this.setState({ ponderacion: e} , () => this.onChange())}}
                            placeholder={uiTexts.INPUT_LABEL_PLACEHOLDER_PONDERACION}
                            maxLength={3}/>
                            </FormItem>
                        </div>
                    </Col>
                </Row>
                <Col sm={24}>
                <Row> 
                    <div style={{ display: this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 || this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE4 ? "block" : "none" }}>
                        <b><label style={styleVars.fontSize15}> {uiTexts.NEW_QUESTION_TITLE_OPTIONS_EVALUACION}</label></b>
                        <br/>
                        <label style={styleVars.fontSize12}>{uiTexts.NEW_QUESTION_TITLE_OPTIONS_SUB_EVALUACION}</label> 
                    </div>
                  </Row> 
                    <h3>{uiTexts.SELECT_EDIT_LABEL}</h3>

                </Col>
                <Col sm={24}>
                    {this.tagCreator(this.state)}

                </Col>
                <Divider orientation="left" style={styleVars.pinkText}>{uiTexts.PREVIEW_LABEL}</Divider>
                <Col sm={24} md={18} lg={12} >
                    <FormItem
                        disabled={this.state.disabled}
                        required={this.state.required}

                        validateStatus={this.state.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                        help={this.state.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
                    >

                        <Select
                            notFoundContent={uiTexts.EMPY_OPTIONS_COMPONENT}
                            style={{ width: "100%" }}
                            value={this.state.value.toString()}
                            name={'idField_' + this.state.idQuestion}
                            placeholder={uiTexts.SELECT_INSTRUCTION}
                            onChange={(e) => this.setState({ value: e },
                                () => this.onChange())}>
                            {this.selectCreator(this.state)}
                        </Select>

                    </FormItem>
                </Col>

                <Col span={24}>
                    <b>{uiTexts.REQUIERED_QUESTION_LABEL} &nbsp;&nbsp;&nbsp;</b> <Switch
                        checkedChildren={uiTexts.YES_LABEL}
                        unCheckedChildren={uiTexts.NOT_LABEL}
                        defaultChecked={(this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 || this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) ? this.state.required : true}
                        checked={(this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 || this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) ? this.state.required : true}
                        onChange={(checked) =>
                            this.setState({ required: checked }, () => this.onChange())}
                    />
                </Col>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFactoryComp);

/*

                         */