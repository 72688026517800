import * as ActionTypes from './actions';
import { UrlGetPublishData, UrlPublishQuestionary } from '../configs/baseUrl';
import { errorStatusType } from './errorSelector';
import { ERROR_TYPE, NOTIF_TYPE } from '../configs/galeryTypes';
import { resetLogin } from './loginActionsCreator';
import { PUBLISHED_SUCCESS_MSG } from '../configs/uiTexts';
import { sendMessage } from './messagesActionCreator';
import locale from 'antd/lib/locale-provider/es_ES';


/** functions to new publish */

export const fetchDataPublish = (token, idQuestionario) => (dispatch) => {
    // console.log(token);


    dispatch(fetchingDataPublish());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlGetPublishData,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({ "idCuestionario": idQuestionario }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = errorStatusType(404);
            throw errmess;
        })
        .then(response => response.json())
        .then(data => {
            dispatch(successDataPublish(data));
        })
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureDataPublish(error.message))
        }
        );

}



const fetchingDataPublish = () => ({
    type: ActionTypes.LOADING_DATA_PUBLISH,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true,
    typeMessage: null,
    succesSend: false

});

const failureDataPublish = (error) => ({
    type: ActionTypes.FAILED_FETCH_DATA_PUBLISH,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,
    typeMessage: error,
    succesSend: false
});

const successDataPublish = (data) => ({
    type: ActionTypes.SUCCESS_FETCH_DATA_PUBLISH,
    isLoading: false,
    payload: data,
    message: null,
    openModal: true,
    typeMessage: null,
    succesSend: false
});




/** functions to publish */
export const publishQuestionary = (token, payload) => (dispatch) => {
    dispatch(publishingQuestionary());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlPublishQuestionary,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify(payload),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = errorStatusType(404);
            throw errmess;
        })
        //.then(response => response.json())
        .then(data => {
            dispatch(successPublish());
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", PUBLISHED_SUCCESS_MSG));
        })
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failurePublish(error.message, payload))
        }
        );



}


const publishingQuestionary = () => ({
    type: ActionTypes.PUBLISHING,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true,
    typeMessage: null,
    succesSend: false

});

const failurePublish = (message, payload) => ({
    type: ActionTypes.FAILED_PUBLISH,
    isLoading: false,
    payload: payload,
    message: null,
    openModal: true,
    typeMessage: message,
    succesSend: false
});

const successPublish = () => ({
    type: ActionTypes.SUCCESS_PUBLISH,
    isLoading: false,
    payload: null,
    message: PUBLISHED_SUCCESS_MSG,
    openModal: false,
    succesSend:true,
    typeMessage: null,
});


export const closePublishWindow = () => ({
    type: ActionTypes.CLOSE_PUBLISH_WINDOW,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,
    typeMessage: null,
    succesSend: false
});