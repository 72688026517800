import * as ActionTypes from './actions';
import { UrlPreviewAns, UrlDeleteQuestAns } from '../configs/baseUrl';
import { DELETE_QUESTION_SUCCESS_MSG } from '../configs/uiTexts';
import { errorStatusType } from './errorSelector';
import { ERROR_TYPE } from '../configs/galeryTypes';
import { resetLogin } from './loginActionsCreator';



/** functions fetch Preview answers */

export const fetchPreviewAns = (token, idPeriodoCuestionario, idQuestionario, idUsuario) => (dispatch) => {
    // console.log(token);


    dispatch(fetchingPreviewAns());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlPreviewAns,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({
                "idCuestionario": idQuestionario,
                "idPeriodoCuestionario": idPeriodoCuestionario,
                "idUsuario": idUsuario
            }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(data => dispatch(successFetchPreviewAns(data)))
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureFetchPreviewAns(error.message))
        }
        );

}



const fetchingPreviewAns = () => ({
    type: ActionTypes.LOADING_PREVIEW_ANS,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true,
    typeMessage: null

});

const failureFetchPreviewAns = (error) => ({
    type: ActionTypes.FAILED_FETCH_PREVIEW_ANS,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,
    typeMessage: error
});

const successFetchPreviewAns = (data) => ({
    type: ActionTypes.SUCCESS_FETCH_PREVIEW_ANS,
    isLoading: false,
    payload: data,
    message: null,
    openModal: true,
    typeMessage: null
});


export const closePreviewAnsWindow = () => ({
    type: ActionTypes.CLOSE_PREVIEW_ANS_WINDOW,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,
    typeMessage: null
})


/**Functions to delete user Answer from quest */

export const fetchDeleteAns = (token, idPeriodoCuestionario, idQuestionario, idUsuario) => (dispatch) => {
    dispatch(deletingPreviewAns());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlDeleteQuestAns,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({
                "idCuestionario": idQuestionario,
                "idPeriodoCuestionario": idPeriodoCuestionario,
                "idUsuario": idUsuario
            }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(data => dispatch(successDeletePreviewAns(data)))
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureDeletePreviewAns(error.message))
        }
        );
        
        //.catch(error => dispatch(failureDeletePreviewAns(error.message)));

}

const deletingPreviewAns = () => ({
    type: ActionTypes.DELETING_ANS_QUEST,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true,
    succesSend: false,
    typeMessage: null
});

const failureDeletePreviewAns = (error) => ({
    type: ActionTypes.FAILED_DELETE_ANS_QUEST,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,
    succesSend: false,
    typeMessage: error
});

const successDeletePreviewAns = (data) => ({
    type: ActionTypes.SUCCESS_DELETE_ANS_QUEST,
    isLoading: false,
    payload: data,
    message: DELETE_QUESTION_SUCCESS_MSG,
    openModal: true,
    succesSend: true,
    typeMessage: null
});