
import React, { Component } from 'react'
import { Form, Radio, Row, Col } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import * as styleVars from '../../../configs/styleVars';
//import * as galeryTypes from '../../../configs/galeryTypes';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});


class RadioComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.question.value,
            questionaryToSend: null,
            questionToSend: null,
            disableOnPreviewConfig: false
        }

    }

    onChange = (e, questionary, section, question) => {


        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = e.target.value;
        if(this.props.isConfig)
        newQuestion.valueToDisableQuestions =  e.target.value;
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    radioCreator = (question) => {

        return (question.label.split("|").map((quest, index) => {
            return (
                 <Row>
                <Col span={1} style={styleVars.wRadio} key={index.toString()}>
                    <Radio value={index.toString()} key={index.toString()}></Radio>
                </Col>
                <Col style={styleVars.answer} >
                <p>{quest}</p>
            </Col>
            </Row>)
        }));

    }


    render() {
        if ((this.props.match.url === ("/" + linksRouter.newQuestionary)) || (this.props.match.url === "/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    {
                        this.props.question.relatedInfo !== null &&
                        <label style={styleVars.relatedInfo}>{this.props.question.relatedInfo}</label>
                    }

                    <FormItem colon={layoutVars.COLON}>
                        <RadioGroup name={'idField_' + this.props.question.idQuestion}
                        value={(typeof (this.props.question.value) !== "undefined") ? this.props.question.value.toString() : ""}
                        disabled={(this.props.question.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 ||
                            this.props.question.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE4) ? true : false}
                            onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, this.props.question)}
                        >
                            {this.radioCreator(this.props.question)}
                        </RadioGroup>
                    </FormItem>
                </React.Fragment>
            )

        return (

            <Row gutter={layoutVars.GUTTER_BASE}>
                {
                    this.props.question.relatedInfo !== null &&
                    <label style={styleVars.relatedInfo}>{this.props.question.relatedInfo}</label>
                }

                <FormItem 
                    disabled={this.props.question.disabled} 
                    colon={layoutVars.COLON} 
                    required={this.props.question.required}
                    validateStatus={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? layoutVars.VALIDATE_ERROR : null}
                    help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
                    >

                    <RadioGroup

                        disabled={
                            (this.props.match.path === "/" + linksRouter.editQuestionary + "/:id")
                                ? (this.props.isConfig === true ? false : true)
                                :
                                this.props.question.disabled}
                        value={(typeof (this.props.question.value) !== "undefined") ? this.props.question.value.toString() : ""}
                        name={'idField_' + this.props.question.idQuestion}
                        onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, this.props.question)}>
                        {this.radioCreator(this.props.question)}
                    </RadioGroup>

                </FormItem>
            </Row>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RadioComp));
