import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoadingComp } from '../genericComponents/LoadingComp';
import { showLoginModal } from '../../actions/loginActionsCreator';
import * as actions from '../../actions/actions';
import { fetchInboxAns } from '../../actions/inboxAnsActionsCreator';
import AnsDataTable from './AnsDataTable';
import PreviewModal from './PreviewModal'
import { Row, Divider, Col } from 'antd';
import { GUTTER_BASE } from '../../configs/layoutVars';
import { verticalMargin45, verticalMargin15 } from '../../configs/styleVars';
import DataDownload from './DataDownload';


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        inboxAns: state.inboxAns
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    fetchInboxAns: (token, idQuestionario) => dispatch(fetchInboxAns(token, idQuestionario)),
    showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
});


class InboxAnswers extends Component {
    /* constructor(props) {
         super(props)
         // console.log(this.props);
     }*/

    componentWillMount() {
        if (!this.props.credentials.isAuthenticated) {
            //Al cerrar sesión, a veces pasa por aquí, entonces también cachamos el caso para que no muestre el form de login al cerrar sesión
            if (this.props.credentials.showLogin !== actions.LOGIN && this.props.credentials.showLogin !== actions.LOGING && this.props.credentials.showLogin !== actions.LOGED_OUT) {
                //console.log("InboxAnswers", "No autenticado", "showLogin != [LOGIN, LOGING, LOGED_OUT]");
                this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, "LOGIN", this.props.credentials.idCuestionario, null);
            }
        }
        else if (this.props.credentials.token)
            this.props.fetchInboxAns(this.props.credentials.token, this.props.match.params.id)
    }

    render() {
        if (!this.props.credentials.isAuthenticated)
            return null;

        if (this.props.inboxAns.isLoading || this.props.inboxAns.isLoading === null)
            return <LoadingComp />;



        return (
            <React.Fragment>
                <PreviewModal />

                <Row gutter={GUTTER_BASE}>

                    <Col span={22} offset={1} >
                        <h2>Respuestas</h2>
                        <Divider style={verticalMargin15}  ></Divider>
                    </Col>
                    <Col span={22} offset={1} style={verticalMargin45} >
                        <DataDownload />
                        <AnsDataTable data={this.props.inboxAns.payload}></AnsDataTable>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InboxAnswers))

