import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router-dom'
import { fetchQuestionary, sendQuestionary } from '../../actions/previewQuestionaryActionCreator';
import { HeaderPreviewQuestionaryComp } from '../viewQuestionary/HeaderPreviewQuestionaryComp'; //'../HeaderPreviewQuestionaryComp';
import { LoadingComp } from '../genericComponents/LoadingComp';
import QuestionsCreatorComp from '../NewQuestionary/QuestionsCreatorComp';
import { Tabs, message } from 'antd';
//import { cloneObject, validateNonEmpty } from '../../helpers/helpers'
import { MESSAGE_CONFIG, TABS_ELIPSIS } from '../../configs/layoutVars';
//import * as uiTexts from '../../configs/uiTexts';
import * as styleVars from '../../configs/styleVars';
import { linksRouter } from '../../configs/baseUrl';
import ModalHeadQuest from '../NewQuestionary/ModalHeadQuest';
import * as actions from '../../actions/actions';
import { showLoginModal } from '../../actions/loginActionsCreator';
import { truncate } from '../../helpers/helpers';





//import * as ActionTypes from '../actions/actions';


const TabPane = Tabs.TabPane;

/** Redux get State ****************/
const mapStateToProps = (state) => {
  return {
    credentials: state.credentials,
    questionary: state.questionary,
    editionEstatus: state.editionEstatus
  }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
  showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
  fetchQuestionary: (token, idCuestionario) => dispatch(fetchQuestionary(token, idCuestionario)),
  sendQuestionary: (questionary, token) => dispatch(sendQuestionary(questionary, token))
});



message.config(MESSAGE_CONFIG);


/** Sections from questionary ****************/
const tabsBuilder = (sections, editionEstatus) => {
  //console.log(sections);
  // eslint-disable-next-line
  return (sections.map((section, index) => {
    //console.log(index);

    return (
      <TabPane
        style={styleVars.tabs}
        tab={truncate(TABS_ELIPSIS, section.title)}
        disabled={section.disabled}
        key={index}
      >
        <QuestionsCreatorComp editionEstatus={editionEstatus} section={section.idSection} questions={section.questions} />
      </TabPane>
    )
  })
  )
}



/* Save Button ****************/

class ViewQuestionaryComp extends Component {


  componentWillMount() {

    if (!this.props.credentials.isAuthenticated) {
      //Al cerrar sesión, a veces pasa por aquí, entonces también cachamos el caso para que no muestre el form de login al cerrar sesión
      if (this.props.credentials.showLogin !== actions.LOGIN && this.props.credentials.showLogin !== actions.LOGING && this.props.credentials.showLogin !== actions.LOGED_OUT) {
        //console.log("ViewQuestionaryComp", "No autenticado", "showLogin != [LOGIN, LOGING]");
        this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, actions.LOGIN, this.props.credentials.idCuestionario, null);
      }
    }

    else {
      this.props.fetchQuestionary(this.props.credentials.token, this.props.match.params.id)
    }
  }

  componentDidUpdate() {
    if (this.props.questionary.succesSend === true && this.props.questionary.message === null) {
      this.props.resetValuesQuestionary();
      console.log("Llegue a la actualización del component")
      this.props.history.push("/" + linksRouter.questionaryList);
    }

  }

  render() {

    if (this.props.questionary.isLoading || this.props.questionary.isLoading === null || this.props.questionary.questionary === null)
      return <LoadingComp />;


    if (this.props.questionary.questionary.header.questionaryName === "")
      return <ModalHeadQuest />




    if (this.props.questionary.questionary.header) {
      return (
        <React.Fragment>
          <HeaderPreviewQuestionaryComp header={this.props.questionary.questionary.header} />
          <Tabs >
            {tabsBuilder(this.props.questionary.questionary.sections, this.props.editionEstatus)}
          </Tabs>

        </React.Fragment>
      );
    }
    return null;
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewQuestionaryComp))
//