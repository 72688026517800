import * as galeryTypes from './galeryTypes';
import * as uiTexts from './uiTexts';

export const menuOptions =
    [{
        type: galeryTypes.INPUT,
        label: uiTexts.INPUT_LABEL_NQ,
        icon:"font-colors"
    },
    {
        type: galeryTypes.RADIO,
        label: uiTexts.RADIO_LABEL_NQ,
        icon:"check-circle"
    },
    {
        type: galeryTypes.CHECKBOX,
        label: uiTexts.CHECKBOX_LABEL_NQ,
        icon:"check-square"
    },
    {
        type: galeryTypes.SELECT,
        label: uiTexts.SELECT_LABEL_NQ,
        icon:"database"
    },
    {
        type: galeryTypes.RATE,
        label: uiTexts.RATE_LABEL_NQ,
        icon:"smile"
    },
    {
        type: galeryTypes.SLIDER,
        label: uiTexts.SLIDER_LABEL_NQ,
        icon:"stock"
    },
    {
        type: galeryTypes.DATEPICKER,
        label: uiTexts.DATEPICKER_LABEL_NQ,
        icon:"calendar"
    },
    {
        type: galeryTypes.DATERANGE_PICKER,
        label: uiTexts.DATERANGE_LABEL_NQ,
        icon:"schedule"
    },

    {
        type: galeryTypes.TIMEPICKER,
        label: uiTexts.TIMEPICKER_LABEL_NQ,
        icon:"clock-circle"
    }
    ]

    export const menuOptionsEvaluation =
    [{
        type: galeryTypes.RADIO,
        label: uiTexts.RADIO_LABEL_NQ,
        icon:"check-circle"
    },
    {
        type: galeryTypes.CHECKBOX,
        label: uiTexts.CHECKBOX_LABEL_NQ,
        icon:"check-square"
    },
    {
        type: galeryTypes.SELECT,
        label: uiTexts.SELECT_LABEL_NQ,
        icon:"database"
    }];
