
import React from 'react';
import * as galeryTypes from '../../configs/galeryTypes';
import { Col } from 'antd';
import * as uiTexts from '../../configs/uiTexts';
import moment from 'moment';

const typeSelector = (type, label, value, justificacion) => {
    let arrayVal = [];
    let val = null;
    let valEntero = null;

    switch (type) {
        case galeryTypes.INPUT:
            return value;

        case galeryTypes.RADIO:
            arrayVal = label.split("|")
            val = parseInt(value, 10)
            if(justificacion != null)
                return(<div>
                    <p>{arrayVal[val]}</p>
                    <p><b>{justificacion.title}</b></p>
                    <p>{justificacion.value}</p>
                </div>)

            else
                return arrayVal[val];

        case galeryTypes.CHECKBOX:
            arrayVal = label.split("|");
            val = value.split("|");
            let objRet = [];
            val.forEach((element, index) => {
                valEntero = parseInt(element, 10);
                objRet.push(<Col span={8} key={index}>{arrayVal[valEntero]}</Col>)
            });
            return objRet;

        case galeryTypes.RATE:
            return <span>{label + " : " + value}</span>

        case galeryTypes.DATEPICKER:
            return <span>{value!= "" ? moment(value, 'DD/MM/YYYY').format('L'): null}</span>

        case galeryTypes.DATERANGE_PICKER:
            val = value.split("|");
            console.log(val);

            return (
                <div>
                    <p><b>{uiTexts.PUBLISH_INITIAL_TXT} :</b> {val[0]!= "" ? moment(val[0], 'DD/MM/YYYY').format('L'): null}</p>
                    <p><b>{uiTexts.PUBLISH_END_TXT} : </b> {val[0]!= "" ? moment(val[0], 'DD/MM/YYYY').format('L'): null}</p>
                </div>)

                

        case galeryTypes.SELECT:
            arrayVal = label.split("|")
            val = parseInt(value, 10)
            return arrayVal[val];

        case galeryTypes.TIMEPICKER:
            return <span>{label + " : " + value}</span>

        case galeryTypes.SLIDER:
            return <span>{label + " : " + value}</span>

        default:
            return null

    }
}

const ValueCalculator = (question) => {
    return (<React.Fragment> {typeSelector(question.type, question.label, question.value, question.childQuestion)}</React.Fragment>);
}


export default ValueCalculator;
