import React, { Component } from 'react'
import { Button, Icon, Divider } from 'antd';
import { connect } from 'react-redux';
import { openWindowToEdit, deleteQuestion } from '../../actions/newQuestionActionCreator';
//import * as styleVars from '../../configs/styleVars';
import * as actionsGalery from '../../actions/actions';
import { questionFinder } from '../../helpers/helpers';
//import * as jsonQuery from 'json-query';

const mapStateToProps = (state) => {
    return {
        questionary: state.questionary
    }
}

const mapDispatchToProps = (dispatch) => ({
    openWindowToEdit: (question, section, action) => dispatch(openWindowToEdit(question, section, action)),
    deleteQuestion: (type, idQuestion, section, questionary)=> dispatch(deleteQuestion(type, idQuestion, section, questionary))
});


/**
 * Function to update edit action and question into redux 
 * @param {*} action 
 * @param {*} question 
 * @param {*} section 
 * @param {*} openWindowToEdit
 */
const onClick = (action, question, section, openWindowToEdit) => {   
    openWindowToEdit(question, section, action)
}

const deleteQuest = (type, idQuestion, section, questionary, deleteQuestion) => {
    //console.log("idQuestion", idQuestion);
    //console.log("questionary", questionary);
    deleteQuestion(type, idQuestion, section, questionary)
    //deleteQuestion
}


/**
 * Function to build buttons - Actions to  question: Edit/Delete
 * @param {*} actions 
 * @param {*} idQuestion 
 * @param {*} section 
 * @param {*} openWindowToEdit 
 * @param {*} questionary 
 */
const buttonsBuilder = (actions, idQuestion, section, questionary, openWindowToEdit, deleteQuestion) => {

    
    const question = questionFinder(idQuestion, section, questionary);
    return (
        // eslint-disable-next-line
        actions.map((action, index) => {
            switch (action) {


                case actionsGalery.EDIT_QUESTION:
                    return (
                        <span key={index} >
                            <Button onClick={() => {
                                onClick(actionsGalery.EDIT_QUESTION, question, section, openWindowToEdit, questionary.questionary.header.type)
                            }}
                                type="default" > <Icon type="edit" theme="outlined" key={index} /> Editar </Button>
                            <Divider type={"vertical"}></Divider>
                        </span>
                    )

                case actionsGalery.DELETE_QUESTION:
                    return (
                        <span key={index} >
                            <Button onClick={() => {
                                deleteQuest(actionsGalery.DELETE_QUESTION, idQuestion, section, questionary, deleteQuestion)
                            }}
                                type="default" > <Icon type="delete" theme="outlined" key={index} /> Eliminar</Button>
                        </span>
                    )


                default:
                    return null;

            }
        })
    );
}

class ActionsQuestionBuilderComp extends Component {
    render() {
        return (
            <div style={{ textAlign: "right" }}>
                {buttonsBuilder(this.props.actions, this.props.idQuestion, this.props.section, this.props.questionary, this.props.openWindowToEdit, this.props.deleteQuestion)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsQuestionBuilderComp);
