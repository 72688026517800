import React, { Component } from 'react'
import { Form, TimePicker } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';
import moment from 'moment';

const FormItem = Form.Item;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});

class TimePickerComp extends Component {

    onChange = (time, timeString, questionary, section, question) => {
        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = timeString.toString();
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    TimePickerCreator = (question) => {

        return <TimePicker
            onChange={(time, timeString) => this.onChange(time, timeString, this.props.questionary, this.props.section, this.props.question)}
            disabled={question.disabled}
            defaultOpenValue={(question.value === "" || question.value === "null") ? moment('00:00:00', 'HH:mm') : moment(question.value, 'HH:mm')}
            value={(question.value === "" || question.value === "null") ? moment('00:00:00', 'HH:mm') : moment(question.value, 'HH:mm')}
            format={"HH:mm"}
        />
    }


    render() {

        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem
                        disabled={this.props.question.disabled}
                        colon={layoutVars.COLON}
                        required={this.props.question.required}
                    >
                        {this.TimePickerCreator(this.props.question)}
                    </FormItem>
                </React.Fragment>
            )


        return (
            <FormItem
                disabled={this.props.question.disabled}
                colon={layoutVars.COLON}
                required={this.props.question.required}
                validateStatus={this.props.question.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >
                {this.TimePickerCreator(this.props.question)}
            </FormItem>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimePickerComp));
