import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Divider, Button, Modal } from 'antd';
import {  cloneObject } from '../../../helpers/helpers';
import { addQuestionaryToScreen } from '../../../actions/previewQuestionaryActionCreator';


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    addQuestionaryToScreen: (questionary) => dispatch(addQuestionaryToScreen(questionary))
});


class RestoreQuestConfig extends Component {
   
    resetQuestConfig = () => {
        let newQuestionary = cloneObject(this.props.questionary.questionary);
        newQuestionary.sections[this.props.section].questions[this.props.index].valueToDisableQuestions = "";
        newQuestionary.sections[this.props.section].questions[this.props.index].questionToDisable = "";
        newQuestionary.sections[this.props.section].questions[this.props.index].value = "";
        //console.log("reset");
        this.props.addQuestionaryToScreen(newQuestionary)
    }

    confirmRestore=()=> {
        Modal.confirm({
          title: 'Confirm',
          content: '¿Desea eliminar la configuración de la pregunta?',
          okText: 'Restaurar',
          cancelText: 'Cancelar',
          onOk : this.resetQuestConfig
        });
      }

    render() {
        return (
            <Col style={{ textAlign: "right" }} span={24}>
                <Divider></Divider>
                <Button
                    type={"danger"}
                    onClick={this.confirmRestore}
                    icon="reload"
                >{"Restaurar"}
                </Button>
            </Col>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreQuestConfig);