import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import { linksRouter } from '../../configs/baseUrl';
import { ADMIN_ROL } from '../../configs/layoutVars';
import guiaHome0 from '../../docs/guia_cuestionarios.pdf';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials
    }
}

/** Redux get dispatch functions ****************/
/*const mapDispatchToProps = (dispatch) => ({
    navigateTo: (invokedBy, navTo, redirectAfterAction, params) => dispatch(navigateTo(invokedBy, navTo, redirectAfterAction, params))
});*/


class GlobalMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { action: null }

    }

    onButtonClick = (e) => {
        switch (e.target.id) {
            case "startBtn":
                this.setState({ action: "/" + linksRouter.publicHome })
                break;

            case "questionaryBtn":
                this.setState({ action: "/" + linksRouter.questionaryList })
                break;

            default:
                this.setState({ action: null })
                break;

        }
    }


    render() {

        if (!this.props.credentials.isAuthenticated) {

            return null;
        }

        if (this.props.credentials.token !== null)
            if (this.props.credentials.token.rol !== ADMIN_ROL)
                return null;
        // this.props.history.push("/" + linksRouter.forbbidenPage);

        if (this.props.location.pathname.split("/")[1] === linksRouter.responseUsers || this.props.location.pathname.split("/")[1] === linksRouter.successAnsUser)
            return null;

        if (this.state.action !== null)
            try {
                this.props.history.push(this.state.action)
                this.setState({ action: null })
            } catch (error) { }


        return (
            <React.Fragment>
                <Row>

                    <Col id={"startBtn"} span={11}
                        onClick={(e) => this.onButtonClick(e)} className={this.props.location.pathname === "/inicio" ? "global-menu-button  active" : "global-menu-button "}
                    >
                        Inicio
                    </Col>

                    <Col id={"questionaryBtn"} span={11}
                        onClick={(e) => this.onButtonClick(e)} className={this.props.location.pathname === "/misCuestionarios" ? "global-menu-button  active" : "global-menu-button "}
                    >
                        Mis cuestionarios
                    </Col>


                    <a href={guiaHome0} target="_blank" download="guia_cuestionarios.pdf">

                        <Col id={"questionaryBtn"} span={2}
                            className={"global-menu-button "}
                        >
                            <Icon type="question-circle" theme="outlined" style={{ fontSize: "1.5em" }} />
                        </Col>
                    </a>


                </Row>
            </React.Fragment>

        )
    }
}

export default withRouter(connect(mapStateToProps)(GlobalMenu))
