import * as ActionTypes from './actions';
import { UrlInboxAnswers } from '../configs/baseUrl';
import { errorStatusType } from './errorSelector';
import { ERROR_TYPE } from '../configs/galeryTypes';
import { resetLogin } from './loginActionsCreator';



/** functions fetch Inbox answers */

export const fetchInboxAns = (token, idQuestionario) => (dispatch) => {
    // console.log(token);
    
    dispatch(fetchingInboxAns());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlInboxAnswers,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            body: JSON.stringify({ "idCuestionario": idQuestionario }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            let errmess = errorStatusType(404);
            throw errmess;
        })
        .then(response => response.json())
        .then(data => dispatch(successFetchInboxAns(data)))
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureFetchInboxAns(error.message))
        }
        );
}

const fetchingInboxAns = () => ({
    type: ActionTypes.LOADING_INBOX_ANS,
    isLoading: true,
    payload: null,
    message: null,
    openModal: true,
    typeMessage: null
});

const failureFetchInboxAns = (error) => ({
    type: ActionTypes.FAILED_FETCH_INBOX_ANS,
    isLoading: false,
    payload: null,
    message: null,
    openModal: false,
    typeMessage: error
});

const successFetchInboxAns = (data) => ({
    type: ActionTypes.SUCCESS_FETCH_INBOX_ANS,
    isLoading: false,
    payload: data,
    message: null,
    openModal: true,
    typeMessage: null
});

export const resetValuesInboxAns = () => ({
    type: ActionTypes.RESET_VALUES,
    isLoading: false,
    payload: null,
    message: null,
    openModal: true,
    typeMessage: null
});