import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginForm from './LoginForm';
import { Modal, Spin } from 'antd';
import { fetchLogout, fetchLogoutAndThanks, showLoginModal } from '../../actions/loginActionsCreator';
import * as actions from '../../actions/actions' 
import { linksRouter } from '../../configs/baseUrl';
/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary,
        califSend: state.questionary.califSend
    }

}
/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    logout: (token) => dispatch(fetchLogout(token)),
    logoutAndThanks: (token) => dispatch(fetchLogoutAndThanks(token)),
    showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
});


class LogInModalComp extends Component {

    handleLogout = () => {
        if (localStorage.getItem('correo')) {
            localStorage.removeItem('correo')
        }
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
        }
        //console.log("credenciales", this.props.credentials)
        if (this.props.credentials.token) {
            this.props.logout(this.props.credentials.token)
        }
    }

    handleCancelLogout = () => {
        this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, null, this.props.credentials.idCuestionario, null);
    }

    handleLogoutUnfinishedOrAlreadyFinished = () => {
        if (localStorage.getItem('correo')) {
            localStorage.removeItem('correo')
        }
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
        }
        if (this.props.credentials.token) {
            this.props.logoutAndThanks(this.props.credentials.token);
        }
	// Se limpia variable para conservar el comportamiento del sistema.
        this.props.questionary.califSend = false;
        this.props.history.replace("/"+ linksRouter.thanksPage);
    }

    handleLogoutUnfinished = () => {
        if (localStorage.getItem('correo')) {
            localStorage.removeItem('correo')
        }
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
        }
        if (this.props.credentials.token) {
            this.props.logoutAndThanks(this.props.credentials.token);
        }
        this.props.history.replace("/"+ linksRouter.publicHome);
    }

    handleNoAccessLoginBD = () => {
        if (localStorage.getItem('correo')) {
            localStorage.removeItem('correo')
        }
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
        }
        this.props.history.replace("/"+ linksRouter.sinAcceso);
    }

    handleLogoutAndExit = () => {
        if (localStorage.getItem('correo')) {
            localStorage.removeItem('correo')
        }
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
        }
        this.props.history.replace("/" + linksRouter.publicHome);
    }

    render() {
       // console.log("this.props.credentials.showLogin: ", this.props.credentials.showLogin);
       // console.log("props CUESTIONARIO", this.props.questionary);
       // console.log("this.props.credentials.showLogin", this.props.credentials.showLogin);
       // console.log('this.props.history.location.pathname', this.props.history.location.pathname)
        switch (this.props.credentials.showLogin) {

            case actions.LOGIN:

                return (
                    <Modal
                        footer={null}
                        width={"40%"}
                        title="Iniciar sesión"
                        visible={true}
                        onCancel={this.handleCancelLogout} >

                        <LoginForm />
                    </Modal>
                )
            case actions.LOGING:
                //console.log("case actions.LOGING: Modal Iniciando sesión...");
                if ((this.props.history.location.pathname.indexOf("sinAcceso") > -1)){
                    return null;
                }else{
                return (
                    <Modal
                        maskClosable={false}
                        footer={null}
                        width={"40%"}
                        title="Iniciar sesión"
                        visible={true}
                        onCancel={this.handleCancelLogout} >

                        <div style={{ textAlign: "center" }}>
                            <Spin />
                        </div>
                    </Modal>
                )
               }
            case actions.LOGOUT:
                //console.log("case actions.LOGOUT: Modal Cerrar sesión");

                return <Modal
                    title="Cerrar sesión"
                    visible={true}
                    okButtonProps={{ type: "default" }}
                    cancelButtonProps={{ type: "primary" }}
                    onOk={this.handleLogout}
                    onCancel={this.handleCancelLogout}
                    okText="Si"
                    cancelText="No" >

                    <h3>¿Quieres cerrar sesión?</h3>
                </Modal>;
            case actions.LOGED_OUT:
                if (this.props.questionary.califSend===true) {
                    //Se contestó el questionario
                    //console.log("case actions.LOGED_OUT: Cerrar sesión sin Modal");
                    this.handleLogoutUnfinishedOrAlreadyFinished();
                } else if (this.props.history.location.pathname.indexOf("responderCuestionario") > -1) {
                    //No se acabó el cuestionario o ya se había contestado y es la única forma de salir
                    //console.log("case actions.LOGED_OUT: Mosrtar Modal Cerrar sesión sin terminar o terminado");
                    return (
                        <Modal
                            title="Cerrar sesión"
                            visible={true}
                            okButtonProps={{ type: "default" }}
                            cancelButtonProps={{ type: "primary" }}
                            //onOk={this.handleLogoutUnfinishedOrAlreadyFinished}
                            onOk={this.handleLogoutUnfinished}
                            onCancel={this.handleCancelLogout}
                            okText="Si"
                            cancelText="No" >

                            <h3>¿Estás seguro que quieres cerrar sesión?</h3>
                        </Modal>
                    );
                } else if (this.props.history.location.pathname.indexOf("graciasParticipar") > -1) {
                    //No se acabó el cuestionario o ya se había contestado y es la única forma de salir
                    return null;
                } else {
                    //console.log("case actions.LOGED_OUT: ELSE null");
                    this.handleLogoutAndExit();
                }
                return null;
            case actions.NO_ACCESS:
                if ((this.props.history.location.pathname.indexOf("sinAcceso") > -1)){
                    return null;
                }else{
                this.handleNoAccessLoginBD();
                }
            default:
                return null;
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogInModalComp))
