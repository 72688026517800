import { loadingQuestionary, addQuestionaryToScreen, failureFetchQuestionary } from './previewQuestionaryActionCreator';
import { ERROR_TYPE } from '../configs/galeryTypes';
import { resetLogin } from './loginActionsCreator';
import { errorStatusType } from './errorSelector';
//import { cloneObject } from '../helpers/helpers';

/**
 * Functions to GET questionary to create new
 */
export const fetchNewQuestionary = () => dispatch => {
    dispatch(loadingQuestionary());
    return fetch("/templates/createQuest.json",

        {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": token.acces_token
            },
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(questionary => dispatch(addQuestionaryToScreen(questionary.questionary)))
        .catch(error => {
            //console.log(error.message);
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureFetchQuestionary(error.message))
        }
        );


}
