import * as ActionTypes from './actions';
import { UrlLogin, UrlLogout, UrlLoginBD } from '../configs/baseUrl';
import * as uiTexts from '../configs/uiTexts';

async function getFetchLogin(userName, password, idCuestionario, dispatch){

    let urlServer = localStorage.getItem("SERVER_CUESTIONARIOS");
    if(!urlServer){
        let respuesta = await fetch(window.location.origin + "/JsonHelper/url.json",
        {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
        }).then((response) => response.json()
        ).then((json) => {
            //console.log("json=>", json);
            return json;
        });
        //console.log("json:=>", respuesta.urlServer);
        localStorage.setItem("SERVER_CUESTIONARIOS", respuesta.urlServer);
        urlServer = respuesta.urlServer;
    }
    //console.log("urlServer:=>", urlServer);

    return fetch(urlServer + UrlLogin,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            redirect: "manual",
            referrer: "no-referrer",
            body: JSON.stringify({
                "usuario": userName,
                "password": password, 
                "idCuestionario": idCuestionario
            }),
        }
    )
        .then(response => {
            let error;
           // console.log("REsponse LOGIN", response)
            if (response.ok) {
                return response;
            } else {
                error = errorSelect(response.status)
                error.response = response;
                throw error;
            }
        },
            error => {
                error = errorSelect(404);
                throw error;
            })
        .then(response => response.json())
        .then(token => dispatch(successLogin(token)))
        .catch(error => dispatch(failureLogin(error.message)));
    
}

export const fetchLogin = (userName, password, idCuestionario) => (dispatch) => {
     // console.log("idCuestionario", idCuestionario);

    dispatch(fetchingLogin());

    getFetchLogin(userName, password, idCuestionario, dispatch);

};

async function getFetchLoginBD(userName, idCuestionario, dispatch){

    let urlServer = localStorage.getItem("SERVER_CUESTIONARIOS");
    if(!urlServer){
        let respuesta = await fetch(window.location.origin + "/JsonHelper/url.json",
        {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
        }).then((response) => response.json()
        ).then((json) => {
            //console.log("json=>", json);
            return json;
        });
        //console.log("json:=>", respuesta.urlServer);
        localStorage.setItem("SERVER_CUESTIONARIOS", respuesta.urlServer);
        urlServer = respuesta.urlServer;
    }
    //console.log("urlServer:=>", urlServer);

    return fetch( urlServer + UrlLoginBD,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            redirect: "manual",
            referrer: "no-referrer",
            body: JSON.stringify({
                "userName": userName, 
                "idCuestionario": idCuestionario
            }),
        }
    )
        .then(response => {
            let error;
          //  console.log("REsponse LOGIN", response);
            if (response.ok) {
                return response;
            } else {
                error = errorSelect(response.status)
                error.response = response;
                throw error;
            }
        },
            error => {
                error = errorSelect(404);
                throw error;
            })
        .then(response => response.json())
        .then(token => {
            dispatch(successLogin(token))
            localStorage.setItem("token", JSON.stringify(token))
        })
        .catch(() => dispatch(noAccesLogin()));
    
}

export const fetchLoginBD = (userName, idCuestionario) => (dispatch) => {

    dispatch(fetchingLogin());
    
    getFetchLoginBD(userName, idCuestionario, dispatch);
};


export const fetchLoginLocalStorage = () => (dispatch) => {
    //  console.log(userName + "-" + password);


    dispatch(fetchingLogin());
    const accessToken = localStorage.getItem('token');
    const tokenFinal= JSON.parse(accessToken)


    dispatch(successLogin(tokenFinal))
        
};




const errorSelect = (status) => {

    switch (status) {
        case 404:
            return new Error(uiTexts.FAILED_FETCH_MESSAGE);

        case 400:
            return new Error(uiTexts.FAILED_MESSAGE);

        case 450:
            return new Error(uiTexts.FAILED_ACTIVE_SESSION);
            
        case 451:
               return new Error(uiTexts.FAILED_BLOCKED_IP);

        case 500:
            return new Error(uiTexts.FAILED_SERVER);
        default:
            return new Error("default error");
    }
}

export const fetchLogout = (token) => (dispatch) => {
    dispatch(fetchingLogout());
    return fetch(localStorage.getItem('SERVER_CUESTIONARIOS') + UrlLogout,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            redirect: "manual"
        }
    )
    .then(
        response => {
            if (response.ok) {
                return response;
            } else {
                let error = errorSelect();
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess =  errorSelect();
            throw errmess;
        }
    )
    .then(response => response.json())
    .then(() => dispatch(logout()))
    .catch(() => dispatch(logout()));
}

export const fetchLogoutAndThanks = (token) => (dispatch) => {
    dispatch(fetchingLogout(token));
    return fetch(localStorage.getItem('SERVER_CUESTIONARIOS') + UrlLogout,
        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },
            redirect: "manual"
        }
    )
    .then(
        response => {
            if (response.ok) {
                return response;
            } else {
                let error = errorSelect();
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess =  errorSelect();
            throw errmess;
        }
    )
    .then(response => response.json())
    .then(() => dispatch(logoutAndThanks()))
    .catch(() => dispatch(logoutAndThanks()));
}

export const showLoginModal = (isAuthenticated, token, typeLog, idCuestionario, idImagen) => ({
    type: ActionTypes.LOGIN_SHOW_MODAL,
    isFetching: false,
    message: null,
    isAuthenticated: isAuthenticated,
    token: token,
    showLogin: typeLog, 
    idCuestionario: idCuestionario,
    idImagen: idImagen
});

export const fetchingLogin = () => ({
    type: ActionTypes.LOGIN_FETCHING,
    isFetching: true,
    message: null,
    isAuthenticated: false,
    token: null,
    showLogin: ActionTypes.LOGING
});

export const failureLogin = (message) => ({
    type: ActionTypes.LOGIN_FAILURE,
    isFetching: false,
    message: message,
    isAuthenticated: false,
    token: null,
    showLogin: "LOGIN"
});

export const successLogin = (token) => ({
    type: ActionTypes.LOGIN_SUCCESS,
    isFetching: false,
    message: null,
    isAuthenticated: true,
    token: token,
    showLogin: null
});

export const fetchingLogout = (token) => ({
    type: ActionTypes.LOGOUT_FETCHING,
    isFetching: true,
    message: null,
    isAuthenticated: true,
    token: token,
    showLogin: null
});

export const logout = () => ({
    type: ActionTypes.LOGED_OUT,
    isFetching: false,
    message: null,
    isAuthenticated: false,
    token: null,
    showLogin: ActionTypes.LOGED_OUT
});

export const logoutAndThanks = () => ({
    type: ActionTypes.LOGED_OUT,
    isFetching: false,
    message: null,
    isAuthenticated: false,
    token: null,
    showLogin: null
});

export const resetLogin = () => ({
    type: ActionTypes.RESET_LOGIN,
    isFetching: false,
    message: null,
    isAuthenticated: false,
    token: null,
    showLogin: null
})

export const noAccesLogin = () => ({
    type: ActionTypes.NO_ACCESS,
    isFetching: false,
    message: null,
    isAuthenticated: false,
    token: null,
    showLogin: ActionTypes.NO_ACCESS
});