import React, { Component } from 'react'
import { Form, Select } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';

const FormItem = Form.Item;
const Option = Select.Option;


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});

class SelectComp extends Component {

    onChange = (e, questionary, section, question) => {

        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = e.toString()
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }


    selectCreator = (newQuest) => {

       
        return (
            (newQuest.label !== "") ?
             // eslint-disable-next-line
                (newQuest.label.split("|").map((item, index) => {
                    if (item !== "")
                        return (
                            <Option value={index.toString()} key={index.toString()}>{item}</Option>)
                }))
                : null
        )

    }

    render() {

        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem >
                        <Select
                            value={this.props.question.value.toString() !== "" ? this.props.question.value.toString() : uiTexts.SELECT_INSTRUCTION}
                            notFoundContent={uiTexts.SELECT_INSTRUCTION}
                            name={'idField_' + this.props.question.idQuestion}
                            placeholder={uiTexts.SELECT_INSTRUCTION}
                            disabled={(this.props.question.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 ||
                                this.props.question.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE4) ? true : false}
                        >
                            {this.selectCreator(this.props.question)}
                        </Select>
                    </FormItem>
                </React.Fragment>
            )


        return (
            <FormItem
                disabled={this.props.question.disabled}
                required={this.props.question.required}
                validateStatus={this.props.question.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >

                <Select
                    disabled={
                        (this.props.match.path === "/" + linksRouter.editQuestionary + "/:id")
                        ? (this.props.isConfig === true ? false : true)
                        :
                        this.props.question.disabled}
                    notFoundContent={uiTexts.EMPY_OPTIONS_COMPONENT}
                    name={'idField_' + this.props.question.idQuestion}
                    value={this.props.question.value.toString() !== "" ? this.props.question.value.toString() : uiTexts.SELECT_INSTRUCTION}
                    onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, this.props.question)}
                    placeholder={uiTexts.SELECT_INSTRUCTION}>
                    {this.selectCreator(this.props.question)}
                </Select>

            </FormItem>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectComp));