import React, { Component } from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { closePublishWindow } from '../../actions/publishActionsCreator'
import * as uiTexts from '../../configs/uiTexts';
import PublishForm from './PublishForm';



/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        publishData: state.publishData

    }
}

/** Redux get dispatch functions ****************/

const mapDispatchToProps = (dispatch) => ({
    closePublishWindow: () => dispatch(closePublishWindow())
});



class NewPeriod extends Component {

    render() {
        //console.log(this.props.publishData.openModal);

        if (this.props.publishData.openModal === null || this.props.publishData.openModal === false)
            return null;



        return (

            <Modal
                maskClosable={false}
                closable={!this.props.publishData.isLoading}
                title={uiTexts.PUBLISH_RANGE_TXT}
                visible={true}//{this.state.visible}
                width={"40%"}
                style={{ maxWidth: "600px" }}
                footer={null}
                //onOk={this.handleOk}
                onCancel={this.props.closePublishWindow}
            >
                <PublishForm />
            </Modal>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPeriod);
/*
 <div style={{ textAlign: "center" }}>
                    <Spin />
                </div>

*/