import React, { Component } from 'react';
import { Form, Slider, Icon } from 'antd';
//import * as uiTexts from '../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import * as styleVars from '../../../configs/styleVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';
const FormItem = Form.Item;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});



class SliderComp extends Component {

    onChange = (e, questionary, section, question) => {
        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = e.toString();
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }


    sliderCreator = (question) => {
        //console.log("range", range.split("|")[0]);

        //return null;

        return <Slider
        disabled={question.disabled}
            onAfterChange={e => this.onChange(e, this.props.questionary, this.props.section, this.props.question)}
            min={0}
            max={100}
            defaultValue={question.value === "" ? 0 : parseInt(question.value, 10)}
        />
    }


    render() {
        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem
                        colon={layoutVars.COLON}
                    >
                        <div className="icon-wrapper-slider">
                            <Icon type={"minus-circle"} style={styleVars.fontSizeIconSlider}
                                theme={"twoTone"} twoToneColor={styleVars.MAIN_COLOR} />

                            {this.sliderCreator(this.props.question)}

                            <Icon type={"plus-circle"} style={styleVars.fontSizeIconSlider}
                                theme={"twoTone"} twoToneColor={styleVars.MAIN_COLOR} />
                        </div>
                    </FormItem>
                </React.Fragment>
            )



        return (
            <FormItem
                disabled={this.props.question.disabled}
                colon={layoutVars.COLON}
            >
                <div className="icon-wrapper-slider">
                    <Icon type={"minus-circle"} style={styleVars.fontSizeIconSlider}
                        theme={"twoTone"} twoToneColor={styleVars.MAIN_COLOR} />

                    {this.sliderCreator(this.props.question)}

                    <Icon type={"plus-circle"} style={styleVars.fontSizeIconSlider}
                        theme={"twoTone"} twoToneColor={styleVars.MAIN_COLOR} />
                </div>
            </FormItem>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SliderComp));

