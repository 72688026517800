import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Icon } from 'antd';
import * as  uiTexts from '../../configs/uiTexts';
//import * as uiTexts from '../../configs/uiTexts'
import { CSVLink } from "react-csv";
import { fetchDownloadData } from '../../actions/downloadDataActionsCreator';
import { showLoginModal } from '../../actions/loginActionsCreator';
import * as actions from '../../actions/actions';
import { withRouter } from 'react-router-dom';
import { UrlDownloadData } from '../../configs/baseUrl';


/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        downloadData: state.downloadData,
        credentials: state.credentials,
        inboxAns: state.inboxAns
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    fetchDownloadData: (token, idCuestionario) => dispatch(fetchDownloadData(token, localStorage.getItem("SERVER_CUESTIONARIOS") + UrlDownloadData, idCuestionario)),
    showLoginModal: (isAuthenticated, token, typeLog, idCuestionario) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario)),
});



class DataDownload extends Component {
    constructor(props) {
        super(props);
        this.state = { data: null }
        //console.log(this.props);

    }
    componentWillMount() {
        if (!this.props.credentials.isAuthenticated) {
            if (this.props.credentials.showLogin !== actions.LOGIN && this.props.credentials.showLogin !== actions.LOGING)
                this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, "LOGIN", this.props.credentials.idCuestionario)
        }
        else if (this.props.credentials.token !== null)
            this.props.fetchDownloadData(this.props.credentials.token, this.props.match.params.id)
    }


    render() {

        if (this.props.downloadData.payload === null || this.props.downloadData.payload.length < 1)
            return null;
            
        return (

            <div style={{ textAlign: "right", marginBottom: 10 }}>
                <CSVLink
                    filename="resultados.csv"//{this.props.inboxAns.payload && this.props.inboxAns.payload.questionaryTitle.csv}
                    data={this.props.downloadData.payload}
                    className="ant-btn ant-btn-primary"

                ><Icon type="download" /> {uiTexts.DOWNLOAD_LABEL} </CSVLink>

            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataDownload))

