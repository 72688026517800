/**
 * Components Galery
 */
export const INPUT = "INPUT";
export const RADIO = "RADIO";
export const CHECKBOX = "CHECKBOX";
export const RATE = "RATE";
export const DATEPICKER = "DATEPICKER";
export const DATERANGE_PICKER = "DATERANGE_PICKER";
export const SELECT = "SELECT";
export const SWITCH = "SWITCH";
export const TIMEPICKER = "TIMEPICKER";
export const SLIDER = "SLIDER";


export const NOTIF_TYPE = {
    info: "info",
    succes: "success",
    error: "error",
    warning: "warning",
}

export const ERROR_TYPE = {
    OFFLINE: "OFFLINE",
    TOKEN: "TOKEN",
    SAVE: "SAVE",
    GET_INFO: "GET_INFO",
    PERIOD: "PERIOD",
    GROUP: "GROUP",
    FORBBIDEN: "FORBBIDEN",
    GENERIC:"GENERIC",
    SESSION: "SESSION"
}
