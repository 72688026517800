import React, { Component } from 'react'
import { Form, Checkbox, Row, Col } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { connect } from 'react-redux';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';


const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});


class CheckBoxComp extends Component {

    onChange = (e, questionary, section, question) => {

        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = e.join("|");

        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    checkCreator = () => {
        return (

            this.props.question.label.split("|").map((question, index) => {
                return <Col style={{ marginTop: "10px", textAlign: "justify" }}  xs={24} sm={12} md={12} lg={8} key={index.toString()}><Checkbox value={index.toString()} key={index.toString()}>{question}</Checkbox>  </Col>
            })
        );
    }

    render() {


        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem
                    >
                        <CheckboxGroup name={'idField_' + this.props.question.idQuestion}
                        value={this.props.question.value.split("|").map(String)}
                        disabled={(this.props.question.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 ||
                            this.props.question.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE4) ? true : false}
                    >
                            {this.checkCreator()}
                        </CheckboxGroup>
                    </FormItem>
                </React.Fragment>
            )



        return (
            <FormItem
                colon={layoutVars.COLON}
                required={this.props.question.required}
                validateStatus={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? layoutVars.VALIDATE_ERROR : null}
                help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >
                <CheckboxGroup
                    style={{ width: '100%' }}
                    disabled={
                        (this.props.match.path === "/" + linksRouter.editQuestionary + "/:id")
                            ? (this.props.isConfig === true ? false : true)
                            :
                            this.props.question.disabled}
                    value={this.props.question.value.split("|").map(String)}
                    name={'idField_' + this.props.question.idQuestion}
                    onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, this.props.question)}
                >
                    <Row gutter={layoutVars.GUTTER_BASE}>
                        {this.checkCreator()}
                    </Row>
                </CheckboxGroup>

            </FormItem>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckBoxComp));
