import * as ActionTypes from './actions';
import { UrlPreview, UrlSave } from '../configs/baseUrl';
import { cloneObject, setDisableStatusIntoQuest } from '../helpers/helpers'
import { successListQuestionary } from './homeActionsCreator';
import { errorStatusType } from './errorSelector';
import { ERROR_TYPE, NOTIF_TYPE } from '../configs/galeryTypes';
import { resetLogin } from './loginActionsCreator';
import { SEND_QUEST_SUCCESS_MSG } from '../configs/uiTexts';
import { sendMessage } from './messagesActionCreator';





/**
 * Functions to GET questionary to preview
 */
export const fetchQuestionary = (token, idCuestionario) => (dispatch) => {

    dispatch(loadingQuestionary());
    return fetch(localStorage.getItem('SERVER_CUESTIONARIOS') + UrlPreview,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },

            body: JSON.stringify({ "idCuestionario": idCuestionario }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(questionary => dispatch(processDisabled(questionary)))
        .catch(error => {
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureFetchQuestionary(error.message))
        }
        );

}

export const processDisabled = (questionary) => (dispatch) => {
    const cloneQuestionary = cloneObject(questionary.questionary)
    cloneQuestionary.sections.forEach(section => {
        setDisableStatusIntoQuest(section.questions)
    });
    dispatch(addQuestionaryToScreen(cloneQuestionary));
}

export const addQuestionaryToScreen = (questionary) => ({
    isLoading: false,
    message: null,
    type: ActionTypes.ADD_QUESTIONARY_TO_SCREEN,
    payload: questionary,
    typeMessage: null
})


export const loadingQuestionary = () => ({
    isLoading: true,
    message: null,
    type: ActionTypes.LOADING_QUESTIONARY,
    payload: null,
    typeMessage: null
})

export const processingQuestionary = () => ({
    isLoading: true,
    message: null,
    type: ActionTypes.PROCESSING_QUESTIONARY,
    payload: [],
    typeMessage: null
})

export const failureFetchQuestionary = (error) => ({
    isLoading: false,
    message: null,
    type: ActionTypes.FAILED_LOAD_QUESTIONARY,
    payload: [],
    typeMessage: error
})





/**
 * Functions to update questionary
 * @param {questionary, section, question, isPreview:null}
 */
export const updateValues = (questionary, section, question, isEdit = null) => (dispatch) => {

    let newQuest = cloneObject(questionary);
    newQuest.sections[section].questions.forEach((element, index) => {
        if (question.idQuestion === element.idQuestion) {
            newQuest.sections[section].questions[index] = question;
        }
    });

    if (!isEdit)
        newQuest.sections.forEach(section => {
            setDisableStatusIntoQuest(section.questions)
        });
    dispatch(updateValuesProcessed(newQuest));

}

export const updateHeader = (questionary, header) => (dispatch) => {
    let newQuest = cloneObject(questionary);
    newQuest.header = header;
    dispatch(updateValuesProcessed(newQuest));
}


export const updateValuesProcessed = (newQuestionary) => ({
    isLoading: false,
    message: null,
    type: ActionTypes.UPDATED_VALUES,
    payload: newQuestionary,
    typeMessage: null
})



/**
 * Functions to SEND questionary
 */

export const sendQuestionary = (questionaryTosend, token) => (dispatch) => {
    dispatch(sendingQuestionary());
    const questionary = { "header": questionaryTosend.header, "sections": questionaryTosend.sections }

    const fetchParams = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.accessToken
        },
        body: JSON.stringify({ questionary }),
        redirect: "manual"
    }

    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlSave, fetchParams)
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                let error = errorStatusType(response.status);
                error.response = response;
                throw error;
            }
        }, error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(questionary => dispatch(successListQuestionary(questionary)))
        .then(()=>
            {
                dispatch(successSendQuestionary());
                dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", SEND_QUEST_SUCCESS_MSG));
            })
        .catch(error => {
            if (error.message === ERROR_TYPE.TOKEN) {
                dispatch(resetLogin())
            }
            dispatch(failureSendQuestionary(error.message, questionary))
        }
        );
}


export const sendingQuestionary = () => ({
    isSending: true,
    message: null,
    succesSend: false,
    type: ActionTypes.SENDING_QUESTIONARY,
    payload: null,
    typeMessage: null
})


export const failureSendQuestionary = (error, questionary) => ({
    isSending: false,
    message: null,
    type: ActionTypes.FAILED_SEND_QUESTIONARY,
    succesSend: false,
    payload: questionary,
    typeMessage: error
})

export const successSendQuestionary = (idImagen) => (
    {
    isSending: false,
    message: SEND_QUEST_SUCCESS_MSG,
    type: ActionTypes.SUCCESS_SEND_QUESTIONARY,
    succesSend: true,
    payload: null, 
    idImagen: idImagen
})




/**
 * Function to RESET all questionary values
 */

export const resetValuesQuestionary = (message) => ({
    isLoading: false,
    isSending: false,
    message: message,
    type: ActionTypes.RESET_VALUES,
    payload: null,
    succesSend: false
});