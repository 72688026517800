import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './configs/configureStore';
import Main from './components/MainComponent';
import { createHashHistory } from 'history';
import moment from 'moment';
import 'moment/locale/es'
moment.locale('es')




const store = ConfigureStore();
const hashHistory = createHashHistory();

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router  history={hashHistory}>
          <Main />
        </Router>
      </Provider>
    );
  }
}

export default App;

