import React, { Component } from 'react';
import { Menu, Dropdown, Card, Icon, Modal, Divider } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { homeCardsActions } from '../../actions/actions';
import { linksRouter } from '../../configs/baseUrl'
import { fetchDataPublish } from '../../actions/publishActionsCreator';
import { fetchDataPeriod } from '../../actions/editPeriodActionsCreator';
import { fetchCloneQuest, fetchDeleteQuest } from '../../actions/homeActionsCreator';
import * as uiTexts from '../../configs/uiTexts';
import { verticalMargin15, pinkText } from '../../configs/styleVars';
import { truncate } from '../../helpers/helpers';
import {  TABS_ELIPSIS_CARD } from '../../configs/layoutVars';


const confirm = Modal.confirm;
const UrlModal = Modal.info;

/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        publishData: state.publishData
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    fetchDataPublish: (token, idQuestionario) => dispatch(fetchDataPublish(token, idQuestionario)),
    fetchDataPeriod: (token, idQuestionario) => dispatch(fetchDataPeriod(token, idQuestionario)),
    fetchCloneQuest: (token, idQuestionary) => dispatch(fetchCloneQuest(token, idQuestionary)),
    fetchDeleteQuest: (token, idQuestionary) => dispatch(fetchDeleteQuest(token, idQuestionary)),

});


class CardQuest extends Component {


    onClickMenu = ({ key, item }) => {

        const values = key.split("|");
        switch (values[0]) {
            case homeCardsActions.PREVIEW_QUEST:
                this.props.history.push("/" + linksRouter.previewQuestionary + "/" + values[1]);
                break;
            case homeCardsActions.DELETE_QUEST:
                this.showDeleteConfirm(this.props.fetchDeleteQuest, this.props.credentials.token, values[1]);
                break;
            case homeCardsActions.CLONE_QUEST:
                this.props.fetchCloneQuest(this.props.credentials.token, values[1]);
                break;
            case homeCardsActions.EDIT_QUEST:
                this.props.history.push("/" + linksRouter.editQuestionary + "/" + values[1]);
                break;
            case homeCardsActions.ADD_PERIOD:
                this.props.fetchDataPublish(this.props.credentials.token, values[1]);
                break;
            case homeCardsActions.POST_QUESTIONARY:
                this.props.fetchDataPublish(this.props.credentials.token, values[1]);
                break;
            case homeCardsActions.EDIT_PERIOD:
                this.props.fetchDataPeriod(this.props.credentials.token, values[1]);
                break;
            case homeCardsActions.VIEW_URL:
                this.showUrlModal(item.props.data_item);
                break;

            default:
                break;
        }

    }
    // <p> {typeof (url) === "undefined" ? <a target={'_blank'} href={url}>{url}</a> : uiTexts.GENERIC_ERROR} </p>
    showUrlModal = (periods) => {
        var urls = null;

        if (periods != ''){
        urls = periods.map(period => {
            return (
                <div>
                    <Divider style={pinkText} orientation="left">{period.periodo} </Divider>
                    <p>
                        <span><b>{uiTexts.PUBLISH_INITIAL_TXT}:</b>{period.fechaInicio}</span>
                        <span style={{ marginLeft: 15 }}><b>{uiTexts.PUBLISH_END_TXT}:</b>{period.fechaFin}</span>
                    </p>
                    <span style={verticalMargin15}><b><Icon type="link" /> URL </b></span><br></br>
                    <p>{window.location.origin}/#/{linksRouter.responseUsers}/{period.hash}</p>
                </div>);
        });
        }else{
            urls = (
                <div>
                    <p>
                        <span><b>{uiTexts.FIN_PERIODO_PUBLICACION}</b></span>
                    </p>
                </div>);
        }

        UrlModal({
            maskClosable:true,
            title: uiTexts.URL_MODAL_TITLE,
            content: urls,
            width: "80%",
            okText: uiTexts.ACCEPT,
        });
    }

    showDeleteConfirm = (fetchDeleteQuest, token, idQuestionary) => {
        confirm({
            title: uiTexts.DELETE_LABEL,
            content: uiTexts.DELETE_QUESTIONARY_LABEL,
            okText: 'Si',
            okType: 'error',
            cancelText: 'No',
            onOk() {
                fetchDeleteQuest(token, idQuestionary)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    menu = (idQuestionary, actions, periods) => {
        return (
            <Menu onClick={this.onClickMenu}>
                {actions.map((element, index) => {
                    //console.log(element);
                    return <Menu.Item data_item={periods} key={element.action + "|" + idQuestionary}>
                        <Icon type={element.icon} /> {element.label}
                    </Menu.Item>
                })}
            </Menu>
        )
    }

    render() {       
        return (
            <div className="card-quest-container"> 
                <Card
                    hoverable={true}
                    className={(this.props.cardParams.type == 2 || this.props.cardParams.type == 4) || this.props.cardParams.type.includes('EVALUACION') ? this.props.cardParams.new  ? "card-exist-test-new" : "card-exist-test" : this.props.cardParams.new ? "card-exist-quest-new" : "card-exist-quest"}
                >

                    <Dropdown.Button
                        trigger={['click']}
                        placement="bottomLeft"
                        className={"menu-card-exist-quest"}
                        overlay={this.menu(this.props.cardParams.id, this.props.actions, this.props.cardParams.periods, this.props)}
                    >
                    </Dropdown.Button>

                    <p style={{ textAlign: "center", marginBottom: "0em", marginTop: "1em" }}> {truncate(TABS_ELIPSIS_CARD, this.props.cardParams.title)}</p>
                </Card>
                
                {
                    this.props.isPublished &&
                    <p style={{ textAlign: "center", marginTop: "1em" }}>
                        <Link to={"/bandejaRespuestas/" + this.props.cardParams.id}>Ver repuestas<Icon type="right" /></Link>
                    </p>
                }
            </div>

        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardQuest));
