import React, { Component } from 'react'
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { closePreviewAnsWindow } from '../../actions/previewAnsActionCreator'
import PreviewQuest from './PreviewQuest';
//import * as uiTexts from '../../configs/uiTexts'


/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        previewAns: state.previewAns
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    closePreviewAnsWindow: () => dispatch(closePreviewAnsWindow())
});



class PreviewModal extends Component {
    render() {
        if (this.props.previewAns.openModal === null || this.props.previewAns.openModal === false)
            return null;

        return (

            <Modal
                maskClosable={false}
                closable={!this.props.previewAns.isLoading}
                title={this.props.previewAns.payload !== null && this.props.previewAns.payload.questionaryTitle}
                visible={true}//{this.state.visible}
                width={"85%"}
                className={"preview-modal"}
                style={{ maxWidth: "1500px" }}
                footer={null}
                //onOk={this.handleOk}
                onCancel={this.props.closePreviewAnsWindow}
            >
                <PreviewQuest></PreviewQuest>
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);
