import * as layoutVars from '../configs/layoutVars';
import * as jsonQuery from 'json-query';
import Query from 'json-query-chain';
//import { homeCardsActions } from '../actions/actions';
//import { linksRouter } from '../configs/baseUrl'


/**
 * 
 * @param {questionary[sections]} 
 * @returns {boolean}
 * Returns false if all requieres questions are full
 */
export const validateRequieredAns = (sections) => {
    let emptyValuesFound = null;
    sections.forEach(section => {
        //console.log(section, section);
        if (emptyValuesFound)
            return emptyValuesFound
        section.questions.forEach(question => {
           // console.log(question, question);
            if (emptyValuesFound)
                return emptyValuesFound
            if (question.required) {
                 console.log(question);
                if (question.disabled) {
                    emptyValuesFound = false;
                } else {
                    if (question.value === "" || question.value === null) {
                        emptyValuesFound = true;
                        return emptyValuesFound;
                    }
                    else {
                        emptyValuesFound = false;
                    }
                }
            } else {
                emptyValuesFound = false
            }

        })
    });
    return emptyValuesFound;
}

/**
 * 
 * @param {questionary[sections]} 
 * @returns {boolean}
 * Returns false if one of the questions is full
 */
 export const validateRequieredOneAns = (sections) => {
    let emptyValuesFound = null;
    let count = 0;

    sections.forEach(section => {
        section.questions.forEach(question => {
            if (question.value === "" || question.value === null) {
                count += count + 0;
            }else {
                count += count + 1;
            }
        })
    });

    if (count >= 1){
        emptyValuesFound = false; 
    }else{
        emptyValuesFound = true;
    }
    return emptyValuesFound;
}

/**
 * 
 * @param {question} validate empty values
 * @returns {question}
 */
export const validateEmptyValues = (question) => {
    let clonedQuestion = cloneObject(question)
    clonedQuestion.validateStatus = "";

    try {
        if (clonedQuestion.childQuestion === null)
            clonedQuestion.childQuestion.validateStatus = "";
    } catch (error) {
        //console.log("no child");
    }

    if (clonedQuestion.required)
        if (clonedQuestion.value === "")
            clonedQuestion.validateStatus = layoutVars.VALIDATE_ERROR;
    try {

        if (clonedQuestion.childQuestion.required)
            if (clonedQuestion.childQuestion.value === "")
                clonedQuestion.childQuestion.validateStatus = layoutVars.VALIDATE_ERROR;
    } catch (error) {
        //console.log("no child");

    }

    return clonedQuestion;
}


/**
 * Function to set disable status into questions
 * @param questions
 */
export const setDisableStatusIntoQuest = (questions) => {
    // let newQuestions=cloneObject(questions);

    getIdDisabledQuestions(resetDisablevalues(questions)).forEach(questId => {


        questions.forEach(quest => {
            //console.log("quest.idQuestion ", quest.idQuestion);
            //console.log("questId", questId);



            if (quest.idQuestion === questId) {
                quest.disabled = true;
                try {
                    quest.childQuestion.disabled = false
                } catch (error) {
                    //console.log("disabled child component fail");
                }
            }
        })
    });

    return questions;
}


/** start functions to set disable status into questions *************************************** */

/**
 * Function to getId from questions to disable
 * @param  questions
 */
const getIdDisabledQuestions = (questions) => {


    let questWithRel = {
        value: new Query(questions)
            .filterBy('questionToDisable', x => x !== null)
            .results
    };
    //console.log(questWithRel);



    //let questWithRel2 = jsonQuery('[*questionToDisable!=null]', { data: questions })
    //console.log(questWithRel.value);
    //console.log(questWithRel2);
    let objToListen = []
    try {
        questWithRel.value.forEach(quest => {
            let arrayValues = quest.value.split("|");
            // console.log(arrayValues);

            arrayValues.forEach(value => {
                //console.log(quest);
                if (parseInt(value) === parseInt(quest.valueToDisableQuestions)) {
                    ///console.log(value + "-" + quest.valueToDisableQuestions);
                    //  console.log(quest.questionToDisable);
                    objToListen = objToListen.concat(quest.questionToDisable.split("|"));
                    // console.log(objToListen);
                }
            });
        });
    } catch (error) {
        // console.log("any quest to disable");

    }
    //console.log(objToListen);
    return objToListen
}

/**
 * Function to reset disabled values
 * @param questions
 */

const resetDisablevalues = (questions) => {
    questions.forEach((quest, index) => {
        questions[index].disabled = false;
        try {
            if (questions[index].childQuestion.idQuestion !== undefined)
                questions[index].childQuestion.disabled = true;
        } catch (error) {

        }
    })
    return questions;
}

/** end functions to set disable status into questions *************************************** */




/**
 * Function to clone object
 * @param object
 * @return objectCloned
 */

export const cloneObject = function (o) {
    return JSON.parse(JSON.stringify(o));
}


/**
 * Function to find question into questionary
 * @param idQuestion
 * @param section
 * @param questionary
 * @returns question [json]
 */

export const questionFinder = (idQuestion, section, questionary) => {

    try {
        const sections = (questionary.questionary.sections);
        const sectionFound = jsonQuery('[idSection=' + section + ']', {
            data: sections
        })
        //console.log(sectionFound);
        const questionFound = jsonQuery('[idQuestion=' + idQuestion + ']', {
            data: sectionFound.value.questions
        })
        return questionFound.value
    } catch (error) {

    }
    return null;

}



export const validateMail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());

}


/** Truncate text  */
export const truncate = (n, useWordBoundary) => {
    //console.log(useWordBoundary);
    //console.log(n);

    if (useWordBoundary.length <= n) {
        return useWordBoundary;
    }
    let subString = useWordBoundary.substr(0, n - 1);
    return (useWordBoundary ?
        subString.substr(0, subString.lastIndexOf(' ')) :
        subString) + " ...";
};