import React, { Component } from 'react';
import { Card, Icon } from 'antd';
import { connect } from 'react-redux';
//import newQuest from '../../json/createQuest.json'
import { withRouter } from 'react-router-dom';
import { linksRouter } from '../../configs/baseUrl';
import { cardStyle } from '../../configs/styleVars';


const mapStateToProps = (state) => {
    return {
        state
    }
}

/** Redux get dispatch functions ****************/
/*const mapDispatchToProps = (dispatch) => ({

});*/



class CardNewQuest extends Component {
    /*  constructor(props){
          super(props)
          console.log(newQuest.questionary);
          
      }*/
    onClickCard() {
      //  console.log(this);

         this.props.history.push("/" + linksRouter.newQuestionary)
    }

    render() {

        return (

            <Card hoverable={true} onClick={() => this.onClickCard()} style={cardStyle}>
                <Icon type="plus-circle" twoToneColor="d5007f" style={{ fontSize: "55px", color: "#d5007f" }} theme="filled" />
            </Card>

        )
    }
}

export default withRouter(connect(mapStateToProps)(CardNewQuest));