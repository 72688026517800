import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, Col, Button, Divider, Tabs, Steps, Icon } from 'antd';
import { GUTTER_BASE, TABS_ELIPSIS } from '../../../configs/layoutVars';
import * as styleVars from '../../../configs/styleVars';
import { sendQuestionary } from '../../../actions/previewQuestionaryActionCreator';
import { withRouter } from 'react-router-dom';
import * as uiTexts from '../../../configs/uiTexts';
import QuestConfigBuilder from './QuestConfigBuilder';
import { truncate } from '../../../helpers/helpers';

const TabPane = Tabs.TabPane;
const Step = Steps.Step;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    sendQuestionary: (questionary, token) => dispatch(sendQuestionary(questionary, token))
});



class QuestionsConfiguration extends Component {

    builder = (sections, editionEstatus) => {
        return (sections.map((section, index) => {

            return (
                <TabPane
                    tab={truncate(TABS_ELIPSIS, section.title)}
                    style={styleVars.tabs}
                    key={index}

                >
                    <QuestConfigBuilder section={section.idSection}  />

                </TabPane>
            )
        })
        );
    }
    //
    //editionEstatus={editionEstatus} section={section.idSection} questions={section.questions}


    saveQuestionaryHandler = () => {
        this.props.sendQuestionary(this.props.questionary.questionary, this.props.credentials.token)
    }

    render() {
        return (
            <Card>
                <h3>{uiTexts.CONFIG_TITLE}</h3>
                <Card style={{ border: "none" }}>
                    <Row gutter={GUTTER_BASE}>

                        <Col offset={2} span={19}>
                            <Steps>
                                <Step status="finish" icon={<Icon type="folder" />} title={"Sección"} description={uiTexts.CONFIG_STEP_1} />
                                <Step status="finish" icon={<Icon type="check-circle" />} title={"Respuesta"} description={uiTexts.CONFIG_STEP_2} />
                                <Step status="finish" icon={<Icon type="double-right" />} title={"Redireccionamiento"} description={uiTexts.CONFIG_STEP_3} />
                            </Steps>
                        </Col>
                    </Row>
                    <p style={{marginTop:15, color:styleVars.MAIN_COLOR}}><b>Nota: </b>{uiTexts.CONFIG_NOTE}</p>
                    <Divider></Divider>
                </Card>

                <Tabs
                    hideAdd
                    type="editable-card"
                    onEdit={this.onEdit}
                >
                    {this.builder(this.props.questionary.questionary.sections, this.props.editionEstatus)}
                </Tabs>

                <Row style={styleVars.verticalMargin45} gutter={GUTTER_BASE}>
                    <Col style={{ textAlign: "right" }} span={24}>

                        <Button
                            type={"primary"}
                            onClick={() => this.props.changeTab("1")}
                            loading={this.props.questionary.isSending}
                            disabled={!this.props.questionary.questionary.sections[0].questions.length > 0}
                            icon="double-left"
                        >{"Regresar a cuestionario"}
                        </Button>
                    </Col>

                </Row>
            </Card>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsConfiguration));