/*Global labels */
export const ACCEPT = "Aceptar";
export const VERSIONFRONT = "4.1.0 R.15 14/06/2023";
export const GUIA = "Descargar Guía";
export const LOADING_MESSAGE = "Cargando . . .";
export const REQUIRED_MESSAGE = "Campo requerido";
export const SELECT_INSTRUCTION = "Selecciona una opción";
export const DELETE_LABEL = "Eliminar";
export const DISCARD_CHANGES = "Descartar cambios";
export const SAVE_LABEL = "Guardar";
export const CANCEL_LABEL = "Cancelar";
export const EDIT_LABEL = "Editar";
export const ADD_LABEL = "Agregar";
export const PREVIEW_LABEL = "Vista previa";
export const REQUIRED_LABEL = "";
export const MARK_REQUIRED_LABEL = "Marcar como requerido";
export const ADD_MAIL = "Agregar Correo";
export const SEARCH = "Buscar";
export const RESTART = "Reiniciar";
export const RESET_SEARCH = "Borrar filtro";
export const SEARCH_PLACEHOLDER = "Dato a buscar";
export const ADD_NEW_SECTION = "Agregar nueva sección";
export const REQUIERED_QUESTION_LABEL = "Pregunta obligatoria";
export const YES_LABEL = "Si";
export const NOT_LABEL = "No";
export const EMPY_OPTIONS_COMPONENT = "Ingresa una opción";
export const URL_MODAL_TITLE = "URL pública";
export const TEXT_URL_MODAL = "La url pública del cuestionario, es la siguiente:";
export const DOWNLOAD_LABEL = "Revisa tus resultados"
export const DOWNLOAD_ANS_LABEL = "Revisa tus  respuestas"

/*Success messages */

export const PUBLISHED_SUCCESS_MSG = "Publicación enviada correctamente";
export const CLONE_SUCCESS_MSG = "Se duplicó el cuestionario correctamente";
export const DELETE_SUCCESS_MSG = "Se eliminó el cuestionario correctamente";
export const DELETE_QUESTION_SUCCESS_MSG = "Se eliminó la respuesta correctamente";
export const SEND_QUEST_SUCCESS_MSG = "El cuestionario se envió correctamente";
export const SEND_QUEST_SUCCESS_MSG_CALIFICACION = "Tu calificación es de: ";
export const SEND_PARTIAL_QUEST_SUCCESS_MSG = "Se han guardado avances";


/*Error messages */
export const FORBBIDEN_PAGE = "No se cuenta con los permisos para el sistema. Comunícate con el CAU."
export const GENERIC_ERROR = "El sistema no esta disponible. Comunícate con el CAU.";
export const GET_INFO_ERROR = "El servidor no esta disponible. Comunícate con el CAU.";
export const GROUP_ERROR = "El usuario ingresado no pertenece al grupo al que esta dirigido este cuestionario";
export const OFFLINE_ERROR = "Verifica tu conexión a internet e inténtalo de nuevo.";
export const SAVE_ERROR = "No se ha podido enviar. Comunícate con el CAU.";
export const TOKEN_ERROR = "Tu sesión ha caducado, ingresa nuevamente al sistema";
export const PERIOD_ERROR = "Actualmente este cuestionario no acepta respuestas";
export const PERIOD_MESSAGE = "La solicitud de respuesta al cuestionario se encuentra fuera del periodo."


/**Login labels */
export const WRONG_MAIL_FORMAT = "Ingresa un correo válido";
export const USER_REQUIRED = "Usuario requerido";
export const PASSWORD_REQUIRED = "Contraseña requerida";
export const USER_LABEL = "Usuario";
export const PASSWORD_LABEL = "Contraseña";
export const USER_PLACEHOLDER = "Ingresa tu usuario";
export const PASSWORD_PLACEHOLDER = "Ingresa tu contraseña";

export const FAILED_MESSAGE = "Verifica tu usuario y contraseña";
export const FAILED_FETCH_MESSAGE = "Verifica tu conexión a internet e inténtalo de nuevo";
export const FAILED_SERVER = "Ocurrió un error, intentar de nuevo";
export const FAILED_ACTIVE_SESSION = "Ya existe una sesión activa";
export const FAILED_BLOCKED_IP = "Se alcanzó el número máximo de intentos, intenta ingresar más tarde.";
export const LOGIN_BUTTON = "Iniciar sesión";
export const LOGOUT_BUTTON = "Cerrar sesión";
export const HELP = "¿Necesitas ayuda?";

export const PERSONAL_DATA = "Protección de datos personales";
export const ACTIVE_SESION_MESSAGE="Se recomienda dejar pasar 10 minutos y actualizar esta página para iniciar sesión nuevamente.";


/*PreviewQuestionary labels  */
export const H1PREVIEW_QUESTIONARY = "Vista previa cuestionario";
export const INCOMPLETE_FORM = "Existen campos incompletos";



/*PreviewQuestionary labels  */
export const SUCCSESS_MESSAGE = "El cuestionario, se ha enviado correctamente";
export const MSG_UNFINISHED_OR_ALREADY_FINISHED = "Sesión terminada correctamente";
export const FAILED_SEND = "Hubo un problema al guardar el cuestionario, favor de intentar más tarde";

/*BuilderQuestionary labels */
export const QUESTIONARY_NAME = "Título o nombre del cuestionario";
export const QUESTIONARY_NAME_EJ = "Ej. Título o nombre del cuestionario";
export const QUESTIONARY_DESCRIPTION = "Título o nombre del cuestionario";
export const QUESTIONARY_DESCRIPTION_EJ = "Ej. Título o nombre del cuestionario";
export const QUESTIONARY_LEGEND = "Instrucción general (opcional)";
export const QUESTIONARY_LEGEND_EJ = "Ej. Instrucción general (opcional)";


//**********************   Edit questionary labels **********************************/

/**Labels for question title builder */
export const QUESTION_TITLE_LABEL = "Ingresa la pregunta del reactivo";
export const QUESTION_TITLE_LABEL_PLACEHOLDER = "Ej. ¿Tienes automóvil propio?";

/**Labels for input evaluation */
export const INPUT_LABEL_REACTIVE_VALUE = "Valor del reactivo";

/**Labels for config question*/
export const CONFIG_TITLE = "Pasos para condicionar preguntas:";
export const CONFIG_STEP_1 = "Selecciona la pregunta a condicionar.";
export const CONFIG_STEP_2 = "Marca la respuesta que redirecciona a otra pregunta.";
export const CONFIG_STEP_3 = "Elige a que pregunta debe ir.";
export const CONFIG_NOTE = "Sólo se muestran las preguntas que pueden ser configuradas.";
export const CONFIG_QUEST_LIST_LABEL = "Listado de preguntas";
export const CONFIG_QUEST_DELETE_CONFIRM = "Al eliminar una pregunta se borrará la configuración existente de todas las preguntas";
export const INPUT_LABEL_PLACEHOLDER_PONDERACION = "Ej. 10";

/**Labels for input builder */
export const INPUT_LABEL_BUILDER = "Ingresa la pregunta de argumento, Ej. ¿Por qué tu elección?";
export const INPUT_LABEL_PLACEHOLDER = "Ej. ¿Por qué tu elección? o Da una justificación";


/**Labels for rate */
export const RATE_LABEL = "Calificación";
export const LABEL_TO_SHOW = "Etiqueta mostrar en calificación";

/**Labels for datePicker */
export const DATEPICK_LABEL_TO_DEFAULT = "Selecciona la fecha predeterminada";


/**Labels for select */
export const SELECT_EDIT_LABEL = "Agrega o elimina los elementos del combo";


//**********************   New questionary texts **********************************/

export const NEW_QUESTIONARY_TYPE1 = "ENCUESTA";
export const NEW_QUESTIONARY_TYPE2 = "EVALUACION";
export const NEW_QUESTIONARY_TYPE3 = "ENCUESTA_MULTIPLE";
export const NEW_QUESTIONARY_TYPE4 = "EVALUACION_MULTIPLE";
export const NEW_QUESTIONARY_MODAL_TITLE = "Creación de cuestionario";
export const NEW_QUESTIONARY_TITLE_LABEL = "Título del cuestionario";
export const NEW_QUESTIONARY_TITLE_PLACEHOLDER = "Título del cuestionario";
export const NEW_QUESTIONARY_SECTION_LABEL = "Título de la sección";
export const NEW_QUESTIONARY_SECTION_PLACEHOLDER = "Título de la sección";
export const NEW_QUESTIONARY_RELATEDINFO_LABEL = "Agregar descripción (opcional)";
export const NEW_QUESTIONARY_RELATEDINFO_PLACEHOLDER = "Agregar descripción (opcional)";
export const NEW_QUESTIONARY_MULTIPLE = "Múltiples intentos.";
export const DELETE_TEMP_QUESTIONARY = "Se eliminará cualquier cambio no guardado.";
export const DELETE_TEMP_QUESTIONARY_BUTTON = "Descartar cambios.";
export const DELETE_QUESTIONARY_LABEL = "Una vez eliminado el cuestionario no se podrá recuperar, ¿Deseas continuar?.";
export const SECTION_INSTRUCTIONS = "Ingresa instrucciones de la sección (opcional).";
export const SECTION_INSTRUCTIONS_PLACEHOLDER = "Ej. Ingresa tus datos personales";

//**********************   New question labels evaluaciones **********************************/

export const NEW_QUESTIONARY_TITLE_TIPO = "Tipo de cuestionario";
export const NEW_QUESTIONARY_RADIO_ENCUESTA = "Encuesta";
export const NEW_QUESTIONARY_RADIO_EVALUACION = "Evaluación";
export const NEW_QUESTION_TITLE_OPTIONS_EVALUACION = "Opciones del reactivo";
export const NEW_QUESTION_TITLE_OPTIONS_SUB_EVALUACION = "Selecciona la opción correcta del reactivo.";
export const INPUT_LABEL_BUILDER_EVALUACION = "Ingresa las opciones del reactivo";

//**********************   New question labels **********************************/
export const NEW_QUEST_LABEL = "Nueva pregunta";
export const CHECKBOX_LABEL_NQ = "Selección múltiple";
export const RADIO_LABEL_NQ = "Opción múltiple";
export const INPUT_LABEL_NQ = "Pregunta abierta";
export const RATE_LABEL_NQ = "Nivel de satisfacción";
export const DATEPICKER_LABEL_NQ = "Fecha";
export const TIMEPICKER_LABEL_NQ = "Hora";
export const DATERANGE_LABEL_NQ = "Rango de fechas";
export const SELECT_LABEL_NQ = "Combo desplegable";
export const SLIDER_LABEL_NQ = "Barra de Medición";
export const NEW_QUEST_BTN = "Agregar nueva pregunta";

/***************************Period Range publish **************************************/
export const PUBLISHED = "Enviados a publicar";
export const NOT_PUBLISHED = "No enviados a publicar";
export const SELECT_TIME_TXT = "Selecciona fecha";
export const PUBLISH_RANGE_TXT = "Período de publicación";
export const PUBLISH_INITIAL_TXT = "Fecha inicial";
export const PUBLISH_END_TXT = "Fecha final";
export const PUBLISH_DURABILITY_TXT = "Duración de sesión ";
export const PUBLISH_TIME_TXT = "minutos";
export const QUESTIONARY_APPLY_TXT = "Aplicar cuestionario";
export const SUBGROUP = "Subgrupo"
export const FIN_PERIODO_PUBLICACION = "La fecha de publicación del cuestionario ha caducado";
export const PUBLISH_IMAGE_TXT = "Imagen para mostrar en la finalización del cuestionario";
export const UPLOAD_FILE_SEND_PUBLICACION = "Cargar una imagen";
export const HISTORIAL_DESCARGA_TXT = "Mostrar el historial";
export const HISTORIAL_DESCARGA_NO = "No mostrar";
export const HISTORIAL_DESCARGA_UNO = "El último intento";
export const HISTORIAL_DESCARGA_TODOS = "Todos los intentos";

/***************************mail confipublish  **************************************/
export const PRESENTATION_TXT = "A quien corresponda:";
export const DESCR_TXT_1 = "Por este medio se notifica la publicación del Cuestionario ";
export const DESCR_TXT_2 = " del Área ";
export const DESCR_TXT_3 = ". Dicha publicación se hará en:";
export const PLACEHOLDER_LINK = "Indica en donde se deberá publicar el cuestionario";
export const FINAL_TEXT = "Fecha de Publicación:";

/***************************editing periods **************************************/

export const EDIT_PERIOD_EDIT_TXT = "Edición de período de publicación"
export const EDIT_PERIOD_INST = "Selecciona el período que deseas editar"


/*************************** inbox Answers **************************************/

export const INITIAL_DATE_INBOX = PUBLISH_INITIAL_TXT;
export const FINAL_DATE_INBOX = PUBLISH_END_TXT;
export const PERIOD_LABEL = "Periodo";
export const SEE_ANS_LABEL = "Ver respuestas";