import * as ActionTypes from '../actions/actions';

export const publishRed = (state = {
    isLoading: null,
    payload: null,
    message: null,
    openModal: null,
    succesSend: null,
    typeMessage: null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_DATA_PUBLISH:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }

        case ActionTypes.FAILED_FETCH_DATA_PUBLISH:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }

        case ActionTypes.SUCCESS_FETCH_DATA_PUBLISH:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }

        case ActionTypes.FAILED_PUBLISH:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }

        case ActionTypes.SUCCESS_PUBLISH:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }

        case ActionTypes.PUBLISHING:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }

        case ActionTypes.CLOSE_PUBLISH_WINDOW:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage, openModal: action.openModal, succesSend: action.succesSend }


        default:
            return state;
    }
}


