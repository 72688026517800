import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cleanPublicHome, cleanListQuestionary } from '../../actions/homeActionsCreator';
import { notification } from 'antd';
import { errorSelector } from '../../actions/errorSelector';
import { linksRouter } from '../../configs/baseUrl';
import { ERROR_TYPE } from '../../configs/galeryTypes';
import { resetValuesQuestionary } from '../../actions/previewQuestionaryActionCreator';
import { closePublishWindow } from '../../actions/publishActionsCreator';
import { resetValuesInboxAns } from '../../actions/inboxAnsActionsCreator'
import { closePreviewAnsWindow } from '../../actions/previewAnsActionCreator'

const key = 'globalNotification';


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary,
        listQuestionary: state.listQuestionary,
        publishData: state.publishData,
        publicHome: state.publicHome,
        inboxAns: state.inboxAns,
        periodData: state.periodData,
        previewAns:state.previewAns
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    cleanPublicHome: () => dispatch(cleanPublicHome()),
    cleanListQuestionary: () => dispatch(cleanListQuestionary()),
    resetValuesQuestionary: () => dispatch(resetValuesQuestionary()),
    resetValuesInboxAns: () => dispatch(resetValuesInboxAns()),
    closePublishWindow: () => dispatch(closePublishWindow()),
    closePreviewAnsWindow: () => dispatch(closePreviewAnsWindow())
});

const emitNotification = (msgObj) => {

    notification[msgObj.type]({
        key,
        duration: msgObj.duration,
        message: msgObj.title,
        description: msgObj.message,
        style: {
            minHeight: 100,
            backgroundColor: "#f1f0f0",
            right: 100
        }
    });
}


class ErrorControler extends Component {

    errorController = (typeMessage) => {
        console.log("typeMessage:=>", typeMessage);
        switch (typeMessage) {
            case ERROR_TYPE.TOKEN:
                console.log("entre en token");
                emitNotification(errorSelector(typeMessage));
                this.props.history.replace("/"+ linksRouter.publicHome);
                break;
            case ERROR_TYPE.FORBBIDEN:
                console.log("entre en forbidden");
                emitNotification(errorSelector(typeMessage));
                this.props.history.push("/" + linksRouter.forbbidenPage);
                break;
            case ERROR_TYPE.OFFLINE:
                console.log("entre en offline");
                emitNotification(errorSelector(typeMessage));
                break;
            case ERROR_TYPE.PERIOD:
                console.log("entre en period");
                emitNotification(errorSelector(typeMessage));
                this.props.history.push("/" + linksRouter.periodPage);
                break;
            case ERROR_TYPE.GROUP:
                console.log("entre en group");
                emitNotification(errorSelector(typeMessage));
                this.props.history.push("/" + linksRouter.forbiddenGroup);
                break;
            default:
                console.log("entre en default");
                emitNotification(errorSelector(typeMessage));
                this.props.history.push("/" + linksRouter.errorPage);
                break;
        }
    }

    componentDidUpdate() {
        //publicHome error Listener
        if (this.props.publicHome.typeMessage) {
            this.errorController(this.props.publicHome.typeMessage)

        } else if (this.props.listQuestionary.typeMessage) {
            this.errorController(this.props.listQuestionary.typeMessage)
            this.props.cleanListQuestionary();

        } else if (this.props.inboxAns.typeMessage) {
            this.errorController(this.props.inboxAns.typeMessage)
            this.props.resetValuesInboxAns();

        } else if (this.props.questionary.typeMessage) {
            this.errorController(this.props.questionary.typeMessage)
            this.props.resetValuesQuestionary();

        } else if (this.props.publishData.typeMessage) {
            this.errorController(this.props.publishData.typeMessage)
            this.props.closePublishWindow();
            
        } else if (this.props.previewAns.typeMessage) {
            this.errorController(this.props.previewAns.typeMessage)
            this.props.closePreviewAnsWindow();
        }
    }

    render() {
        return (
            null
        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorControler))
