import * as ActionTypes from './actions';
import { UrlUserQuest, UrlSaveParcial, UrlSaveParcialEvaluacion, UrlSaveFinal, UrlSaveFinalEvaluacion, UrlUserQuestBD } from '../configs/baseUrl';
//import { cloneObject, setDisableStatusIntoQuest } from '../helpers/helpers';
import { loadingQuestionary, failureFetchQuestionary, processDisabled, sendingQuestionary, successSendQuestionary, failureSendQuestionary } from './previewQuestionaryActionCreator';
import { SEND_PARTIAL_QUEST_SUCCESS_MSG, SEND_QUEST_SUCCESS_MSG} from '../configs/uiTexts';
import { sendMessage } from './messagesActionCreator';
import { NOTIF_TYPE } from '../configs/galeryTypes';
import { errorStatusType } from './errorSelector';





/**
 * Functions to GET questionary to preview
 */
export const fetchQuestionaryToAns = (token, idCuestionario) => (dispatch) => {
    //console.log("token", token.accessToken);
    //console.log("idCuestionario", idCuestionario);

    dispatch(loadingQuestionary());
    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") +  UrlUserQuest,

        {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.accessToken
            },

            body: JSON.stringify({ "hashCuestionario": idCuestionario }),
            redirect: "manual"
        }
    )
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                //var error = new Error('Error ' + response.status + ': ' + response.statusText);
                //error.response = response;
                //console.log(error.message);
                let errorObj  = errorStatusType(response.status)
                errorObj.response = response;
                console.log(errorObj);
                //throw error;
                throw errorObj;
            }
        }
        , error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        }
        )
        .then(response => response.json())
        .then(questionary => dispatch(processDisabled(questionary)))
        .catch(error => dispatch(failureFetchQuestionary(error.message)));
}


/**
 * Functions to SAVE questionary
 */

export const saveParcial = (questionaryTosend, token) => (dispatch) => {
    dispatch(sendingQuestionary());
    const questionary = { "header": questionaryTosend.header, "sections": questionaryTosend.sections }

    const fetchParams = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.accessToken
        },
        body: JSON.stringify({ questionary }),
        redirect: "manual"
    }


    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlSaveParcial, fetchParams)
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })

        .then(() => {
            dispatch(successSendProgressQuestionary(questionaryTosend));
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", SEND_PARTIAL_QUEST_SUCCESS_MSG));
        }
        )
        .catch(error => dispatch(failureSendQuestionary(error.message, questionary)));
}
export const saveParcialEvaluacion = (questionaryTosend, token) => (dispatch) => {
    dispatch(sendingQuestionary());
    const questionary = { "header": questionaryTosend.header, "sections": questionaryTosend.sections }

    const fetchParams = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.accessToken
        },
        body: JSON.stringify({ questionary }),
        redirect: "manual"
    }


    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlSaveParcialEvaluacion, fetchParams)
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })

        .then(() => {
            dispatch(successSendProgressQuestionary(questionaryTosend));
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", SEND_PARTIAL_QUEST_SUCCESS_MSG));
        }
        )
        .catch(error => dispatch(failureSendQuestionary(error.message, questionary)));
}


export const successSendProgressQuestionary = (questionary) => {
    //console.log(questionary);

    return ({
        isSending: false,
        message: SEND_PARTIAL_QUEST_SUCCESS_MSG,
        type: ActionTypes.SUCCESS_SEND_PROGRESS_QUESTIONARY,
        succesSend: true,
        payload: questionary
    })
}





export const sendFinal = (questionaryTosend, token) => (dispatch) => {
    dispatch(sendingQuestionary());
    const questionary = { "header": questionaryTosend.header, "sections": questionaryTosend.sections }

    const fetchParams = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.accessToken
        },
        body: JSON.stringify({ questionary }),
        redirect: "manual"
    }


    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlSaveFinal, fetchParams)
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })

        .then(() => {
            dispatch(successSendQuestionary(questionaryTosend.header.id));
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", SEND_QUEST_SUCCESS_MSG));
        })
        .catch(error => dispatch(failureSendQuestionary(error.message, questionary)));
}


export const sendFinalEvaluacion = (questionaryTosend, token) => (dispatch) => {
    dispatch(sendingQuestionary());
    const questionary = { "header": questionaryTosend.header, "sections": questionaryTosend.sections }

    const fetchParams = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.accessToken
        },
        body: JSON.stringify({ questionary }),
        redirect: "manual"
    }


    return fetch(localStorage.getItem("SERVER_CUESTIONARIOS") +  UrlSaveFinalEvaluacion, fetchParams)
        .then(response => {

            if (response.ok) {
                return response;
            } else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            //console.log("aqui2")
            var errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(respuesta => {
            dispatch(successSendQuestionary(questionaryTosend.header.id));
            localStorage.removeItem('correo');
            localStorage.removeItem('token');
            dispatch(sendMessage(NOTIF_TYPE.succes, "Realizado", respuesta.calificacion));
        })
        .catch(error => dispatch(failureSendQuestionary(error.message, questionary)));
}