import * as ActionTypes from '../actions/actions';

export const downloadRed = (state = {
    isLoading: null,
    payload: null,
    message: null,

}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_DATA_DOWNLOAD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message }

        case ActionTypes.FAILED_FETCH_DATA_DOWNLOAD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message }

        case ActionTypes.SUCCESS_FETCH_DATA_DOWNLOAD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message }

        case ActionTypes.CLEAN_FETCH_DATA_DOWNLOAD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message }

        default:
            return state;
    }
}


 