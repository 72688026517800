import React, { Component } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import CardQuest from './CardQuest';
import * as layoutVars from '../../configs/layoutVars';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        state
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({

});



const cardQuestBuilder = (cards, actions) => {

   /* console.log("cards", cards);
    console.log("actions", actions);*/

    var cardsQuestArray = cards.map((element, index) => {
        return <CardQuest cardParams={element} actions={actions} key={index} isPublished={true} />;
    })

    //return null;
    return cardsQuestArray;

}

class TabPublished extends Component {
   /* constructor(props) {
        super(props)
        console.log(this.props);
    }*/
    render() {

        return (
            <Row gutter={layoutVars.GUTTER_BASE_LISTQUEST}>
                <Col span={24}>
                    {cardQuestBuilder(this.props.cards, this.props.actions)}
                </Col>
            </Row>

        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabPublished));
