import axios from "axios";



function loadData(){
     axios
    .get(window.location.origin + "/JsonHelper/url.json")
    .then((response) => {
      
      if (response.status === 200) {
        //console.log("petición")
       
        localStorage.setItem('SERVER_CUESTIONARIOS', response.data.urlServer);
        //localStorage.setItem('APACHE', response.data.urlApache);
      }});
}

 

//const test = async ()=>{await loadData()}
//console.log(test);

loadData();

//var UrlServer=localStorage.getItem('SERVER_CUESTIONARIOS'); //balanceador local    pruebas /desa
//var apache=localStorage.getItem('APACHE');



//const UrlServer="https://pru-cuestionarios-inst.ine.mx"; //balanceador pruebas    apunta a la base de PRUEBAS
//const UrlServer="https://pruebas-sg.ine.mx"; //balanceador pruebas    apunta a la base de CAPA
//const UrlServer="https://seguridad-sg.ine.mx"; //balanceador seguridad   apunta a la base de Pruebas
//const UrlServer="https://cuestionarios-app.ine.mx"; //balanceador produccion

//export const urlApache = "http://localhost:3000/#/";  // balanceador local
//export const urlApache = apache + "/#/";  // balanceador local
//console.log("ruta apache: ", urlApache);

//export const urlApache = "https://rep-cuestionarios-prueba.ine.mx/#/";  // balanceador pruebas
//export const urlApache = "https://rep-cuestionarios-seg.ine.mx/#/";  // balanceador seguridad
//export const urlApache = "https://cuestionarios.ine.mx/#/"; //Balanceador de produccion

export const UrlPublicHome = "/cuestionarios/home";  
export const UrlLogin = "/cuestionarios/api/auth/";
export const UrlLoginBD = "/cuestionarios/api/loginBD/";
export const UrlPreview = "/cuestionarios/api/cuestionario/consulta";
export const UrlListQuest = "/cuestionarios/api/bandeja/consulta";
export const UrlLogout = "/cuestionarios/api/logout/";

export const UrlGetPublishData = "/cuestionarios/api/cuestionario/obtenerDatosPublicacion";
export const UrlPublishQuestionary = "/cuestionarios/api/cuestionario/publica";
export const UrlSaveImagePublish = "/cuestionarios/api/cuestionario/guardaImagen";
export const UrlShowImagePublish = "/cuestionarios/api/cuestionario/mostrarImagen";

export const UrlGetListPeriod = "/cuestionarios/api/cuestionario/consultaPeriodos";
export const UrlUpdatePeriod = "/cuestionarios/api/cuestionario/modificaPeriodo";

export const UrlInboxAnswers = "/cuestionarios/api/bandeja/respuestas";
export const UrlPreviewAns = "/cuestionarios/api/cuestionario/rusuario";

export const UrlSave = "/cuestionarios/api/cuestionario/guarda"
export const UrlSaveParcial = "/cuestionarios/api/cuestionario/guardarua"
export const UrlSaveParcialEvaluacion = "/cuestionarios/api/evaluacion/guardarua"
export const UrlSaveFinal = "/cuestionarios/api/cuestionario/guardaruf"
export const UrlSaveFinalEvaluacion = "/cuestionarios/api/evaluacion/guardar"
export const UrlUserQuest = "/cuestionarios/api/cuestionario/usuario/solicitar"
export const UrlDownloadAnswers = "/cuestionarios/api/cuestionario/usuario/descargaRespuestas"

export const UrlCloneQuest = "/cuestionarios/api/cuestionario/duplica";
export const UrlDeleteQuest = "/cuestionarios/api/bandeja/elimina";
export const UrlDeleteQuestAns = "/cuestionarios/api/bandeja/respuestas/elimina";

export const UrlDownloadData = "/cuestionarios/api/bandeja/respuestas/descarga";


//export const baseUrlServer = "http://localhost:3000/auth/login";
//export const baseUrl = "http://localhost:8080/";


/**
 * flows (urls) to navigate
 */

export const linksRouter = {
    login: "login",
    newQuestionary: "creaCuestionario",
    successSend: "envioCorrecto",
    previewQuestionary: "consultaCuestionario",
    questionaryList: "misCuestionarios",
    publicHome: "inicio",
    editQuestionary: "editaCuestionario",
    inboxAnswers: "bandejaRespuestas",
    responseUsers: "responderCuestionario",
    successAnsUser: "envioCuestCorrecto",
    forbbidenPage: "accesoRestringido",
    errorPage: "error",
    thanksPage:"graciasParticipar",
    sinAcceso: "sinAcceso",
    periodPage: "fueraDePeriodo",
    forbiddenGroup: "grupoRestringido"
}
