import React, { Component } from 'react';
import { Divider, Button, Row, Col, Radio, DatePicker, InputNumber } from 'antd';
import { connect } from 'react-redux';
import * as uiTexts from '../../configs/uiTexts';
import { LoadingComp } from '../genericComponents/LoadingComp';
import { GUTTER_BASE } from '../../configs/layoutVars';
import { cloneObject } from '../../helpers/helpers';
import moment from 'moment';
import { verticalMargin15, verticalRadioGAlign } from '../../configs/styleVars';
import { saveEditedPeriod } from '../../actions/editPeriodActionsCreator'

//const Step = Steps.Step;
const RadioGroup = Radio.Group;
const DATEFORMAT = 'DD/MM/YYYY HH:mm';

/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        periodData: state.periodData
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    saveEditedPeriod: (token, data) => dispatch(saveEditedPeriod(token, data)),
});


const radioBuilder = (data) => {
    return (
        data.map((item, index) => {
            return <Radio style={verticalRadioGAlign} key={index} value={index}>{item.label}</Radio>;
        })
    );
}


class EditForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
            initialRange: null,
            finalRange: null, 
            tiempoSesion : null
        }
    }

    onChangePeriodSelected = (e, data) => {
        //console.log(e.target.value);

        this.setState({
            selected: e.target.value,
            initialRange: data[e.target.value].fechaHoraInicio,
            finalRange: data[e.target.value].fechaHoraTermino,
            idPeriodo: data[e.target.value].id, 
            tiempoSesion: data[e.target.value].tiempoSesion
        }, () => {
           // console.log(this.state.tiempoSesion);
        });
    }



    disabledStartDate = (startValue) => {
        //console.log(startValue);
        startValue = moment(startValue, DATEFORMAT)
        const finalRange = moment(this.state.finalRange, DATEFORMAT);

        if (!startValue || !finalRange) {
            return false;
        }


        return startValue.valueOf() > finalRange.valueOf();
    }

    disabledEndDate = (endValue) => {
        endValue = moment(endValue, DATEFORMAT)
        const initialRange = moment(this.state.initialRange, DATEFORMAT);
        if (!endValue || !initialRange) {
            return false;
        }
        return endValue.valueOf() <= initialRange.valueOf();
    }


    preProcessdata = () => {
        let newData = cloneObject(this.props.periodData.payload);

        if (this.state.tiempoSesion === '0'){           
            this.state.tiempoSesion = 15;
        }
        
        newData.periodo = {
            "idPeriodo": this.state.idPeriodo,
            "fechaHoraInicio": this.state.initialRange,
            "fechaHoraTermino": this.state.finalRange, 
            "tiempoSesion": this.state.tiempoSesion
        }

        this.props.saveEditedPeriod(this.props.credentials.token, newData);
    }

    render() {

        if (this.props.periodData.isLoading)
            return <LoadingComp></LoadingComp>

        return (
            <React.Fragment>
                <Row gutter={GUTTER_BASE}>

                    <h3 style={verticalMargin15}>{uiTexts.EDIT_PERIOD_INST}</h3>

                    <Col span={24} lg={6} >
                        <RadioGroup value={this.state.selected} onChange={(e) => this.onChangePeriodSelected(e, this.props.periodData.payload.periodos)}>
                            {radioBuilder(this.props.periodData.payload.periodos)}
                        </RadioGroup>
                    </Col>

                    <Col span={1}>
                        <Divider type={"vertical"} className="vertical-divider-8em"></Divider>
                    </Col>

                    <Col span={23} lg={17}>
                        <Col md={24} lg={12}>
                            {uiTexts.PUBLISH_INITIAL_TXT}
                            <br></br>
                            <DatePicker
                                disabledDate={this.disabledStartDate}
                                allowClear={false}
                                disabled={this.state.initialRange === null}
                                value={this.state.initialRange !== null ? moment(this.state.initialRange, DATEFORMAT) : null}
                                showTime
                                format={DATEFORMAT}
                                placeholder={uiTexts.SELECT_TIME_TXT}
                                onChange={(e) => this.setState({ initialRange: e !== null ? e.format(DATEFORMAT) : null })}
                            />
                        </Col>
                        <Col md={24} lg={12}>
                            {uiTexts.PUBLISH_END_TXT}
                            <br></br>
                            <DatePicker
                                disabledDate={this.disabledEndDate}
                                allowClear={false}
                                disabled={this.state.finalRange === null}
                                value={this.state.finalRange !== null ? moment(this.state.finalRange, DATEFORMAT) : null}
                                showTime
                                format={DATEFORMAT}
                                placeholder={uiTexts.SELECT_TIME_TXT}
                                onChange={(e) => this.setState({ finalRange: e !== null ? e.format(DATEFORMAT) : null })}

                            />
                        </Col>
                        <Col md={24} lg={12} style={{ marginTop: 15 }}>
                            {uiTexts.PUBLISH_DURABILITY_TXT}
                            <br></br>
                            <InputNumber min={15} max={240} type="number"
                                value={this.state.tiempoSesion} disabled={this.state.tiempoSesion === null}
                                onChange={(e) => this.setState({ tiempoSesion: e})}/>
                                &nbsp;{uiTexts.PUBLISH_TIME_TXT}
                        </Col>
                    </Col>
                </Row>

                <div style={{ textAlign: "right" }}>
                    <Divider></Divider>
                    <Button
                        icon="save"
                        style={{ marginRight: "10px" }}
                        type="primary"
                        onClick={this.preProcessdata}
                    > {uiTexts.SAVE_LABEL}
                    </Button>

                </div>

            </React.Fragment>
        )

    }
}

//export default (PublishForm);
export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
 // value={this.state.value}>