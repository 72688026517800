import * as ActionTypes from '../actions/actions';

export const messagesRed = (state = {
    showMessage: false,
    title: null,
    description: null,
    notifType: null,
}, action) => {
    switch (action.type) {

        case ActionTypes.CLEAR_MESSAGE:
            //console.log(action.payload);
            return { ...state, showMessage: action.showMessage, notifType: action.notifType, title: action.title, description: action.description }

        case ActionTypes.SEND_MESSAGE:
            //console.log(action.payload);
            return { ...state, showMessage: action.showMessage, notifType: action.notifType, title: action.title, description: action.description }

        default:
            return state;
    }
}

