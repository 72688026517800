import * as ActionTypes from '../actions/actions';
//import { linksRouter } from '../configs/baseUrl';
//console.log("action" , this.props.action)
export const Questionary = (state = {
    isSending: false,
    isLoading: null,
    message: null,
    questionary: null,
    succesSend: null,
    califSend: false,
    ruta:false
}, action) => {
 // console.log("valores de un success send", action.type, action.isSending, action.message, action.typeMessage, action.paylod  );
  //console.log("valores de un success send", state  );
    switch (action.type) {
        
  
        /**Load questionary */
        case ActionTypes.LOADING_QUESTIONARY:
            return { ...state, isLoading: action.isLoading, message: action.message, typeMessage: action.typeMessage, questionary: action.payload }

        case ActionTypes.ADD_QUESTIONARY_TO_SCREEN:
            return { ...state, isLoading: action.isLoading, message: action.message, typeMessage: action.typeMessage, questionary: action.payload }

        case ActionTypes.FAILED_LOAD_QUESTIONARY:
            return { ...state, isLoading: action.isLoading, message: action.message, typeMessage: action.typeMessage, questionary: action.payload }

        case ActionTypes.UPDATED_VALUES:
            return { ...state, isLoading: action.isLoading, message: action.message, typeMessage: action.typeMessage, questionary: action.payload}

        /**Send questionary */
        case ActionTypes.SEND_QUESTIONARY:
            return { ...state, isSending: action.isSending, message: action.message, typeMessage: action.typeMessage, questionary: action.payload }

        case ActionTypes.SENDING_QUESTIONARY:
            return { ...state, isSending: action.isSending, message: action.message, typeMessage: action.typeMessage, questionary: action.payload }

        case ActionTypes.FAILED_SEND_QUESTIONARY:
            return { ...state, isSending: action.isSending, message: action.message, typeMessage: action.typeMessage, questionary: action.payload, succesSend: false }

        case ActionTypes.SUCCESS_SEND_QUESTIONARY:
            return { ...state, isSending: action.isSending, message: action.message, typeMessage: action.typeMessage, questionary: action.payload, succesSend: true, califSend:true, idImagen : action.idImagen}
       
        case ActionTypes.SUCCESS_SEND_PROGRESS_QUESTIONARY:
            return { ...state, isSending: action.isSending, message: action.message, typeMessage: action.typeMessage, questionary: action.payload, succesSend: true  }

        case ActionTypes.RESET_VALUES:
            return { ...state, isSending: action.isSending, message: action.message, typeMessage: action.typeMessage, questionary: action.payload, succesSend: false }

        case ActionTypes.DELETE_QUESTION:
            return { ...state, isLoading: action.isLoading, message: action.message, typeMessage: action.typeMessage, questionary: action.payload }


        default:
           //console.log("entro al default x", action.type) ;
         if(action.type==="LOGOUT_FETCHING" )
         return {...state, ruta:true};
        // else if(action.type==="LOGED_OUT" )
         //return window.location = ("/"+ linksRouter.thanksPage);
            else return {...state, ruta:false};
            
    }
    
}

