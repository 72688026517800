import React from 'react';
import InputComp from '../viewQuestionary/invididualComps/InputComp';
import RadioComp from '../viewQuestionary/invididualComps/RadioComp';
import CheckBoxComp from '../viewQuestionary/invididualComps/CheckBoxComp';
import RateComp from '../viewQuestionary/invididualComps/RateComp';
import DatePickerComp from '../viewQuestionary/invididualComps/DatePickerComp';
import DateRangePickerComp from '../viewQuestionary/invididualComps/DateRangePickerComp';
import SelectComp from '../viewQuestionary/invididualComps/SelectComp';
//import SwitchComp from '../viewQuestionary/invididualComps/SwitchComp';
import TimePickerComp from '../viewQuestionary/invididualComps/TimePickerComp';
import SliderComp from '../viewQuestionary/invididualComps/SliderComp';
import * as galeryTypes from '../../configs/galeryTypes';


export const typeSelect = (props) => {
    //console.log(props.isChild);

    switch (props.isChild ? props.question.childQuestion.type : props.question.type) {
        case galeryTypes.INPUT:
            return <InputComp {...props} />

        case galeryTypes.RADIO:
            return <RadioComp {...props} />

        case galeryTypes.CHECKBOX:
            return <CheckBoxComp {...props} />

        case galeryTypes.RATE:
            return <RateComp {...props} />

        case galeryTypes.DATEPICKER:
            return <DatePickerComp {...props} />

        case galeryTypes.DATERANGE_PICKER:
            return <DateRangePickerComp {...props} />

        case galeryTypes.SELECT:
            return <SelectComp {...props} />

/*        case galeryTypes.SWITCH:
            return <SwitchComp {...props} />
*/
        case galeryTypes.TIMEPICKER:
            return <TimePickerComp {...props} />

        case galeryTypes.SLIDER:
            return <SliderComp {...props} />

        default:
            return null;

    }
}


export const ComponentCreatorComp = (props) => {
    //console.log('props', props);


    return (
        typeSelect(props)
    );
}