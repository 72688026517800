import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import ValueCalculator from './ValueCalculator';
//import { fetchPreviewAns } from '../../actions/previewAnsActionCreator';
//import { cloneObject } from '../../helpers/helpers';
import { LoadingComp } from '../genericComponents/LoadingComp';
import { GUTTER_BASE } from '../../configs/layoutVars';
import { verticalMargin30, verticalMargin15 } from '../../configs/styleVars'


/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        previewAns: state.previewAns
    }
}


const questionBuilder = (section) => {
    let questions = null;

    questions = section.questions.map(function (question, index) {
        return (
            <Row  gutter={GUTTER_BASE} key={index} style={verticalMargin15}>
                <Col span={20} offset={1}>
                    <p><b>{index + 1}. {question.title}</b></p>
                </Col>

                <Col span={20} offset={1}>
                        {ValueCalculator(question)}
                </Col>
                <Col span={4} offset={1}><Divider></Divider></Col>
            </Row>
        );

    });

    return questions;

}

const sectionBuilder = (payload) => {

    let sections = null;

    sections = payload.sections.map(function (section, index) {
        return (
            <React.Fragment key={index}>
                <Row gutter={GUTTER_BASE} key={index}>
                    <Col span={20} offset={1}>
                        <h2>Sección {index + 1}. {section.title}</h2>
                        <h3>{section.relatedInfo}</h3>
                        <Divider style={verticalMargin30}></Divider>
                    </Col>
                </Row>
                {questionBuilder(section)}
            </React.Fragment>

        )
    });

    return sections;
}



class PreviewQuest extends Component {

    render() {

        //console.log(this.props);
        
        if (this.props.previewAns.isLoading)
            return <LoadingComp></LoadingComp>
        
        return (
            <React.Fragment>
                <Row gutter={GUTTER_BASE}>
                    <Col xs={12} md={8} >{this.props.previewAns.payload.questionary.header.fullName}</Col>
          
                </Row>
                {sectionBuilder(this.props.previewAns.payload.questionary)}
            </React.Fragment>
        )

    }
}

export default connect(mapStateToProps)(PreviewQuest);
/*
          <Col xs={6} md={4} >{"Edad:" + this.props.previewAns.payload.questionary.header.age}</Col>
                    <Col span={1}><Divider type={"vertical"}></Divider></Col>
                    <Col xs={6} md={6}  >{"Género:" + this.props.previewAns.payload.questionary.header.gender}</Col>
                    <Divider style={verticalMargin45}></Divider>
*/