import { Col, Form, Row, Card, Divider, Menu, Dropdown, Icon } from 'antd';
import React, { Component } from 'react';
import { GUTTER_BASE } from '../../configs/layoutVars';
import { ComponentCreatorComp } from '../viewQuestionary/ComponentCreatorComp';

import WindowToEditQuestion from './WindowToEditQuestion';
//import WindowToNewQuestion from './WindowToNewQuestion';
import ActionsQuestionBuilderComp from '../viewQuestionary/ActionsQuestionBuilderComp';
import * as actionsGalery from '../../actions/actions'
import * as styleVars from '../../configs/styleVars';
import * as optionsMenuNewQuest from '../../configs/optionsMenuNewQuest';
import { openWindowToCreate } from '../../actions/newQuestionActionCreator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '../../configs/baseUrl';
import *  as uitexts from '../../configs/uiTexts';
import { MAIN_COLOR } from '../../configs/styleVars'




/** Redux get State ****************/
const mapStateToProps = (state) => {    
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    openWindowToCreate: (section, action, type) => dispatch(openWindowToCreate(section, action, type))
});




/**
 * [Enable/Disable] [Edit/New]  Questions into Questionary view
 */
/**/
const enableNewEditQuestion = (editionEstatus) => {
    return <WindowToEditQuestion editionEstatus={editionEstatus} />
}

const questionsBuilder = (section, questions, url, id) => {

    // eslint-disable-next-line
    return (questions.map((question, index) => {
        // console.log(question.required);

        return (
            <Card style={styleVars.questionCard} key={index} hoverable>
                <Row gutter={GUTTER_BASE}>
                    <Col span={24}>
                        <h3> 
                            {question.required ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : ""}
                            {(parseInt(question.idQuestion, 10) + 1) + "."}
                            {question.acierto==='NO' ? <span style={{ color: "red", fontWeight: "bold" }}>{question.title} (INCORRECTO)</span> 
                            :question.acierto==='SI' ?  <span style={{ color: "#28a745", fontWeight: "bold" }}>{question.title} (CORRECTO)</span>  : ' '+question.title} 
                        </h3>
                    </Col>
                    <Col span={24}>
                        <ComponentCreatorComp question={question} section={section} isChild={false} />
                    </Col>
                    {childQuestions(question, section)}

                    {
                        //(url === ("/" + linksRouter.newQuestionary) || url === ("/" + linksRouter.editQuestionary)) &&
                        ((url === ("/" + linksRouter.newQuestionary)) || (url === "/" + linksRouter.editQuestionary + '/' + id)) &&

                        <React.Fragment>
                            <Divider></Divider>
                            <ActionsQuestionBuilderComp idQuestion={question.idQuestion} actions={[actionsGalery.EDIT_QUESTION, actionsGalery.DELETE_QUESTION]} section={section} />
                        </React.Fragment>
                    }

                </Row>
            </Card>
        )

    }));
}


const childQuestions = (question, section) => {




    if (question.childQuestion === null)
        return null;
    else {
        const arrayValues = question.value.split("|").map(String);
        return (arrayValues.map((element, index) => {

            return <Col span={24} key={index}>
                <Col span={6} key={index}>
                    <label>{question.childQuestion.title}</label>
                    <ComponentCreatorComp question={question} section={section} isChild={true} />
                </Col>
            </Col>

        }));
    }
}




class QuestionsCreatorComp extends Component {    
    
    //ToDo: Include evaluation type check condition
    //Las opciones de tipo de pregunta a mostrar dependen del tipo de Cuestionario/Evaluación en cuestión
    //questionMenuOptions =  (true) ? optionsMenuNewQuest.menuOptions : optionsMenuNewQuest.menuOptionsEvaluation;
    
    questionMenuOptions = this.props.questionary.header.type === uitexts.NEW_QUESTIONARY_TYPE1 ||
    this.props.questionary.header.type === uitexts.NEW_QUESTIONARY_TYPE3 ? 
    optionsMenuNewQuest.menuOptions : optionsMenuNewQuest.menuOptionsEvaluation;

    menuNewQuestion = (
        <Menu>
            {
                this.questionMenuOptions.map((item, index) => {
                    //console.log(item);
                    return (
                        <Menu.Item key={index} onClick={() => this.onClicMenuItem(item.type)}>
                            <Icon type={item.icon} />  {item.label}
                        </Menu.Item>
                    )
                })
            }

        </Menu>
    );

    onClicMenuItem = (type) => {
        this.props.openWindowToCreate(this.props.section, actionsGalery.ADD_NEW_QUESTION, type)

    }

    render() {

        return (
            <React.Fragment>
                {enableNewEditQuestion(this.props.editionEstatus)}
                <Form >
                    {questionsBuilder(this.props.section, this.props.questions, this.props.match.url, this.props.match.params.id)}
                </Form>
                {

                    ((this.props.match.url === ("/" + linksRouter.newQuestionary)) || (this.props.match.url === "/" + linksRouter.editQuestionary + '/' + this.props.match.params.id)) &&

                    <Card style={styleVars.questionCard} hoverable>
                        <Row gutter={GUTTER_BASE}>
                            <Dropdown overlay={this.menuNewQuestion} trigger={['click']}>
                                <span className="ant-dropdown-link">
                                    <Icon
                                        type="plus-circle" theme="outlined"
                                        style={{ fontSize: 35, display: "table-cell", verticalAlign: "middle", color: MAIN_COLOR }}
                                    />
                                    <span
                                        style={{ fontSize: 16, display: "table-cell", verticalAlign: "middle" }}
                                    >&nbsp;&nbsp; {uitexts.NEW_QUEST_BTN}
                                    </span>
                                </span>
                            </Dropdown>
                        </Row>
                    </Card>

                }

            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsCreatorComp))