import * as ActionTypes from './actions';
import * as uiTexts from '../configs/uiTexts';
import { cloneObject } from '../helpers/helpers';
import * as jsonQuery from 'json-query';
import { updateTemporalQuestion } from './updateTemporalQuestionActionCreator';

const questEmpty = {
    disabled: false,
    idQuestion: null,
    label: "",
    questionToDisable:"",
    relatedInfo: "",
    required: false,
    ponderacion: "",
    title: "",
    type: null,
    validateStatus: "",
    value: "",
    valueToShowChild: "",
    valueToDisableQuestions:"",
    visible: true,
    childQuestion: null
}

export const openWindowToEdit = (question, section, type) =>
    ({
        type: type,
        isEditorOpen: true,
        question: question,
        message: null,
        section: section,
        component: null
    })

export const openWindowToCreate = (section, type, component, tipoCuestionario) => (dispatch) => {
    questEmpty.type = component
    dispatch(updateTemporalQuestion(questEmpty))
    dispatch(newQuestion(section, type, component))

}

const newQuestion = (section, type, component) =>
    ({
        type: type,
        isEditorOpen: true,
        question: questEmpty,
        message: null,
        section: section,
        component: component
    })


export const closeWindowToEdit = (type) => (dispatch) => {
    dispatch(updateTemporalQuestion([]));
    dispatch(openWindowToEdit([], null, type))
}

export const deleteQuestion = (type, idQuestion, section, questionary) => (dispatch) => {
    const sections = (questionary.questionary.sections);
    const sectionFound = jsonQuery('[idSection=' + section + ']', { data: sections })
    const questionFound = jsonQuery('[idQuestion=' + idQuestion + ']', { data: sectionFound.value.questions })

    let newQuestionary = cloneObject(questionary.questionary)

    newQuestionary.sections[section].questions.splice(questionFound.key, 1);
    newQuestionary.sections.forEach(section => {
        section.questions.forEach((question, index) => {
            question.idQuestion = index.toString();
            question.valueToDisableQuestions = "";
            question.questionToDisable = null;
        })
    });
    
    dispatch(addQuestionaryToScreenAfterDelete(newQuestionary))

}

export const addQuestionaryToScreenAfterDelete = (questionary) => ({
    isLoading: false,
    message: null,
    type: ActionTypes.DELETE_QUESTION,
    payload: questionary
})
