import * as ActionTypes from '../actions/actions';

export const EditQuestionRed = (state = {
    type: null,
    isEditorOpen: false,
    question: [],
    message: null,
    section: null,
    component:null
}, action) => {
    switch (action.type) {

        case ActionTypes.EDIT_QUESTION:
            //console.log(action.payload);
            return { ...state, isEditorOpen: action.isEditorOpen, question: action.question, type: action.type, section: action.section, message: action.message, component:null }

        case ActionTypes.ADD_NEW_QUESTION:
            //console.log(action.payload);
            return { ...state, isEditorOpen: action.isEditorOpen, question: action.question, type: action.type, section: action.section, message: action.message, component:action.component }

        case ActionTypes.CLOSE_EDITOR:
            //console.log(action.payload);
            return { ...state, isEditorOpen: false, question: [], type: action.type, section: null, message: null }

        default:
            return state;
    }
}

