import React, { Component } from 'react'
import { Form, DatePicker } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';
import moment from 'moment';



const FormItem = Form.Item;
const DATEFORMAT = 'DD/MM/YYYY';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});


class DatePickerComp extends Component {

    onChange = (e, questionary, section, question) => {
        const date = e !== null ? e.format('L') : "";
        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = date
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    DatePickerCreator = (question) => {

        return <DatePicker
            disabled={question.disabled}
            value={(question.value === "" || question.value === "null") ? null : moment(this.props.question.value, 'DD/MM/YYYY')}
            onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, question)}
            allowClear={!question.required}
            format={DATEFORMAT}
        />

    }


    render() {

        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem
                        colon={layoutVars.COLON}
                        required={this.props.question.required}
                    >
                        {this.DatePickerCreator(this.props.question)}
                    </FormItem>
                </React.Fragment>
            )

        return (
            <FormItem
                colon={layoutVars.COLON}
                required={this.props.question.required}
                validateStatus={this.props.question.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >
                {this.DatePickerCreator(this.props.question)}
            </FormItem>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatePickerComp));
