import React, { Component } from 'react';
import { Form, Input, Switch, Col } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import * as galeryTypes from '../../../configs/galeryTypes'
import { connect } from 'react-redux';
import { updateTemporalQuestion } from '../../../actions/updateTemporalQuestionActionCreator';



const FormItem = Form.Item;


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        tempQuestion: state.temporalQuest
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateTemporalQuestion: (tempQuestion) => dispatch(updateTemporalQuestion(tempQuestion)),
});

const { TextArea } = Input;

class InputFactoryComp extends Component {
    constructor(props) {
        super(props);

        this.state = {

            disabled: this.props.disabled,
            idQuestion: this.props.idQuestion,
            label: "",
            relatedInfo: this.props.relatedInfo,
            required: this.props.required,
            title: this.props.title,
            type: galeryTypes.INPUT,
            validateStatus: this.props.validateStatus,
            value: this.props.value,
            valueToShowChild: this.props.valueToShowChild,
            visible: this.props.visible,
            childQuestion: null,
            titleValidate: "",
            labelValidate: "",
            disableOkButton: true

        }

    }

    onChange() {
        this.setState(() => { this.props.updateTemporalQuestion(this.state) })
    }

    validaButton() {
        if (this.state.title !== ""){
            this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) })
        }else{
            this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
        }
    }

    render() {


        return (

            <React.Fragment>

                <FormItem
                    colon={layoutVars.COLON}
                    label={uiTexts.QUESTION_TITLE_LABEL}
                    required={true}
                >
                    <TextArea
                        autosize={{ minRows: 1, maxRows: 3 }}
                        maxLength={layoutVars.MAX_LENGTH_QUESION}
                        value={this.state.title}
                        placeholder={uiTexts.QUESTION_TITLE_LABEL_PLACEHOLDER}
                        onChange={(e) => {
                            this.setState({ title: e.target.value })
                        }}
                        onKeyUp={(e) => {
                            this.setState(
                                () => this.validaButton())
                        }}
                    />
                </FormItem>

                <Col span={24}>
                    <b>{uiTexts.REQUIERED_QUESTION_LABEL} &nbsp;&nbsp;&nbsp;</b> <Switch
                        checkedChildren={uiTexts.YES_LABEL}
                        unCheckedChildren={uiTexts.NOT_LABEL}
                        defaultChecked={this.state.required}
                        checked={this.state.required}
                        onChange={(checked) =>
                            this.setState({ required: checked }, () => this.onChange())}
                    />
                </Col>

            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputFactoryComp);
