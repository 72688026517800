import React, { Component } from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { closeEditingPeriodWindow } from '../../actions/editPeriodActionsCreator';
//import * as uiTexts from '../../configs/uiTexts';
import EditForm from './EditForm';




/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        periodData: state.periodData
    }
}

/** Redux get dispatch functions ****************/

const mapDispatchToProps = (dispatch) => ({
    closeEditingPeriodWindow: () => dispatch(closeEditingPeriodWindow())
});



class EditPeriod extends Component {

    render() {

        if (this.props.periodData.openModal === null || this.props.periodData.openModal === false)
            return null;

        return (

            <Modal
                maskClosable={false}
                closable={!this.props.periodData.isLoading}
                title={this.props.periodData.payload !== null && this.props.periodData.payload.tituloCuestionario}
                visible={true}//{this.state.visible}
                width={"50%"}
                style={{ maxWidth: "800px" }}
                footer={null}
                //onOk={this.handleOk}
                onCancel={this.props.closeEditingPeriodWindow}
            >
                <EditForm />
            </Modal>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPeriod);