import { Divider, Tabs, Row, Col } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import TabPublished from './TabPublished';
import TabNotPublished from './TabNotPublished';
import { resetValuesQuestionary } from '../../actions/previewQuestionaryActionCreator';
//import TabUnPublished from './TabUnPublished';
import { fetchListQuestionary, cleanListQuestionary } from '../../actions/homeActionsCreator';
import { closePublishWindow } from '../../actions/publishActionsCreator';
import * as uiTexts from '../../configs/uiTexts';
import { showLoginModal } from '../../actions/loginActionsCreator';
import { LoadingComp } from '../genericComponents/LoadingComp';
import * as actions from '../../actions/actions';
import NewPeriod from '../publish/NewPeriod';
import EditPeriod from '../publish/EditPeriod';
import { ADMIN_ROL } from '../../configs/layoutVars';
import { linksRouter } from '../../configs/baseUrl';
//import { linksRouter } from '../../configs/baseUrl';



const TabPane = Tabs.TabPane;

/** Redux get State ****************/
const mapStateToProps = (state) => {
  return {
    credentials: state.credentials,
    listQuestionary: state.listQuestionary,
    publishData: state.publishData,
    questionary: state.questionary
  }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
  fetchListQuestionary: (token) => dispatch(fetchListQuestionary(token)),
  showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
  closePublishWindow: () => dispatch(closePublishWindow()),
  resetValuesQuestionary: () => dispatch(resetValuesQuestionary()),
  cleanListQuestionary: () => dispatch(cleanListQuestionary())

});


class ListQuest extends Component {

  componentWillMount() {

    if (!this.props.listQuestionary.typeMessage) {

      if (!this.props.credentials.isAuthenticated) {
        //Al cerrar sesión, a veces pasa por aquí, entonces también cachamos el caso para que no muestre el form de login al cerrar sesión
        if (this.props.credentials.showLogin !== actions.LOGIN && this.props.credentials.showLogin !== actions.LOGING && this.props.credentials.showLogin !== actions.LOGED_OUT) {
          //console.log("ListQuest", "No autenticado", "showLogin != [LOGIN, LOGING, LOGED_OUT]");
          this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, "LOGIN", this.props.credentials.idCuestionario, null);
        }
      }
      else if (this.props.credentials.token !== null) {

        if (this.props.listQuestionary.payload)
          this.props.cleanListQuestionary();

        this.props.fetchListQuestionary(this.props.credentials.token)
      }

      if (this.props.questionary.questionary !== null)
        this.props.resetValuesQuestionary()
    }
  }

  componentDidUpdate() {
    if (!this.props.listQuestionary.typeMessage) {
      //console.log(this.props);
      if (this.props.credentials.token !== null || this.props.credentials.rol !== ADMIN_ROL) {

        if (!this.props.listQuestionary.payload && !this.props.listQuestionary.isLoading){
          this.props.fetchListQuestionary(this.props.credentials.token);
        }

        if (this.props.publishData.succesSend !== null && this.props.publishData.succesSend){
            this.props.closePublishWindow();
          	//Al cerrar el modal de publicación renderizar la pagina. 
            this.props.resetValuesQuestionary();
            this.props.history.push("/" + linksRouter.questionaryList);
        }

        if (this.props.questionary.questionary !== null){
          this.props.resetValuesQuestionary()
        }
      }
    }
  }

  render() {
    if (!this.props.credentials.isAuthenticated)
      return null;

    if (this.props.listQuestionary.isLoading || this.props.listQuestionary.isLoading === null || !this.props.listQuestionary.payload)
      return <LoadingComp />;
   

    //console.log("listQuestionary=>", this.props.listQuestionary);
    return (


      <Row>

        <NewPeriod />
        <EditPeriod />

        <Col span={20} offset={2}>
          <h1>Mis cuestionarios</h1>
          <Divider />
          <Tabs defaultActiveKey="1" >

            <TabPane tab={uiTexts.NOT_PUBLISHED} key="1">
              <TabNotPublished
                cards={this.props.listQuestionary.payload.notPublished.questionaries}
                actions={this.props.listQuestionary.payload.notPublished.actions}
              />
            </TabPane>

            {this.props.listQuestionary.payload && this.props.listQuestionary.payload.published.questionaries && this.props.listQuestionary.payload.published.questionaries.length &&
              <TabPane tab={uiTexts.PUBLISHED} key="2">
                <TabPublished
                  cards={this.props.listQuestionary.payload.published.questionaries}
                  actions={this.props.listQuestionary.payload.published.actions}
                />
              </TabPane>
            }
          </Tabs>
        </Col>
      </Row>

    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListQuest))