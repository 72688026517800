import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Col, Row, Icon, Divider, DatePicker, InputNumber, Checkbox, Select, message } from 'antd';
import { UrlSaveImagePublish } from '../../configs/baseUrl';
import axios,{post} from 'axios'; 

import * as uiTexts from '../../configs/uiTexts';
import * as styleVars from '../../configs/styleVars'
import * as layoutVars from '../../configs/layoutVars'
import moment from 'moment';

const DATEFORMAT = 'DD/MM/YYYY HH:mm';
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;

const options = [
    { label: 'Juntas Distritales ', value: 1 },
    { label: 'Juntas Locales', value: 5 },
    { label: 'Oficinas Centrales', value: 10 }
];


/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        publishData: state.publishData
    }
}

/** Redux get dispatch functions ****************/

/*const mapDispatchToProps = (dispatch) => ({
    fetchDataPublish: (token, idQuestionario) => dispatch(fetchDataPublish(token, idQuestionario)),
});*/

class DateRange extends Component {
    constructor(props) {
        super(props)
        // this.state={data:[]}
        this.state = {
            idJunta: 0,
            toShowCombo: null,
            initialRange: null,
            finalRange: null,
            timeSession: null,
            junta: [],
            subGrupo: null,
            isExternals: false, 
            nombreArchivo: null, 
            checkedUploadFile: false,
            archivoValido: false,
            showHistorial: null,
            value: null
        }

    }

    componentDidMount() {
        if (this.props.data !== null) {
            this.setState({ ...this.props.data });
        }
    }
    onChangeCheck = (e) => {

        let counter = 0;
        e.forEach(element => {
            counter += element
        });
        //console.log(counter);
        const areas = this.props.publishData.payload.areas;
        const puestos = this.props.publishData.payload.puestos;
    
        //console.log(this.props.publishData.payload);
        
        this.setState({ isExternals: false })
        switch (true) {
            case (counter === 1):
                //console.log(1, "JUNTA DISTRITAL");
                this.setState({ junta: e, idJunta: 1, toShowCombo: puestos, subGrupo: [] })
                break;
            case (counter === 5):
                // console.log(2, "JUNTA LOCAL");
                this.setState({ junta: e, idJunta: 2, toShowCombo: puestos, subGrupo: [] })

                break;
            case (counter === 6):
                // console.log(3, "JUNTA DISTRITAL Y JUNTA LOCAL");
                this.setState({ junta: e, idJunta: 3, toShowCombo: puestos, subGrupo: [] })

                break;
            case (counter === 10):
                //console.log(4, "OFICINAS CENTRALES");
                this.setState({ junta: e, idJunta: 4, toShowCombo: areas, subGrupo: [] })

                break;
            case (counter === 15):
                // console.log(5, "OFICINAS CENTRALES Y JUNTA DISTRITAL");
                this.setState({ junta: e, idJunta: 5, toShowCombo: null, subGrupo: [] })

                break;
            case (counter === 11):
                //console.log(6, "OFICINAS CENTRALES Y JUNTA LOCAL");
                this.setState({ junta: e, idJunta: 6, toShowCombo: null, subGrupo: [] })

                break;
            case (counter === 16):
                //console.log(7, "OFICINAS CENTRALES, JUNTA DISTRITAL Y JUNTA LOCAL");
                this.setState({ junta: e, idJunta: 7, toShowCombo: null, subGrupo: [] })
                break;

            default:
                this.setState({ junta: [], idJunta: 0, toShowCombo: null, subGrupo: [] })
                break;
        }
    }
    onChangeExternals = (e) => {
        const areasExternas= this.props.publishData.payload.areasExternas;
        if (e.target.checked) {
            this.setState({ isExternals: e.target.checked,junta: [25], idJunta: 8, toShowCombo: areasExternas, subGrupo: [] })
        }else{
            this.setState({ isExternals: e.target.checked,junta: [], idJunta: 0, toShowCombo: null, subGrupo: [] })

        }
    }


    optionsBuilder = () => {
        let data = this.state.toShowCombo;
        //console.log(data);
        if (data === null)
            return <Option value={0} key={0}>Todos</Option>;

        return (data.map((question, index) => {
            return <Option style={{ whiteSpace: "normal" }} value={question.id} key={question.id}>{question.label}</Option>
        })
        )

    }

    evalAllData = () => {
      //  console.log(this.state);

        let checkValidator = true

        if (this.state.idJunta !== 0)
            if (this.state.initialRange !== null)
                if (this.state.finalRange !== null)
                	if (this.state.timeSession !== null)
                		if (this.state.junta.length > 0)
                          if(this.state.showHistorial !== null)
                            if(this.state.checkedUploadFile){
                                if(this.state.archivoValido)
                                    checkValidator = false;
                            }else{
                                checkValidator = false;
                            }
                        
        if (!checkValidator) {
            this.props.setConfigData(this.state)
        }
        this.props.enableDisableNextBtn(checkValidator)
    }



    disabledStartDate = (startValue) => {
        //console.log(startValue);
        startValue = moment(startValue, DATEFORMAT)
        const finalRange = moment(this.state.finalRange, DATEFORMAT);

        if (!startValue || !finalRange) {
            return false;
        }


        return startValue.valueOf() > finalRange.valueOf();
    }

    disabledEndDate = (endValue) => {
        endValue = moment(endValue, DATEFORMAT)
        const initialRange = moment(this.state.initialRange, DATEFORMAT);
        if (!endValue || !initialRange) {
            return false;
        }
        return endValue.valueOf() <= initialRange.valueOf();
    }

    onChangeSaveImage(e){
        let files = e.target.files;         
        let banderaEnvio = true; 
        //Inicializamos la bandera de las validaciones
        this.setState({
            nombreArchivo: null
          });

        if (files !== undefined){
            banderaEnvio = this.validarArchivo(files[0]); 
             //En caso de que el archivo cumpla con las validaciones lo cargamos
        if (banderaEnvio){
        //Actualizamos la bandera 
        this.setState({
            archivoValido: true
          });    
        //Damos el formato requerido al archivo para poder enviarlo en el servicio.   
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload=(e)=>{
            var formData = new FormData();
            const metadatoArchivo = {
                idCuestionario:this.props.publishData.payload.idCuestionario,
                idPeriodo: this.props.publishData.payload.periodo.idPeriodo, 
                nombreImagen: files[0].name
               };
           // formData.append('files', e.target.result);
           formData.append('file', files[0]);
           formData.append('metadatos',new Blob([JSON.stringify(metadatoArchivo)],
                                                  {type:"application/json"}));

            return post(localStorage.getItem("SERVER_CUESTIONARIOS") + UrlSaveImagePublish, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': this.props.credentials.token.accessToken
                }
                }).then(response => {
                    console.log("al guardar",response)
                    //Cuando la carga del archivo sea correcta o se realice la carga el nombre del archivo se envia 
                    this.setState({
                        nombreArchivo: files[0].name
                      });
                    message.success(`Imagen cargada correctamente`);
                }).catch(error => {
                    console.error("al guardar error",error)
                    message.error(`Error en la carga de la imagen`);
                })
        }
      }else{
        //Actualizamos la bandera 
        this.setState({
            archivoValido: false
        }); 
      }
    }
    }

      validarArchivo = (archivo) =>{

        let formatoValido = archivo.type === 'image/jpeg' || archivo.type === 'image/jpg';
        let tamanioPermitido = archivo.size / 1024 / 1024 < 2; //2 MB
        let archivoVacio = archivo.size === 0;
        let nombreLargo = archivo.name.length > 100;
        let archNombrePermitido = this.validaNombreArchivo(archivo.name);
  
        if (!formatoValido) {
            message.error(`${archivo.name} El archivo debe estar en formato .jpeg, .jpg.`);
              return false;
        }

        if (!tamanioPermitido) {
            message.error(`${archivo.name} el archivo excede el límite de peso 2MB`);
              return false;
          }
      
          if(archivoVacio){
            message.error(`${archivo.name} error al cargar el archivo; archivo vacio`);
              return false;
          }
      
          if(nombreLargo){
            message.error(`Nombre de archivo demasiado largo`); 
              return false;
          }
      
          if(!archNombrePermitido){
            message.error(`El nombre del documento no debe contener caracteres especiales.`);
              return false;
          }

          // Si paso todas las validaciones
          if (formatoValido && tamanioPermitido && !archivoVacio && !nombreLargo && archNombrePermitido){
            return true;
          }
    }

    validaNombreArchivo=(e) =>{
        var patron = /^[0-9a-zA-Z_-\s]+.[jpg/JPG/jpeg/JPEG]+$/;
        if(e.match(patron)){
            return true;
        }else{
            return false;
        }
    }

    onChangeSendFile = e => {
     //   console.log('checked = ', e.target.checked);
        this.setState({
            checkedUploadFile: e.target.checked,
        });
        //Hay que limpiar la varibale archivo en caso de que se desactive la carga del archivo
        if (!e.target.checked){
            this.setState({
                nombreArchivo: null,
            });
        }

      };

      onChangeHistorial  = (value) => {
      //  console.log('Valor del select', value);
      this.setState({
        showHistorial: value,
    });
      };

    firstStep = () => {

        return (
            <React.Fragment>
                <Row gutter={layoutVars.GUTTER_BASE}>
                    <Col span={11}>
                        <h3>{uiTexts.PUBLISH_INITIAL_TXT}</h3>
                        <DatePicker
                            allowClear={false}
                            disabledDate={this.disabledStartDate}
                            value={this.state.initialRange !== null ? moment(this.state.initialRange, DATEFORMAT) : null}
                            showTime
                            format={DATEFORMAT}
                            placeholder={uiTexts.SELECT_TIME_TXT}
                            onChange={(e) => this.setState({ initialRange: e !== null ? e.format(DATEFORMAT) : null })}
                        //onOk={onOk}
                        />
                    </Col>
                    <Col span={1} style={styleVars.verticalMargin30}>

                        <Icon type="arrow-right" />
                    </Col>

                    <Col span={10} offset={1}>
                        <h3>{uiTexts.PUBLISH_END_TXT}</h3>

                        <DatePicker
                            allowClear={false}
                            disabledDate={this.disabledEndDate}
                            value={this.state.finalRange !== null ? moment(this.state.finalRange, DATEFORMAT) : null}
                            showTime
                            format={DATEFORMAT}
                            placeholder={uiTexts.SELECT_TIME_TXT}
                            onChange={(e) => this.setState({ finalRange: e !== null ? e.format(DATEFORMAT) : null })}
                        //onOk={onOk}
                        />
                    </Col>
                        </Row>
                        <Row gutter={layoutVars.GUTTER_BASE}>
                        <Col span={11}>
                        <h3>{uiTexts.PUBLISH_DURABILITY_TXT}</h3>

                      <InputNumber min={15} max={240} type="number"
                          value={this.state.timeSession}
                          style={{ width: "50%" }}
                      	  onChange={(e) => this.setState({ timeSession: e})}
                          placeholder="Minutos"
                       />
                    </Col>
                    <Col span={1} style={styleVars.verticalMargin30}/>
                    <Col span={10} offset={1}>
                    <h3>{uiTexts.HISTORIAL_DESCARGA_TXT}</h3>
                        <Select
                            placeholder={uiTexts.SELECT_INSTRUCTION}
                            style={{ width: "88%" }}
                            value={this.state.value !== null ? this.state.value : []}
                            onChange={(e) => this.setState({ value: e },
                                () => this.onChangeHistorial(e))}>
                                <Option value="0">{uiTexts.HISTORIAL_DESCARGA_NO}</Option>
                                <Option value="1">{uiTexts.HISTORIAL_DESCARGA_UNO}</Option>
                                <Option value="2">{uiTexts.HISTORIAL_DESCARGA_TODOS}</Option>
                        </Select>
                    </Col>
                </Row>
                <Divider style={styleVars.verticalMargin15}></Divider>
                <Row gutter={layoutVars.GUTTER_BASE}>
                    <h3 style={{textAlign: "center"}}> {uiTexts.PUBLISH_IMAGE_TXT} </h3>
                </Row>
                <Row gutter={layoutVars.GUTTER_BASE} style={{marginBottom: "-15px"}}>
                    <Col span={9} offset={1}>   
                        <Checkbox
                            style={{marginTop:15}}
                            checked={this.state.checkedUploadFile}
                            onChange={this.onChangeSendFile}>
                            {uiTexts.UPLOAD_FILE_SEND_PUBLICACION}
                        </Checkbox>
                    </Col>
                    <Col span={1} style={styleVars.verticalMargin30}/>
                    <Col span={11} offset={1}>
                      <div style={{ display: this.state.checkedUploadFile ? "block" : "none" }}>                  
                            <input type='file' name='file'
                                onChange={(e)=>this.onChangeSaveImage(e)} accept="image/jpeg, image/jpg" style={{width: "95%", marginTop:15}}/>
                      </div> 
                    </Col>
                </Row>
                <Divider style={styleVars.verticalMargin15}></Divider>
                <Row gutter={layoutVars.GUTTER_BASE}>
                    <h3> {uiTexts.QUESTIONARY_APPLY_TXT}</h3>

                    <Col span={8}>
                        <CheckboxGroup
                            value={!this.state.isExternals ? (this.state.junta !== [] ? this.state.junta : []) : []}
                            onChange={(e) => this.onChangeCheck(e)} options={options}
                        />
                        <Checkbox style={{marginTop:30}}
                            indeterminate={this.state.indeterminate}
                            onChange={this.onChangeExternals}
                            checked={this.state.isExternals}
                        > Usuarios externos
    
                        </Checkbox>
                    </Col>

                    <Col span={1}>
                        <Divider type={"vertical"} className="vertical-divider-12em"></Divider>
                    </Col>

                    <Col span={13} offset={1}>
                        <h3>{uiTexts.SUBGROUP} {(this.state.idJunta > 0 && this.state.toShowCombo === null) && "(Todos)"} </h3>
                        <Select
                            value={this.state.subGrupo !== null ? this.state.subGrupo : []}
                            mode="tags"
                            maxTagCount={3}
                            disabled={this.state.toShowCombo === null && true}
                            onChange={(e) => this.setState({ subGrupo: e })}
                            filterOption={(input, option) => option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0}
                            style={{ width: "100%" }}>
                            {this.optionsBuilder()}
                        </Select>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }


    render() {
        //console.log(this.state);
        this.evalAllData();
        return (
            this.firstStep()
        )
    }
}

export default connect(mapStateToProps)(DateRange);