import React, { Component } from 'react'
import { Modal } from 'antd';
import QuestionEditorComp from './QuestionEditorComp';
import * as actionsGalery from '../../actions/actions'
import { updateValues } from '../../actions/previewQuestionaryActionCreator';
import { connect } from 'react-redux';
import { cloneObject, validateEmptyValues } from '../../helpers/helpers'
import { closeWindowToEdit } from '../../actions/newQuestionActionCreator';
import * as uiTexts from '../../configs/uiTexts';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary,
        tempQuestion: state.tempQuestion,
        editionEstatus: state.editionEstatus
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question,isEdit) => dispatch(updateValues(questionary, section, question,isEdit)),
    closeWindowToEdit: (question, section, action) => dispatch(closeWindowToEdit(question, section, action))
});

class WindowToEditQuestion extends Component {

    handleModalOk = (questionary, section, question) => {

        if (this.props.tempQuestion.question.idQuestion === null) {
            let newQuestion = cloneObject(question)
            newQuestion.idQuestion = String(questionary.sections[section].questions.length);
            if (newQuestion.childQuestion !== null)
                newQuestion.childQuestion.idQuestion = newQuestion.idQuestion + "|0"
            const newQuestionary = cloneObject(questionary)

            newQuestionary.sections[section].questions.push(newQuestion)

            this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion),true);
            this.props.closeWindowToEdit(actionsGalery.CLOSE_EDITOR)

        }
        else {
            const newQuestionary = cloneObject(questionary)
            let newQuestion = cloneObject(question)
            this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion),true);
            this.props.closeWindowToEdit(actionsGalery.CLOSE_EDITOR)
          
        }
    }

    handleModalCancel = (e) => {

        this.props.closeWindowToEdit(actionsGalery.CLOSE_EDITOR)
    }

    evalStatus = (editionEstatus) => {

        if (editionEstatus.type === actionsGalery.EDIT_QUESTION)
            return this.modalConstructorEdit(editionEstatus.isEditorOpen, editionEstatus.question)
        else
            return this.modalConstructorNew(editionEstatus.isEditorOpen, editionEstatus.question)
    }

    modalConstructorEdit = (isEditorOpen, question) => {
        return (

            <Modal
                maskClosable={false}
                width={"80%"}
                okButtonProps={{ disabled: typeof (this.props.tempQuestion.question.disableOkButton) === "undefined" ? true : this.props.tempQuestion.question.disableOkButton }}
                cancelButtonProps={{ disabled: false }}
                title={question.title === "Nueva pregunta tipo " + question.type ? "" : "Edición de pregunta: " + question.title}
                visible={isEditorOpen}
                onOk={() => this.handleModalOk(this.props.questionary, this.props.editionEstatus.section, this.props.tempQuestion.question)}
                onCancel={this.handleModalCancel}
            >
                <QuestionEditorComp typeQuestionary ={this.props.questionary.header.type}/>
            </Modal>
        )
    }

    modalConstructorNew = (isEditorOpen, question) => {
        return (

            <Modal
                width={"80%"}
                okButtonProps={{ disabled: typeof (this.props.tempQuestion.question.disableOkButton) === "undefined" ? true : this.props.tempQuestion.question.disableOkButton }}
                cancelButtonProps={{ disabled: false }}
                title={"Nueva pregunta"}
                visible={isEditorOpen}
                onOk={() => this.handleModalOk(this.props.questionary, this.props.editionEstatus.section, this.props.tempQuestion.question)}
                onCancel={this.handleModalCancel}
            >
                <QuestionEditorComp typeQuestionary ={this.props.questionary.header.type}/>
            </Modal>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.evalStatus(this.props.editionEstatus)}
            </React.Fragment>
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(WindowToEditQuestion);
//{ disabled: typeof (this.props.tempQuestion.question.disableOkButton) === "undefined" ? true : this.props.tempQuestion.question.disableOkButton }