import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetLogin } from '../../actions/loginActionsCreator';
//import { linksRouter } from '../../configs/baseUrl';
import { GENERIC_ERROR } from '../../configs/uiTexts';



/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    resetLogin: () => dispatch(resetLogin()),
});

class ErrorPage extends Component {

    componentWillMount() {
        if (this.props.credentials.isAuthenticated)
            this.props.resetLogin();
    }


    render() {
        return (
            <div>
                <h2>{GENERIC_ERROR}</h2>
            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPage));