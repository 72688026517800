import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, Col, Divider, Select } from 'antd';
import { GUTTER_BASE } from '../../../configs/layoutVars';
import * as styleVars from '../../../configs/styleVars';
import { sendQuestionary } from '../../../actions/previewQuestionaryActionCreator';
//import HeaderCreateQuestionary from '../HeaderCreateQuestionary';
import { withRouter } from 'react-router-dom';
//import { SAVE_LABEL } from '../../../configs/uiTexts';
import { RADIO } from '../../../configs/galeryTypes';
import { ComponentCreatorComp } from '../../viewQuestionary/ComponentCreatorComp';
import * as uiTexts from '../../../configs/uiTexts';
import { questionFinder, cloneObject } from '../../../helpers/helpers';
import { addQuestionaryToScreen } from '../../../actions/previewQuestionaryActionCreator';
import RestoreQuestConfig from './RestoreQuestConfig';



const Option = Select.Option;


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    sendQuestionary: (questionary, token) => dispatch(sendQuestionary(questionary, token)),
    addQuestionaryToScreen: (questionary) => dispatch(addQuestionaryToScreen(questionary))

});



class QuestConfigBuilder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledSelect: true,
        }
    }

    getArrayValidQuest = (questions, idQuestion) => {
        // eslint-disable-next-line
        return (questions.map((question, index) => {
            if (parseInt(question.idQuestion, 10) > parseInt(idQuestion, 10)) {
                return (
                    <Option value={question.idQuestion} key={index}>{(parseInt(question.idQuestion, 10) + 1) + "."} {question.title}</Option>
                )
            }
        }));
    }

    valueSelectCalculator = (idQuestion, section) => {
        const questTemp = questionFinder(idQuestion, section, this.props.questionary)
        if (questTemp) {

            if (questTemp.valueToDisableQuestions !== "") {
                return false
            }
            else {
                //console.log(true);
                return true
            }
        }
        else
            return true;
    }

    onSelectListChange = (e, question) => {
        let arrayToDisable = [];
        for (let i = (parseInt(question.idQuestion) + 1); i <= parseInt(e); i++) {
            arrayToDisable.push(i);
        }

        const stringArray = arrayToDisable.join("|");
        let newQuestionary = cloneObject(this.props.questionary.questionary)
        newQuestionary.sections[this.props.section].questions[parseInt(question.idQuestion)].questionToDisable = stringArray;
        newQuestionary.sections[this.props.section].questions[parseInt(question.idQuestion)].valueToDisableQuestions = newQuestionary.sections[this.props.section].questions[parseInt(question.idQuestion)].value;
        this.props.addQuestionaryToScreen(newQuestionary);
    }

    questBuilder = (section) => {

        let questions = cloneObject(this.props.questionary.questionary.sections[section].questions);

        // eslint-disable-next-line
        return (questions.map((question, index, questions) => {

            if (question.type === RADIO) {
                //this.valueSelectCalculator(question.idQuestion, section, this.props.questionary)
                if (question.valueToDisableQuestions)
                    if (question.valueToDisableQuestions !== "") {
                        if (question.value !== question.valueToDisableQuestions) {
                            //this.setState({ disabledSelect: false })
                            question.value = question.valueToDisableQuestions;
                        }
                    }

                return (

                    <Card style={styleVars.questionCard} key={index + 100} hoverable>
                        <Row gutter={GUTTER_BASE}>

                            <Col span={16}>
                                <h3> {question.required
                                    ?
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                    :
                                    ""}

                                    {(parseInt(question.idQuestion, 10) + 1) + "."} {question.title}

                                </h3>
                            </Col>

                            <Col sm={24} md={15} lg={14}>
                                <ComponentCreatorComp question={question} section={section} isChild={false} isConfig={true} />
                            </Col>

                            <Col span={1}> <Divider type={"vertical"} className="vertical-divider-8em"></Divider> </Col>
                            <Col sm={23} md={8} lg={9}>
                                <label>{uiTexts.CONFIG_QUEST_LIST_LABEL}</label>

                                <Select
                                    value={question.questionToDisable
                                        ?
                                        question.questionToDisable.split("|")[question.questionToDisable.split("|").length - 1]
                                        :
                                        null
                                    }
                                    notFoundContent={"Sin opciones válidas"}
                                    placeholder={uiTexts.SELECT_INSTRUCTION}
                                    style={{ width: "100%" }}
                                    disabled={this.valueSelectCalculator(question.idQuestion, section)}
                                    onChange={(e) => this.onSelectListChange(e, question)}
                                >
                                    {this.getArrayValidQuest(questions, question.idQuestion)}
                                </Select>
                            </Col>

                            <RestoreQuestConfig section={section} questions={questions} index={index}></RestoreQuestConfig>
                        </Row>

                    </Card>

                )
            }
        }));
    }

    render() {
        return (
            this.questBuilder(this.props.section)
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestConfigBuilder));