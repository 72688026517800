import React from 'react';
import { Col, Spin, Row } from 'antd';
import * as uiTexts from '../../configs/uiTexts';

export const LoadingComp = () => {
    return (
        <Row>
            <Col span={24} style={{ textAlign: "center" }}>
                <Spin size="large" />
                <p>{uiTexts.LOADING_MESSAGE}</p>
            </Col>
        </Row>

    );
};