import React, { Component } from 'react';
//import { Card, Icon, Modal, Row, Col, Divider, DatePicker, Checkbox, Select } from 'antd';
import { Icon, Divider, Steps, Button } from 'antd';
import { connect } from 'react-redux';
import DateRange from './DateRange';
import MailConfig from './MailConfig';
import { publishQuestionary } from '../../actions/publishActionsCreator';
import { cloneObject } from '../../helpers/helpers';
import { LoadingComp } from '../genericComponents/LoadingComp';

const Step = Steps.Step;


/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        publishData: state.publishData
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    publishQuestionary: (token, data) => dispatch(publishQuestionary(token, data)),
});


class PublishForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: 0,
            nextDisabled: true,
            saveDisabled: false,
            configPeriodValues: null,
            mailDataValues: null
        }
        this.enableDisableNextBtn = this.enableDisableNextBtn.bind(this)
        //this.enableDisableSaveBtn = this.enableDisableSaveBtn.bind(this)

        this.setConfigData = this.setConfigData.bind(this)
        this.setMailData = this.setMailData.bind(this)

    }

    enableDisableNextBtn = (sw) => { this.setState({ nextDisabled: sw }) }
    //enableDisableSaveBtn = (sw) => { this.setState({ saveDisabled: sw }) }

    setConfigData = (configs) => { this.setState({ configPeriodValues: configs }) }
    setMailData = (configs) => { this.setState({ mailDataValues: configs }) }



    preProcessPublish = () => {

        
        let newData = cloneObject(this.props.publishData.payload);
        newData.areas = null;
        //newData.organos = null;
        newData.puestos = null;

        newData.correo.listaCorreosCopia = this.state.mailDataValues.tags;
        newData.correo.urlAreaIntranet = this.state.mailDataValues.urlInput;

        newData.periodo.fechaHoraInicio = this.state.configPeriodValues.initialRange
        newData.periodo.fechaHoraTermino = this.state.configPeriodValues.finalRange
        newData.periodo.tiempoSesion = this.state.configPeriodValues.timeSession
        newData.periodo.areasPuestos = this.state.configPeriodValues.subGrupo;
        newData.periodo.idOrgano = this.state.configPeriodValues.idJunta;
        newData.periodo.nombreOrgano = newData.organos[this.state.configPeriodValues.idJunta-1].label;
        newData.periodo.nombreImagenFinalizacion = this.state.configPeriodValues.nombreArchivo;
        newData.periodo.historial = this.state.configPeriodValues.showHistorial;
        //newData.organos = null;
        //console.log(newData);
        //console.log(this.state);

        this.props.publishQuestionary(this.props.credentials.token, newData);

    }

    render() {

        if (this.props.publishData.isLoading)
            return <LoadingComp></LoadingComp>

        return (
            <React.Fragment>


                <Steps current={this.state.currentTab}>
                    <Step key={"Configuracion"} title={"Configuración de periodo"} />
                    <Step key={"Publicar"} title={"Publicar"} />
                </Steps>
                <Divider></Divider>

                {this.state.currentTab === 0 ?
                    <React.Fragment>


                        <DateRange
                            enableDisableNextBtn={this.enableDisableNextBtn}
                            setConfigData={this.setConfigData}
                            data={this.state.configPeriodValues}
                            configPeriodValues={this.state.configPeriodValues}
                        />


                        <div style={{ textAlign: "right" }}>
                            <Divider></Divider>
                            <Button
                                disabled={this.state.nextDisabled}
                                type="primary"
                                onClick={() => this.setState({ currentTab: 1 })}
                            > Siguiente <Icon type="arrow-right" />
                            </Button>
                        </div>

                    </React.Fragment>
                    :
                    <React.Fragment>

                        <MailConfig
                            setMailData={this.setMailData}
                            //enableDisableSaveBtn={this.enableDisableSaveBtn}
                            formState={this.state}
                        />


                        <div style={{ textAlign: "right" }}>
                            <Divider></Divider>
                            <Button
                                icon="arrow-left"
                                style={{ float: "left" }}
                                onClick={() => this.setState({ currentTab: 0 })}
                            > Regresar
                            </Button>
                            <Button
                                onClick={this.preProcessPublish}
                                disabled={this.state.saveDisabled}
                                icon="save"
                                type="primary"
                            > Guardar</Button>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>


        )

    }
}

//export default (PublishForm);
export default connect(mapStateToProps, mapDispatchToProps)(PublishForm);
