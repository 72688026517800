import React, { Component } from 'react';

import { fetchLogin } from '../../actions/loginActionsCreator';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import * as layoutVars from '../../configs/layoutVars' //'../configs/layoutVars';
import * as uiTexts from '../../configs/uiTexts';
import * as styleVars from '../../configs/styleVars'


import { Form, Icon, Input, Button, Card, Row, Col, Divider, Popover } from 'antd';

const FormItem = Form.Item;

const CAP_KEY = "6LdwfnwUAAAAADTbpbr9KcP0PlzEMa1cWsyDF-04"
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        publicHome: state.publicHome
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchLogin: (userName, password, idCuestionario) => dispatch(fetchLogin(userName, password, idCuestionario))
});
  
const content = (
    <div>
      <p>Levanta un caso <a href={layoutVars.urlCAU} target="_blank" rel="noopener noreferrer">CAU</a>, explicando el problema.</p>
    </div>
  );
  
  const validacionUsuario = /^[a-zA-Z-0-9.@_.]*$/;

  //const validacionContra = /^[a-zA-Z-0-9.@_.$@$!%*?&#.:]*$/;
  const validacionContra = /^[^<> ]*$/;

class LoginForm extends Component {
    constructor(props) {
        super(props)
        this.handleLogin = this.handleLogin.bind(this);
        this.state = { loading: false, captcha: null }
    }

    handleLogin(e) {

        console.log(this.props.publicHome.payload);
        e.preventDefault();

        /**
         * código para desactivar ingreso de datos en login
         * Solo para pruebas
         */
        //  this.props.fetchLogin("nilson", "nilson");

        /**
         * código a usar en producción
         */
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.fetchLogin(values.userName, values.password, this.props.credentials.idCuestionario);
                //console.log('Received values of form: ', values);
            }
        });

    }
    handleCaptcha = (e) => {
        //console.log(e);
        this.setState({ captcha: e })

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        //console.log("credentials",this.props.credentials)
        return (
            <Row gutter={layoutVars.GUTTER_BASE}>
                <Col span={24}>
                    <h3 style={{textAlign:"center"}}>    CUESTIONARIOS </h3>
                    <h3 style={{textAlign:"center"}}> Diseño de cuestionarios</h3>
                </Col>
                <Col span={24}>
                    <Card>
                    {this.props.credentials.message !==null?
                    
                    <p style={ {textAlign:"center", color:"#ff0000", fontWeight:"bold", fontSize:"16px"}}><br/>{this.props.credentials.message}
                    <br/>
                    {this.props.credentials.message ===uiTexts.FAILED_ACTIVE_SESSION?
                     <span style={ {textAlign:"center", color:"#888888", fontWeight:"normal", fontSize:"14px", lineHeight:"12px"}}>{uiTexts.ACTIVE_SESION_MESSAGE}</span>
                    :""}
                    </p>:""}
                        <Form onSubmit={this.handleLogin} className="login-form">
                            <FormItem
                                label={uiTexts.USER_LABEL}
                            >
                                {getFieldDecorator('userName', {
                                    rules: [{ required: true, message: uiTexts.USER_REQUIRED }],
                                    getValueFromEvent: (event) => {
                                        if (validacionUsuario.test(event.target.value)){
                                            return event.target.value
                                        }else{
                                            return (event.target.value).slice(0,-1)
                                        }
                                    }
                                })(
                                    <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={uiTexts.USER_PLACEHOLDER} maxLength={layoutVars.MAX_LENGTH_USER}/>  
                                )}
                            </FormItem>
                            <FormItem
                                label={uiTexts.PASSWORD_LABEL}

                            >
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: uiTexts.PASSWORD_REQUIRED }],
                                    getValueFromEvent: (event) => {
                                        if (validacionContra.test(event.target.value)){
                                            return event.target.value
                                        }else{
                                            return (event.target.value).slice(0,-1)
                                        }
                                    }
                                })(
                                    <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={uiTexts.PASSWORD_PLACEHOLDER} maxLength={layoutVars.MAX_LENGTH_PASS}/>
                                )}
                            </FormItem>

                            {this.props.publicHome.payload && this.props.publicHome.payload.validarCaptcha && <ReCAPTCHA
                                style={styleVars.ReCAPTCHA}
                                sitekey={CAP_KEY}
                                onChange={this.handleCaptcha}
                                />}

                            <FormItem>
                                <Button type="primary"
                                    block={true}
                                    disabled={(!(this.props.publicHome.payload && this.props.publicHome.payload.validarCaptcha) ||
                                         this.state.captcha !== null) ? false : true}
                                    htmlType="submit"
                                    loading={this.props.credentials.isFetching}
                                >{uiTexts.LOGIN_BUTTON}</Button>

                            </FormItem>
                        </Form>
                        
                        <Divider />
                        <Row>
                             <Col style={{textAlign:"center"}}>
                            <Popover content={content} title="Comunicate con el CAU" trigger="click">
                                <a  href={layoutVars.urlDatosPersonales} target="_blank" rel="noopener noreferrer"> {uiTexts.HELP} </a>
                            </Popover>
                            <p></p>

                            <a  href={layoutVars.urlDatosPersonales} target="_blank" rel="noopener noreferrer">{uiTexts.PERSONAL_DATA}</a>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        )

    }
}
// 
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(LoginForm));