import React, { Component } from 'react'
import { Table, Input, Button, Icon } from 'antd';
import { MAIN_COLOR } from '../../configs/styleVars';
import * as uiTexts from '../../configs/uiTexts';
import { connect } from 'react-redux';
import { fetchPreviewAns, fetchDeleteAns } from '../../actions/previewAnsActionCreator';
import moment from 'moment';




/** Redux get state  ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    fetchPreviewAns: (token, idPeriodo, idCuestionario, idUsuario) => dispatch(fetchPreviewAns(token, idPeriodo, idCuestionario, idUsuario)),
    fetchDeleteAns: (token, idPeriodo, idCuestionario, idUsuario) => dispatch(fetchDeleteAns(token, idPeriodo, idCuestionario, idUsuario))
});




class AnsDataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
        };
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    }

    handleReset = clearFilters => () => {
        clearFilters();
        this.setState({ searchText: '' });
    }
    handleDelete = (e, data, props) => {
        this.props.fetchDeleteAns(props.credentials.token, data.periodId, data.questionaryId, data.userId);
    }
    handleSeeAns = (e, data, props) => {
        this.props.fetchPreviewAns(props.credentials.token, data.periodId, data.questionaryId, data.userId);
    }

    render() {
        const columns = [
            {
                title: "#",
                dataIndex: 'key',
                key: 'key',
                className: 'align-center width-xs',
            },

            {
                title: 'Usuario',
                dataIndex: 'userFullname',
                key: 'userFullname',
                sorter: (a, b) => a.userFullname.length - b.userFullname.length,

                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div className="custom-filter-dropdown">
                        <Input
                            ref={ele => this.searchInput = ele}
                            placeholder={uiTexts.SEARCH_PLACEHOLDER}
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={this.handleSearch(selectedKeys, confirm)}
                        />
                        <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>{uiTexts.SEARCH}</Button>
                        <Button onClick={this.handleReset(clearFilters)}>{uiTexts.RESET_SEARCH}</Button>
                    </div>
                ),
                filterIcon: filtered => <Icon type="filter" theme="filled" style={{ color: filtered ? MAIN_COLOR : '#aaa', fontSize: filtered ? "18px" : "15px" }} />,
                onFilter: (value, record) => record.userFullname.toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => {
                            this.searchInput.focus();
                        });
                    }
                },
                render: (text) => {
                    const { searchText } = this.state;
                    return searchText ? (
                        <span>
                            {text.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')).map((fragment, i) => (
                                fragment.toLowerCase() === searchText.toLowerCase()
                                    ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
                            ))}
                        </span>
                    ) : text;
                },



            }, {
                title: uiTexts.INITIAL_DATE_INBOX,
                dataIndex: 'startDate',
                key: 'startDate',
                className: 'align-right',
                sorter: (a, b) => moment(a.startDate).subtract(moment(b.startDate)),
            }, {
                title: uiTexts.FINAL_DATE_INBOX,
                dataIndex: 'endDate',
                key: 'endDate',
                className: 'align-right',
                sorter: (a, b) => moment(a.endDate).subtract(moment(b.endDate)),

            }, {
                title: uiTexts.PERIOD_LABEL,
                dataIndex: 'periodDescription',
                key: 'periodDescription',
                className: 'align-center',
            }

            , {
                title: uiTexts.SEE_ANS_LABEL,
                key: 'tags',
                className: 'align-center width-sm',
                render: (text, record) => (<Button icon="eye" shape="circle" onClick={(e) => this.handleSeeAns(e, record, this.props)} />)
                ,
            }, {
                title: uiTexts.DELETE_LABEL,
                key: 'action',
                className: 'align-center width-sm',
                render: (text, record) => (<Button icon="delete" shape="circle" onClick={(e) => this.handleDelete(e, record, this.props)} />)
            }
        ];


        return (
            <div>
                {this.props.data && <Table bordered title={() => <h2>{this.props.data.questionaryTitle}</h2>} columns={columns} dataSource={this.props.data.users}>
                </Table>}
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnsDataTable);
