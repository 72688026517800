import React, { Component } from 'react'
import { Form, DatePicker } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';
import moment from 'moment';



const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const DATEFORMAT = 'DD/MM/YYYY';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});


class DateRangePickerComp extends Component {

    onChange = (e, questionary, section, question) => {
        const date = e.length > 0 ? e[0].format('L') + "|" + e[1].format('L') : "";
        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = date
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    DateRangePickerCompCreator = (question) => {
        return <RangePicker
            onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, question)}
            disabled={question.disabled}
            value={(question.value === "" || question.value === "null") ?
                [moment(), moment()] :
                [moment(question.value.split("|")[0], 'DD/MM/YYYY'), moment(question.value.split("|")[1], 'DD/MM/YYYY')]}
            format={DATEFORMAT}
            showToday={true}
        />
    }


    render() {
        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem
                        disabled={this.props.question.disabled}
                        colon={layoutVars.COLON}
                        label={this.props.question.groupLabel}
                        required={this.props.question.required}
                    >
                        {this.DateRangePickerCompCreator(this.props.question)}
                    </FormItem>
                </React.Fragment>
            )


        return (
            <FormItem
                disabled={this.props.question.disabled}
                colon={layoutVars.COLON}
                label={this.props.question.groupLabel}
                required={this.props.question.required}
                validateStatus={this.props.question.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >
                {this.DateRangePickerCompCreator(this.props.question)}
            </FormItem>

        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DateRangePickerComp));
