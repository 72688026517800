import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import sinAcceso from "../../imgs/Mensaje_sinacceso.svg";
import {noAccesLogin} from '../../actions/loginActionsCreator';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        credentials: state.credentials
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    noAccesLogin: () => dispatch(noAccesLogin()),
});


class SinAcceso extends Component {
    
    componentWillMount() {
        if (this.props.credentials.isAuthenticated)
            this.props.noAccesLogin();
    }

    render() {
        return (
            <div>
               <img alt="" src={sinAcceso}></img><br />
            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinAcceso));
