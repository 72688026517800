import React, { Component } from 'react';
import { Form, Input, Switch, Col, Radio, Button, Row, InputNumber } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
//import * as styleVars from '../../../configs/styleVars'
//import * as styleVars from '../../../configs/styleVars';
import * as galeryTypes from '../../../configs/galeryTypes'
import { connect } from 'react-redux';
import { updateTemporalQuestion } from '../../../actions/updateTemporalQuestionActionCreator';
import { cloneObject } from '../../../helpers/helpers';
import { fontSize12, fontSize15 } from '../../../configs/styleVars';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        tempQuestion: state.temporalQuest
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateTemporalQuestion: (tempQuestion) => dispatch(updateTemporalQuestion(tempQuestion)),
});

const { TextArea } = Input;

let childQuest = {
    "idQuestion": "",
    "validateStatus": "",
    "disabled": false,
    "visible": true,
    "title": "",
    "relatedInfo": "",
    "type": "INPUT",
    "label": uiTexts.REQUIRED_LABEL,
    "value": "",
    "required": false
};

class RadioFactoryComp extends Component {
    constructor(props) {
        super(props);
        this.state = {

            disabled: this.props.disabled,
            idQuestion: this.props.idQuestion,
            label: this.props.label,
            ponderacion: this.props.ponderacion ? this.props.ponderacion : "",
            relatedInfo: this.props.relatedInfo,
            required: this.props.required,
            title: this.props.title,
            type: galeryTypes.RADIO,
            typeQuestionary: this.props.typeQuestionary,
            validateStatus: this.props.validateStatus,
            value: this.props.value ? this.props.value : "",
            valueToShowChild: this.props.valueToShowChild,
            visible: this.props.visible,
            childQuestion: this.props.childQuestion,
            titleValidate: "",
            labelValidate: "",
            disableOkButton: true,
            addItemDisabled: false,
            labelsTemp: this.props.label.split("|"),
            labelsChildtemp: this.props.childQuestion !== null ? this.props.childQuestion.label : uiTexts.REQUIRED_LABEL,
            switchChildValue: this.props.childQuestion !== null ? typeof (this.props.childQuestion.label) === "string" ? true : false : false
        }
    }

    onChange = (labelsTemp) => {
        /*console.log("switch=>:", this.state.switchChildValue);
        console.log("childquestion=>:", this.state.childQuestion);
        console.log("title=>:", this.state.childQuestion  && this.state.childQuestion.title !=="" );
        console.log("label=>:", this.state.label !== "" && this.state.label !== undefined );*/
        //Condiciones de validacion para el boton de aceptar en el proceso de creación y modificacion
        let labelsValidate = labelsTemp ? labelsTemp : this.state.labelsTemp;
        let labelFlag = labelsValidate && labelsValidate.length > 0;
        labelsValidate.forEach(element => {
            labelFlag &= element && element !== '';
        });
        if (this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 ||
            this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) {
            if (/*this.state.label !== "" && this.state.label !== undefined*/ labelFlag && this.state.title !== "" && this.state.title !== undefined && /*this.state.titleValidate === layoutVars.VALIDATE_SUCCESS  &&*/ (!this.state.switchChildValue || this.state.switchChildValue && this.state.childQuestion  && this.state.childQuestion.title !=="")){
                this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) }) 
            }else{
                this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
            }
        }else {
            this.setState({required: true}); //Solo aplica para el caso de EVALUACIONES
            if (/*this.state.label !== "" && this.state.label !== undefined*/ labelFlag && this.state.title !== "" && this.state.title !== undefined &&
                this.state.ponderacion !== "" && this.state.ponderacion !== undefined && this.state.value !== "" && this.state.ponderacion !== null && this.state.value !== undefined ){
                this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) })
            }else{
                this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
            }
        }
    }

    radioCreator = (question) => {
        return (question.label.split("|").map((quest, index, array) => {
            return (
                <Col xs={24} sm={12} md={12} lg={8} key={index.toString()}>
                    <Radio value={index.toString()} >
                        <Input
                            onChange={(e) => {
                                this.onChangeLabel(e, index);
                            }}
                            onBlur={(e) => this.blurInput(e, array, index)}
                            value={this.state.labelsTemp[index]}
                        >
                        </Input>
                        &nbsp;<Button shape="circle" icon="delete" onClick={() => this.deleteItem(index, array)} />
                    </Radio>
                </Col>
            )
        }));

    }
    blurInput = (e, array, index) => {
        array[index] = e.target.value === "" ? "radio " + index : e.target.value;

        this.setState({ label: array.join("|") },
            this.onChange())
    }
    onChangeLabel = (e, index) => {
        let labels = this.state.labelsTemp;
        labels[index] = e.target.value;
        this.setState({ labelsTemp: labels }, this.onChange());
    }

    deleteItem = (index, arrayLabels) => {
        arrayLabels.splice(index, 1);
        this.setState({ labelsTemp: arrayLabels, label: arrayLabels.join("|"), addItemDisabled: arrayLabels.length > 5 ? true : false },
            this.onChange(arrayLabels))
    }

    addItem = () => {
        let arrayLabels = this.state.label.split("|");
        arrayLabels.push(uiTexts.REQUIRED_LABEL);

        this.setState({ labelsTemp: arrayLabels, label: arrayLabels.join("|"), addItemDisabled: arrayLabels.length > 5 ? true : false },
            this.onChange(arrayLabels))
    }


    onChangeSwitch = (e) => {
       
        if (e) {
            childQuest.idQuestion = this.state.idQuestion + "|0";
            //childQuest.label = uiTexts.REQUIRED_LABEL;
            this.setState({ switchChildValue: e, childQuestion: e ? { ...childQuest } : null }, () => { this.props.updateTemporalQuestion(this.state) })
        }
        else {
            this.setState({ switchChildValue: e, childQuestion: null }, () => { this.props.updateTemporalQuestion(this.state) })

        }
        let labelsValidate = this.state.labelsTemp;
        let labelFlag = labelsValidate && labelsValidate.length > 0;
        labelsValidate.forEach(element => {
            labelFlag &= element && element !== '';
        });
        /*console.log("switch=>:", e);
        console.log("childquestion=>:", this.state.childQuestion);
        console.log("title=>:", this.state.childQuestion  && this.state.childQuestion.title !=="" );
        console.log("label=>:", this.state.label !== "" && this.state.label !== undefined );*/
        if (this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 ||
            this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) {
            if (/*this.state.title !== "" && this.state.title !== undefined*/ labelFlag && /*this.state.titleValidate === layoutVars.VALIDATE_SUCCESS &&*/ (!e ||  e && this.state.childQuestion  && this.state.childQuestion.title !=="")){
                this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) }) 
            }else{
                this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
            }
        }else {
            this.setState({required: true}); //Solo aplica para el caso de EVALUACIONES
            if (/*this.state.label !== "" && this.state.label !== undefined*/ labelFlag && this.state.title !== "" && this.state.title !== undefined &&
                this.state.ponderacion !== "" && this.state.ponderacion !== undefined && this.state.value !== "" && this.state.value !== undefined ){
                this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) })
            }else{
                this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
            }
        }
    }

    render() {

        return (

            <React.Fragment>
                <Row>
                    <Col span={18}>
                        <FormItem
                            colon={layoutVars.COLON}
                            label={uiTexts.QUESTION_TITLE_LABEL}
                            required={true}
                            validateStatus={this.state.titleValidate}
                            help={this.state.titleValidate === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
                        >
                        <TextArea
                            autosize={{ minRows: 1, maxRows: 3 }}
                            maxLength={layoutVars.MAX_LENGTH_QUESION}
                            value={this.state.title}
                            placeholder={uiTexts.QUESTION_TITLE_LABEL_PLACEHOLDER}
                            onChange={(e) => {
                                this.setState({ title: e.target.value }, this.onChange());
                            }}
                            onBlur={(e) => {
                                this.setState({ titleValidate: this.state.title === "" ? layoutVars.VALIDATE_ERROR : layoutVars.VALIDATE_SUCCESS },
                                    () => this.onChange())
                            }}
                        />
                        </FormItem>
                    </Col>
                    <Col span={1}/>
                    <Col span={4} >
                        <div style={{ display: this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 || this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE4 ? "block" : "none" }}>
                            <FormItem
                                colon={layoutVars.COLON}
                                label={uiTexts.INPUT_LABEL_REACTIVE_VALUE}
                                required={true}>

                            <InputNumber min={1} max={100} type="number" 
                            value={this.state.ponderacion}
                            style={{ width: "100%" }}
                            onChange={(e) => {this.setState({ ponderacion: e} , () => this.onChange())}}
                            placeholder={uiTexts.INPUT_LABEL_PLACEHOLDER_PONDERACION}
                            maxLength={3}/>

                            </FormItem>
                        </div>
                    </Col>
                </Row>
                <Col span={24}>
                  <Row> 
                    <div style={{ display: this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 || this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE4 ? "block" : "none" }}>
                        <b><label style={fontSize15}> {uiTexts.NEW_QUESTION_TITLE_OPTIONS_EVALUACION}</label></b>
                        <br/>
                        <label style={fontSize12}>{uiTexts.NEW_QUESTION_TITLE_OPTIONS_SUB_EVALUACION}</label> 
                    </div>
                  </Row>   
                    <FormItem
                        colon={layoutVars.COLON}
                        label={this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE2 || this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE4 ? uiTexts.INPUT_LABEL_BUILDER_EVALUACION : uiTexts.INPUT_LABEL_BUILDER}
                        required={true}
                        validateStatus={this.state.labelValidate}
                        help={this.state.labelValidate === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
                    >

                        <RadioGroup
                            style={{ width: "100%" }}
                            value={this.state.value.toString()}
                            name={'idField_' + this.state.idQuestion}
                            onChange={(e) => {
                                this.setState({ value: e.target.value.toString() },
                                    () => this.onChange())
                            }}    //{(e) => this.onChange(e, this.props.questionary, this.props.section, this.props.question)}
                        >
                            {this.radioCreator(this.state)}
                        </RadioGroup>

                        <Col span={24}>
                            <Button icon="plus" onClick={this.addItem} disabled={this.state.addItemDisabled} >Agregar</Button>
                        </Col>

                    </FormItem>
                </Col>

                { this.renderJustification() }

                <Col span={24}>
                    <b>{uiTexts.REQUIERED_QUESTION_LABEL} &nbsp;&nbsp;&nbsp;</b> <Switch
                        checkedChildren={uiTexts.YES_LABEL}
                        unCheckedChildren={uiTexts.NOT_LABEL}
                        defaultChecked={(this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 || this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) ? this.state.required : true}
                        checked={(this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 || this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) ? this.state.required : true}
                        onChange={(checked) =>
                            this.setState({ required: checked }, () => this.onChange())}
                    />
                </Col>

            </React.Fragment>
        );
    }

    //La opción de agregar justificación depende del tipo de Cuestionario/Evaluación en cuestión
    renderJustification() {
        //ToDo: Include evaluation type check condition
        //if(true){
        if (this.props.typeQuestionary === uiTexts.NEW_QUESTIONARY_TYPE1 ||
            this.props.typeQuestionary  === uiTexts.NEW_QUESTIONARY_TYPE3) {
            return (
                <Col span={12}>
                    <FormItem colon={layoutVars.COLON} >
                        <Switch
                            onChange={(e) => this.onChangeSwitch(e)}
                            checked={this.state.switchChildValue}
                        />  Agregar justificación
                    </FormItem>
                    {
                        (this.state.switchChildValue)?
                            <FormItem colon={layoutVars.COLON} label={uiTexts.INPUT_LABEL_BUILDER} required={true} >
                                <Input
                                    maxLength={layoutVars.MAX_LENGTH_LABELS}
                                    value={typeof (this.state.childQuestion.title) === "string" ? this.state.childQuestion.title : "¿Por qué?"}
                                    onChange={
                                        (e) => {

                                            let newState = cloneObject(this.state.childQuestion);
                                            newState.title = e.target.value

                                            this.setState({ childQuestion: newState }, () => this.onChange())
                                        }}
                                />
                            </FormItem>
                        :
                        null
                    }
                </Col>
            );
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioFactoryComp);