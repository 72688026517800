import React, { Component } from 'react'
import { Form, Rate } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '.././../../configs/baseUrl';
const FormItem = Form.Item;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateValues: (questionary, section, question) => dispatch(updateValues(questionary, section, question)),
});


class RateComp extends Component {

    onChange = (e, questionary, section, question) => {
        const newQuestionary = cloneObject(questionary)
        let newQuestion = cloneObject(question)
        newQuestion.value = e.toString();
        this.props.updateValues(newQuestionary, section, validateEmptyValues(newQuestion));
    }

    rateCreator = (question) => {
        return (
            <React.Fragment >
                <Rate disabled={question.disabled}
                    allowClear={!question.required}
                    onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, question)}
                    value={parseInt(question.value, 10)}
                />
                {"Calificación " + (question.label !== "" ? (isNaN(parseInt(question.value, 10)) ? 0 : question.value) : "")}
            </React.Fragment>
        )

    }

    render() {

        if (this.props.match.url === ("/" + linksRouter.newQuestionary))
            return (

                <React.Fragment>
                    <FormItem
                        colon={layoutVars.COLON}
                        required={this.props.question.required}
                    >
                        {this.rateCreator(this.props.question)}
                    </FormItem>
                </React.Fragment>
            )


        return (
            <FormItem
                disabled={this.props.question.disabled}
                colon={layoutVars.COLON}
                required={this.props.question.required}
                validateStatus={this.props.question.validateStatus === "" ? null : layoutVars.VALIDATE_ERROR}
                help={this.props.question.validateStatus === layoutVars.VALIDATE_ERROR ? uiTexts.REQUIRED_MESSAGE : null}
            >
                {this.rateCreator(this.props.question)}
            </FormItem>
        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RateComp));