import React, { Component } from 'react'
import { Form, DatePicker, Switch, Col, Input, Divider, Row } from 'antd';
import * as uiTexts from '../../../configs/uiTexts';
import * as layoutVars from '../../../configs/layoutVars';
import { connect } from 'react-redux';
//import { updateValues } from '../../../actions/previewQuestionaryActionCreator';
//import { cloneObject, validateEmptyValues } from '../../../helpers/helpers';
import * as styleVars from '../../../configs/styleVars';
import * as galeryTypes from '../../../configs/galeryTypes'
import { updateTemporalQuestion } from '../../../actions/updateTemporalQuestionActionCreator';
import moment from 'moment';

const FormItem = Form.Item;
const { TextArea } = Input;
const DATEFORMAT = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    updateTemporalQuestion: (tempQuestion) => dispatch(updateTemporalQuestion(tempQuestion))
});


class DateRangePickerFactoryComp extends Component {
    constructor(props) {
        super(props);

        this.state = {

            disabled: this.props.disabled,
            idQuestion: this.props.idQuestion,
            label: "",
            relatedInfo: this.props.relatedInfo,
            required: this.props.required,
            title: this.props.title,
            type: galeryTypes.DATERANGE_PICKER,
            validateStatus: this.props.validateStatus,
            value: this.props.value,
            valueToShowChild: this.props.valueToShowChild,
            visible: this.props.visible,
            titleValidate: "",
            labelValidate: "",
            disableOkButton: true,
            addItemDisabled: false,
            labelsTemp: this.props.label,
            childQuestion: null

        }

    }


    onChangeDate = (e) => {
        const date = e.length > 0 ? e[0].format('L') + "|" + e[1].format('L') : "";

        this.setState({ value: date },
            this.onChange())
    }


    DateRangePickerCompCreator = (question) => {
        return <RangePicker
            //onChange={(e) => this.onChange(e, this.props.questionary, this.props.section, question)}
            disabled={false}
            defaultValue={(question.value === "" || question.value === "null") ?
                null :
                [moment(question.value.split("|")[0], 'DD/MM/YYYY'), moment(question.value.split("|")[1], 'DD/MM/YYYY')]}
            allowClear={!question.required}
            format={DATEFORMAT}
            showToday={true}
        />
    }


    onChange() {
        this.setState(() => { this.props.updateTemporalQuestion(this.state) })
    }

    validaButton() {
        if (this.state.title !== ""){
            this.setState({ disableOkButton: false }, () => { this.props.updateTemporalQuestion(this.state) })
        }else{
            this.setState({ disableOkButton: true }, () => { this.props.updateTemporalQuestion(this.state) })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <FormItem
                            colon={layoutVars.COLON}
                            label={uiTexts.QUESTION_TITLE_LABEL}
                            required={true}
                        >
                            <TextArea
                                autosize={{ minRows: 1, maxRows: 3 }}
                                maxLength={layoutVars.MAX_LENGTH_QUESION}
                                value={this.state.title}
                                placeholder={uiTexts.QUESTION_TITLE_LABEL_PLACEHOLDER}
                                onChange={(e) => {
                                    this.setState({ title: e.target.value })
                                }}
                                onKeyUp={(e) => {
                                    this.setState(
                                        () => this.validaButton())
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
              

                <Divider orientation="left" style={styleVars.pinkText}>{uiTexts.PREVIEW_LABEL}</Divider>


                <RangePicker
                    allowClear={!this.state.required}
                    disabled={false}
                    value={(this.state.value === "" || this.state.value === "null") ?
                        [moment(), moment()] :
                        [moment(this.state.value.split("|")[0], 'DD/MM/YYYY'), moment(this.state.value.split("|")[1], 'DD/MM/YYYY')]}
                    format={DATEFORMAT}
                    showToday={true}
                />

                
                <Col span={24} style={styleVars.verticalMargin30}>

                    <b>{uiTexts.REQUIERED_QUESTION_LABEL} &nbsp;&nbsp;&nbsp;</b> <Switch
                        checkedChildren={uiTexts.YES_LABEL}
                        unCheckedChildren={uiTexts.NOT_LABEL}
                        defaultChecked={this.state.required}
                        checked={this.state.required}
                        onChange={(checked) =>
                            this.setState({ required: checked }, () => this.onChange())}
                    />
                </Col>

            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePickerFactoryComp);
