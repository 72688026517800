import { Component } from 'react';
import { connect } from 'react-redux'; 
import { notification } from 'antd';
import { clearMessage } from '../../actions/messagesActionCreator'


const key = 'globalNotification';


/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary,
        listQuestionary: state.listQuestionary,
        publishData: state.publishData,
        inboxAns: state.inboxAns,
        periodData: state.periodData,
        previewAns: state.previewAns,
        messages: state.messages
    }
}


/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    clearMessage: () => dispatch(clearMessage())
});



class MessageControler extends Component {

    emitNotification = (msgObj) => {

        notification[msgObj.type]({
            key,
            duration: msgObj.duration,
            message: msgObj.message,
            description: msgObj.description,
            style: {
                minHeight: 100,
                backgroundColor: "#f1f0f0",
                right: 100
            },
            onClose:()=>{this.props.clearMessage()}
        });
    }
    componentDidUpdate() {
        /*let msgObj = { type: null, duration: null, message: null, description: null };
        msgObj.type = NOTIF_TYPE.succes;
        msgObj.duration = 10;
        msgObj.message = "Ejecución exitosa";

        if (this.props.questionary.succesSend) {
            msgObj.description = this.props.questionary.message;

        } else if (this.props.publishData.succesSend) {
            msgObj.description = this.props.publishData.message;

        } else if (this.props.listQuestionary.succesSend) {
            msgObj.description = this.props.listQuestionary.message;

        } else if (this.props.previewAns.succesSend) {
            msgObj.description = this.props.previewAns.message;

        }
        if (msgObj.description !== null)
            emitNotification(msgObj);*/

        if (this.props.messages.showMessage) {
            let msgObj = {
                type: this.props.messages.notifType,
                duration: 10,
                message: this.props.messages.title,
                description: this.props.messages.description
            };
            this.emitNotification(msgObj);
        }
    }

    render() {
        return (
            null
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MessageControler);
