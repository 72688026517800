
import React from 'react';
import {  Row } from 'antd';
import * as layoutVars from '../../configs/layoutVars' //'../configs/layoutVars';
//import * as uiTexts from '../../configs/uiTexts';

export const HeaderPreviewQuestionaryComp = ({ header }) => {
    return (
        <React.Fragment>
            <Row gutter={layoutVars.GUTTER_BASE}>
                <h2>{header.questionaryName}</h2>
            </Row>
        </React.Fragment>
    );
};