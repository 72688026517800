import * as ActionTypes from '../actions/actions';

export const editPeriodRed = (state = {
    isLoading: null,
    payload: null,
    message: null,
    openModal: null,
    typeMessage:null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_PERIOD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal }

        case ActionTypes.FAILED_FETCH_PERIOD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, typeMessage:action.typeMessage  }

        case ActionTypes.SUCCESS_FETCH_PERIOD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal }

        case ActionTypes.FAILED_EDITED_PERIOD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, typeMessage:action.typeMessage   }

        case ActionTypes.SUCCESS_EDITED_PERIOD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal, typeMessage:action.typeMessage }

        case ActionTypes.CLEAR_FETCH_PERIOD:
            //console.log(action);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal }

        case ActionTypes.SAVING_EDITED_PERIOD:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal }

        case ActionTypes.CLOSE_PERIOD_WINDOW:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, openModal: action.openModal }

        default:
            return state;
    }
}


