import React, { Component } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import CardNewQuest from './CardNewQuest';
import CardQuest from './CardQuest';
import * as layoutVars from '../../configs/layoutVars';
import { LoadingComp } from '../genericComponents/LoadingComp';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        listQuestionary: state.listQuestionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({

});



const cardQuestBuilder = (cards, actions) => {

    /* console.log("cards", cards);
     console.log("actions", actions);*/

    var cardsQuestArray = cards.map((element, index) => {
        return <CardQuest cardParams={element} actions={actions} key={index} />;
    })

    //return null;
    return cardsQuestArray;

}

class TabNotPublished extends Component {
   /* constructor(props) {
        super(props)
        console.log(this.props);
    }*/
    render() {
        if (this.props.listQuestionary.isLoading)
            return <LoadingComp />

        return (
            <Row gutter={layoutVars.GUTTER_BASE_LISTQUEST}>
                <Col span={24}>
                    <CardNewQuest />
                    {
                        this.props.listQuestionary.payload.notPublished.questionaries.length > 0 &&
                        cardQuestBuilder(this.props.cards, this.props.actions)
                    }
                </Col>
            </Row>

        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabNotPublished));
