import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchNewQuestionary } from '../../actions/newQuestionaryActionCreator';
//import { HeaderPreviewQuestionaryComp } from '../viewQuestionary/HeaderPreviewQuestionaryComp';
import { LoadingComp } from '../genericComponents/LoadingComp';
//import QuestionsCreatorComp from '../NewQuestionary/QuestionsCreatorComp';
import { Row, message, Card, Col, Button, Divider, Tabs, Icon } from 'antd';
import { GUTTER_BASE, MESSAGE_CONFIG } from '../../configs/layoutVars';
//import * as styleVars from '../../configs/styleVars';
//import ActionButtonQuestComp from '../genericComponents/ActionButtonQuestComp';
import { showLoginModal } from '../../actions/loginActionsCreator';
import ModalHeadQuest from './ModalHeadQuest';
import * as actions from '../../actions/actions';
import QuestionsConfiguration from './configQuest/QuestionsConfiguration';
//import { cleanListQuestionary } from '../../actions/homeActionsCreator';
import { sendQuestionary, resetValuesQuestionary, fetchQuestionary } from '../../actions/previewQuestionaryActionCreator';
import HeaderCreateQuestionary from './HeaderCreateQuestionary';
import TabsBuilder from './TabsBuilder';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '../../configs/baseUrl'
import { SAVE_LABEL } from '../../configs/uiTexts';
import * as uiTexts from '../../configs/uiTexts';

const TabPane = Tabs.TabPane;

/** Redux get State ****************/
const mapStateToProps = (state) => {
  return {
    credentials: state.credentials,
    questionary: state.questionary
  }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
  fetchNewQuestionary: () => dispatch(fetchNewQuestionary()),
  fetchQuestionary: (token, idCuestionario) => dispatch(fetchQuestionary(token, idCuestionario)),
  sendQuestionary: (questionary, token) => dispatch(sendQuestionary(questionary, token)),
  showLoginModal: (isAuthenticated, token, typeLog, idCuestionario, idImagen) => dispatch(showLoginModal(isAuthenticated, token, typeLog, idCuestionario, idImagen)),
  resetValuesQuestionary: () => dispatch(resetValuesQuestionary()),
  //cleanListQuestionary: () => dispatch(cleanListQuestionary())
});



message.config(MESSAGE_CONFIG);




class CreateQuestionary extends Component {
  constructor(props) {
    super(props)
    this.state = ({ tab: "1" })

  }

  componentWillMount() {

    if (!this.props.questionary.typeMessage) {

      if (!this.props.credentials.isAuthenticated) {
        //Al cerrar sesión, a veces pasa por aquí, entonces también cachamos el caso para que no muestre el form de login al cerrar sesión
        if (this.props.credentials.showLogin !== actions.LOGIN && this.props.credentials.showLogin !== actions.LOGING && this.props.credentials.showLogin !== actions.LOGED_OUT) {
          //console.log("CreateQuestionary", "No autenticado", "showLogin != [LOGIN, LOGING, LOGED_OUT]");
          this.props.showLoginModal(this.props.credentials.isAuthenticated, this.props.credentials.token, actions.LOGIN, this.props.credentials.idCuestionario, null);
        }
      }
      else if (this.props.questionary.questionary === null && !this.props.questionary.isLoading) {

        if (this.props.match.url === "/" + linksRouter.newQuestionary)
          this.props.fetchNewQuestionary();

        else if (this.props.match.url === "/" + linksRouter.editQuestionary + '/' + this.props.match.params.id)
          this.props.fetchQuestionary(this.props.credentials.token, this.props.match.params.id)
      }

    } else {
      this.props.resetValuesQuestionary();
      this.props.history.push("/" + linksRouter.errorPage);
    }

  }


  componentDidUpdate() {
    if (this.props.questionary.succesSend === true) {
      this.props.resetValuesQuestionary();
      this.props.history.push("/" + linksRouter.questionaryList);
    }

  }

  saveQuestionaryHandler = () => {
    //console.log(this.props.questionary.questionary);

    this.props.sendQuestionary(this.props.questionary.questionary, this.props.credentials.token)
  }

  changeTab = (tab) => {
    this.setState({ tab: tab });
  }
  render() {

    if (!this.props.credentials.isAuthenticated)
      return null;

    if (this.props.questionary.isLoading || this.props.questionary.isLoading === null || this.props.questionary.questionary === null)
      return <LoadingComp />;


    if (this.props.questionary.questionary.header.questionaryName === "")
      return <ModalHeadQuest />
      
    if (this.props.questionary.questionary.header.type === uiTexts.NEW_QUESTIONARY_TYPE1 || this.props.questionary.questionary.header.type === uiTexts.NEW_QUESTIONARY_TYPE3){
    return (
      <React.Fragment>
        <Tabs
          activeKey={this.state.tab}
          size={"large"}
          style={{ margin: 0 }}
          onTabClick={(e) => this.changeTab(e)}
        >
          <TabPane tab={<span><Icon type="form" /> Agregar preguntas</span>} key="1">

            <Card>
              <HeaderCreateQuestionary />
              <Divider></Divider>

              <TabsBuilder></TabsBuilder>

              <Row gutter={GUTTER_BASE}>
                <Col style={{ textAlign: "right" }} span={24}>

                  <Button
                    type={"primary"}
                    onClick={() => this.saveQuestionaryHandler()}
                    loading={this.props.questionary.isSending}
                    disabled={!this.props.questionary.questionary.sections[0].questions.length > 0}
                  >{SAVE_LABEL}
                  </Button>
                </Col>

              </Row>
            </Card>

          </TabPane>
        
          <TabPane tab={<span><Icon type="setting" /> Configurar preguntas</span>} key="2">
            <QuestionsConfiguration changeTab={this.changeTab} />
          </TabPane>
        </Tabs>

      </React.Fragment>
    )
    }else{
      return (
        <React.Fragment>
          <Tabs
            activeKey={this.state.tab}
            size={"large"}
            style={{ margin: 0 }}
            onTabClick={(e) => this.changeTab(e)}
          >
            <TabPane tab={<span><Icon type="form" /> Agregar preguntas</span>} key="1">
  
              <Card>
                <HeaderCreateQuestionary />
                <Divider></Divider>
  
                <TabsBuilder></TabsBuilder>
  
                <Row gutter={GUTTER_BASE}>
                  <Col style={{ textAlign: "right" }} span={24}>
  
                    <Button
                      type={"primary"}
                      onClick={() => this.saveQuestionaryHandler()}
                      loading={this.props.questionary.isSending}
                      disabled={!this.props.questionary.questionary.sections[0].questions.length > 0}
                    >{SAVE_LABEL}
                    </Button>
                  </Col>
  
                </Row>
              </Card>
            </TabPane>  
          </Tabs>
  
        </React.Fragment>
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateQuestionary));
