import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addQuestionaryToScreen, resetValuesQuestionary } from '../../actions/previewQuestionaryActionCreator';
import * as layoutVars from '../../configs/layoutVars'
import { Modal, Row, Col, Button, Input } from 'antd';
import { GUTTER_BASE } from '../../configs/layoutVars';
import * as uiTexts from '../../configs/uiTexts';
import { cloneObject } from '../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { linksRouter } from '../../configs/baseUrl'



//const TabPane = Tabs.TabPane;
const confirm = Modal.confirm;

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        questionary: state.questionary
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    addQuestionaryToScreen: (questionary) => dispatch(addQuestionaryToScreen(questionary)),
    resetValuesQuestionary: () => dispatch(resetValuesQuestionary())
});


class HeaderCreateQuestionary extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            isEditingTitle: false,
            title: this.props.questionary.questionary.header.questionaryName,
            isEditingRelatedInfo: false,
            relatedInfo: this.props.questionary.questionary.header.relatedInfo,
        })
        //console.log(this.props);

    }

    saveChanges = () => {
        let newQuestionary = cloneObject(this.props.questionary.questionary);
        newQuestionary.header.questionaryName = this.state.title;
        newQuestionary.header.relatedInfo = this.state.relatedInfo;
        if (this.state.title !== "")
            this.props.addQuestionaryToScreen(newQuestionary);
        else
            this.setState({ title: this.props.questionary.questionary.header.questionaryName })
    }

    titleEditor = () => {
        return (
            this.state.isEditingTitle ?
                <Input
                    maxLength={layoutVars.MAX_LENGTH_TITLE}
                    value={this.state.title}
                    style={{ width: "50%" }}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    placeholder={uiTexts.NEW_QUESTIONARY_TITLE_LABEL}
                    onPressEnter={(e) => this.setState({ isEditingTitle: false }, this.saveChanges)}
                    onBlur={(e) => this.setState({ isEditingTitle: false }, this.saveChanges)}
                >
                </Input>
                :
                this.state.title
        )
    }

    relatedInfoEditor = () => {
        return (
            this.state.isEditingRelatedInfo ?
                <Input
                    maxLength={layoutVars.MAX_LENGTH_RELATED_INFO}
                    value={this.state.relatedInfo}
                    style={{ width: "50%" }}
                    onChange={(e) => this.setState({ relatedInfo: e.target.value })}
                    placeholder={uiTexts.NEW_QUESTIONARY_TITLE_LABEL}
                    onPressEnter={(e) => this.setState({ isEditingRelatedInfo: false }, this.saveChanges)}
                    onBlur={(e) => this.setState({ isEditingRelatedInfo: false }, this.saveChanges)}
                >
                </Input>
                :
                this.state.relatedInfo
        )
    }


    showDeleteConfirm = (resetValuesQuestionary, history) => {
        confirm({
            title: uiTexts.DISCARD_CHANGES,
            content: uiTexts.DELETE_TEMP_QUESTIONARY,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                resetValuesQuestionary();
                history.push("/" + linksRouter.questionaryList);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }



    render() {
        return (
            <Row gutter={GUTTER_BASE}>
                <p style={{ textAlign: "right" }}>
                    <Button
                        type="danger"
                        icon="file-exclamation"
                        onClick={() => this.showDeleteConfirm(this.props.resetValuesQuestionary, this.props.history)}
                    // onClick={(e) => this.setState({ isEditingRelatedInfo: true })}
                    >{uiTexts.DELETE_TEMP_QUESTIONARY_BUTTON}</Button>
                </p>
                <Col span={24}>

                    <h1>
                        {this.titleEditor()}
                        &nbsp;
                    <Button
                            style={{ border: "none" }}
                            disabled={this.state.isEditingTitle}
                            shape="circle"
                            icon="edit"
                            onClick={(e) => this.setState({ isEditingTitle: true })}
                        />
                    </h1>

                    <h2 style={{ color: "#888888" }} >
                        {this.relatedInfoEditor()}
                        &nbsp;
                <Button
                            style={{ border: "none" }}
                            disabled={this.state.isEditingTitle}
                            shape="circle"
                            icon="edit"
                            onClick={(e) => this.setState({ isEditingRelatedInfo: true })}
                        />
                    </h2>
                </Col>


            </Row>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderCreateQuestionary));