import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Carousel, Divider } from 'antd';
import { withRouter } from 'react-router-dom';
import { LoadingComp } from '../genericComponents/LoadingComp';
import { fetchPublicHome } from '../../actions/homeActionsCreator';
import { resetLogin } from '../../actions/loginActionsCreator';
import { resetValuesQuestionary } from '../../actions/previewQuestionaryActionCreator';
import * as layoutVars from '../../configs/layoutVars';
import { verticalMargin45 } from '../../configs/styleVars';
import { MSG_UNFINISHED_OR_ALREADY_FINISHED } from '../../configs/uiTexts';

import mosaicHome1 from '../../imgs/home_mosaic1.png'
import mosaicHome2 from '../../imgs/home_mosaic2.png';
import mosaicHome3 from '../../imgs/home_mosaic3.png';
import mosaicHome4 from '../../imgs/home_mosaic4.png';

import carucelHome1 from '../../imgs/home_carrucel1.png';
import carucelHome2 from '../../imgs/home_carrucel2.png';
import carucelHome3 from '../../imgs/home_carrucel3.png';

/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        publicHome: state.publicHome,
        credentials: state.credentials
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    fetchPublicHome: () => dispatch(fetchPublicHome()),
    resetLogin: () => dispatch(resetLogin()),
    resetValuesQuestionary: (message) => dispatch(resetValuesQuestionary(message))
});


/*


*/
class PublicHome extends Component {

    componentWillMount() {
        this.props.resetValuesQuestionary(MSG_UNFINISHED_OR_ALREADY_FINISHED);
        //console.log("LLEGAMOS A INICIO")
        if (this.props.credentials.showLogin === "LOGED_OUT") {
            //console.log("home", this.props);
            this.props.resetLogin();
        } else {
            if (this.props.publicHome.typeMessage===null) {
                if (this.props.publicHome.payload === null && !this.props.publicHome.isLoading) {
                    this.props.fetchPublicHome();
                }
            }
        }
    }

    render() {

        if (this.props.publicHome.isLoading || this.props.publicHome.payload === null)
            return <LoadingComp />

        return (
            <Row gutter={layoutVars.GUTTER_BASE_LISTQUEST} >
                <Col span={20} offset={1}>
                     <Row style={verticalMargin45} gutter={layoutVars.GUTTER_BASE_LISTQUEST}>
                     <h2 style={verticalMargin45} >El sistema te permite</h2>

                        <Col span={24} md={12}>
                            <Col span={24} md={12} style={{ textAlign: "center" }}>
                                <p><img src={mosaicHome1} alt="Juntas (imagen)" /> </p>
                                <h4 > Consultar la opinión de las Juntas, creando cuestionarios de forma fácil e intuitiva. </h4>
                            </Col>
                            <Col span={24} md={12} style={{ textAlign: "center" }}>
                                <p> <img src={mosaicHome2} alt="Proceso electoral (imagen)" /></p>
                                <h4 >Obtener información de los procesos electorales que necesitas de tus encuestados.</h4>
                            </Col>
                        </Col>
                        <Col span={24} md={12}>
                            <Col span={24} md={12} style={{ textAlign: "center" }}>
                                    <p> <img src={mosaicHome3} alt="Oficinas centrales (imagen)" /></p>
                                    <h4 >Recopilar las respuestas de los Órganos Públicos Locales de forma rápida y sencilla.</h4>
                            </Col>
                            <Col span={24} md={12} style={{ textAlign: "center" }}>
                                    <p> <img src={mosaicHome4} alt="Vincular por temporalidad (imagen)" /></p>
                                    <h4 >Vincular las respuestas para conseguir información más precisa.</h4>
                            </Col>
                        </Col>

                        <Divider />
                        <Col span={24} >
                            <h2 style={verticalMargin45} >Toma en cuenta</h2>
                            <Carousel dots={true}>
                                <div><h4><p style={{ textAlign: "center" }}><img src={carucelHome1} alt="Escribe una breve descripción (imagen)" /></p></h4></div>
                                <div><h4><p style={{ textAlign: "center" }}><img src={carucelHome2} alt="Divide y venceras (imagen)" /></p></h4></div>
                                <div><h4><p style={{ textAlign: "center" }}><img src={carucelHome3} alt="Menos es más (imagen)" /></p></h4></div>
                            </Carousel>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicHome))
