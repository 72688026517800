import * as ActionTypes from '../actions/actions';


/* temporal container to new/edit question */
export const TempQuestion = (state = {
    type: null,
    question: [],
    message: null
}, action) => {
    switch (action.type) {
        case ActionTypes.UPDATED_VALUES_TEMP_QUEST:
            return { ...state, type: action.type, question: action.question, message: action.message }
        default:
            return state;
    }
}