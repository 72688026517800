import React, { Component } from 'react'
import { fetchLogout } from '../../actions/loginActionsCreator';
import { linksRouter } from '../../configs/baseUrl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetValuesQuestionary } from '../../actions/previewQuestionaryActionCreator';
//import { linksRouter } from '../../configs/baseUrl';
import { SUCCSESS_MESSAGE } from '../../configs/uiTexts';



/** Redux get State ****************/
const mapStateToProps = (state) => {
    return {
        state,
        credentials: state.credentials
    }
}

/** Redux get dispatch functions ****************/
const mapDispatchToProps = (dispatch) => ({
    resetValuesQuestionary: (message) => dispatch(resetValuesQuestionary(message)),
    logout: (token) => dispatch(fetchLogout(token))
});



class UserAnsOK extends Component {

    componentWillMount() {
        this.props.resetValuesQuestionary(SUCCSESS_MESSAGE);
       
    }

    componentDidUpdate() {
        this.props.logout(this.props.credentials.token);
        if(localStorage.getItem('correo')){
            localStorage.removeItem('correo')
            localStorage.removeItem('token')
        }
        this.props.history.replace("/" + linksRouter.succesAnsUser)
        return null;
    }

    render() {
        return (
            <div>
                <h2>{SUCCSESS_MESSAGE}</h2>
            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAnsOK));