import * as ActionTypes from '../actions/actions';

export const listQuestionary = (state = {
    isLoading: null,
    payload: null,
    message: null,
    typeMessage: null,
    succesSend: null
}, action) => {
    switch (action.type) {

        case ActionTypes.LOADING_LIST_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }

        case ActionTypes.FAILED_LIST_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }

        case ActionTypes.SUCCESS_LIST_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }


        case ActionTypes.CLEAN_DATA_LIST_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }


        case ActionTypes.CLONING_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }

        case ActionTypes.FAILED_CLONE_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }

        case ActionTypes.SUCCESS_CLONE_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }



        case ActionTypes.DELETING_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }

        case ActionTypes.FAILED_DELETE_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }

        case ActionTypes.SUCCESS_DELETE_QUEST:
            //console.log(action.payload);
            return { ...state, isLoading: action.isLoading, payload: action.payload, message: action.message, typeMessage: action.typeMessage,succesSend: action.succesSend  }




        default:
            return state;
    }
}

